import { _alert } from '@model/dialogModel';
import etcInfoModel from '@model/establish/etcInfoModel';
import portalModel from '@model/portalModel';
import { PURPOSE_CLASSIFICATION_TYPE } from '@service/businessPurposeService';
import { reaction, runInAction } from 'mobx';

import { purposeType } from './constant';
import EstateBrokeragePurposeSelectGuideModal, {
  modalType,
} from './modals/EstateBrokeragePurposeSelectGuideModal/index';

/**
 * 목적 분류 관리
 * 목적의 분류는 한 가지만 존재해야함. ex) 부동산중개업 분류와 일반(GENERAL) 분류의 목적을 혼용해서 선택할 수 없음.
 */
class BusinessPurposeClassificationVM {
  disposer = null;
  isModifiedByClassification = false;
  // 변경 전 목적 분류
  originalPurposesClassification = null;

  constructor() {
    this.init();
  }

  init() {
    this.recordOriginalPurposesClassification();
    this.disposer = reaction(
      // 목적 목록 길이 변경 시 트리거
      () => etcInfoModel.businessPurposes.length,
      () => this._reaction([...etcInfoModel.businessPurposes]),
    );
  }

  dispose() {
    if (typeof this.disposer === 'function') {
      this.disposer();
    }
  }

  recordOriginalPurposesClassification(purposes) {
    const classificationList = this._extractPurposesClassificationList(purposes || etcInfoModel.businessPurposes);

    if (classificationList.length === 0) {
      this.originalPurposesClassification = null;
    } else if (classificationList.length === 1) {
      this.originalPurposesClassification = classificationList[0];
    } else {
      this.originalPurposesClassification = PURPOSE_CLASSIFICATION_TYPE.GENERAL;
    }
  }

  // 목적 목록 변경 시 처리
  _reaction(purposes) {
    const record = () => {
      this.recordOriginalPurposesClassification(purposes);
      this.isModifiedByClassification = false;
    };

    // 목적 목록 변경 시 처리가 필요없는 조건들
    if (this.isModifiedByClassification || purposes.length === 0) {
      record();

      return;
    }

    // 변경된 목적 목록의 분류
    const classificationList = this._extractPurposesClassificationList(purposes);

    // 목적 목록의 분류가 추가되지 않았거나, 목적 목록이 비어있는 경우
    if (classificationList.length < 2) {
      record();

      return;
    }

    const addedClassification = classificationList.filter(
      (classification) => this.originalPurposesClassification !== classification,
    )[0];

    // 목적 목록의 분류 추가 된 경우 (둘 중 하나로 통일해야하기에 안내 및 필터링 처리)
    this.isModifiedByClassification = true;

    // 부동산 > 부동산 중개업 공인 중개업 그룹 목적 예외 처리
    if (
      addedClassification === PURPOSE_CLASSIFICATION_TYPE.ESTATE_BROKERAGE ||
      this.originalPurposesClassification === PURPOSE_CLASSIFICATION_TYPE.ESTATE_BROKERAGE
    ) {
      void this._check부동산_중개_공인중개업_목적_안내(addedClassification);
    }
  }

  _extractPurposesClassificationList(purposes) {
    const classificationSet = new Set();

    purposes.forEach((purpose) => {
      if (purpose.purposeType === purposeType.DIRECT) return;

      classificationSet.add(purpose.purposeClassification);
    });

    return [...classificationSet];
  }

  async _check부동산_중개_공인중개업_목적_안내(addedClassification) {
    const openEstateBrokeragePurposeSelectGuideModal = async (type) => {
      return new Promise((resolve) => {
        portalModel.modalContent = (
          <EstateBrokeragePurposeSelectGuideModal
            type={type}
            onClickYes={() => resolve('filter')}
            onClickNo={() => resolve('keep')}
          />
        );
        portalModel.closeBtn = false;
      });
    };

    const type =
      addedClassification === PURPOSE_CLASSIFICATION_TYPE.ESTATE_BROKERAGE
        ? modalType.다른_목적O_부동산_중개_추가_시
        : modalType.부동산_중개O_다른_목적_추가_시;

    const response = await openEstateBrokeragePurposeSelectGuideModal(type);

    runInAction(() => {
      portalModel.setModalClose();

      etcInfoModel.businessPurposes = etcInfoModel.businessPurposes.filter(({ purposeClassification }) => {
        if (this.originalPurposesClassification) {
          return response === 'keep'
            ? purposeClassification === this.originalPurposesClassification
            : purposeClassification !== this.originalPurposesClassification;
        }

        const original =
          type === modalType.다른_목적O_부동산_중개_추가_시
            ? PURPOSE_CLASSIFICATION_TYPE.GENERAL
            : PURPOSE_CLASSIFICATION_TYPE.ESTATE_BROKERAGE;

        return response === 'keep' ? purposeClassification === original : purposeClassification !== original;
      });

      const confirmMessage =
        (type === modalType.다른_목적O_부동산_중개_추가_시 && response === 'filter') ||
        (type === modalType.부동산_중개O_다른_목적_추가_시 && response === 'keep')
          ? '[부동산 > 부동산중개·공인중개]에 포함된 목적 외 다른 목적을 삭제하였습니다.'
          : '[부동산 > 부동산중개·공인중개]에 포함된 목적을 삭제하였습니다.';

      void _alert(confirmMessage);
    });
  }
}

export default BusinessPurposeClassificationVM;
