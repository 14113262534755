import { get } from '@common/module/httpRequest';
import smartformAppModel from '@model/smartformAppModel';

export const PURPOSE_CLASSIFICATION_TYPE = {
  GENERAL: 'GENERAL',
  ESTATE_BROKERAGE: 'ESTATE_BROKERAGE',
};

const mergePurposes = (purpose) => {
  return {
    recommendedPurposeId: Number(purpose.recommendedPurposeId),
    content: purpose.content || '',
    noteYn: Boolean(purpose.noteYn),
    note: purpose.noteYn
      ? {
          minCapital: purpose.note?.minCapital || '',
          cautions: purpose.note?.cautions || '',
          postRegistrationRequirements: purpose.note?.postRegistrationRequirements || '',
        }
      : null,
    purposeClassification: purpose.purposeClassification || PURPOSE_CLASSIFICATION_TYPE.GENERAL,
  };
};

const mapPurposeGroup = (group) => {
  return {
    groupNames: group.groupNames?.map((name) => name || '') || [],
    purposes: group.purposes?.map(mergePurposes) || [],
  };
};

class BusinessPurposeService {
  // 목적 검색 API
  async searchPurpose(searchWord) {
    const res = await get(`establishment/purpose/search/${smartformAppModel.smartformId}?keyword=${searchWord}`);
    const data = res.data;

    return {
      purposeGroupsForGroupName: data?.purposeGroupsForGroupName?.map(mapPurposeGroup) || [],
      purposeGroupsForContent:
        data?.purposeGroupsForContent?.map((purposeGroup) => ({
          groupNames: purposeGroup.groupNames?.map((name) => name || '') || [],
          purposes:
            purposeGroup.purposes?.map((purpose) => ({
              recommendedPurposeId: Number(purpose.recommendedPurposeId),
              content: purpose.content || '',
              noteYn: Boolean(purpose.noteYn),
              purposeClassification: purpose.purposeClassification || PURPOSE_CLASSIFICATION_TYPE.GENERAL,
              note: purpose.noteYn
                ? {
                    minCapital: purpose.note?.minCapital || '',
                    cautions: purpose.note?.cautions || '',
                    postRegistrationRequirements: purpose.note?.postRegistrationRequirements || '',
                  }
                : null,
              // 검색결과에 해당하는 목적인지, 아니면 그냥 단순히 그룹에 속한 목적인지
              searchYn: Boolean(purpose.searchYn),
            })) || [],
        })) || [],
      purposes: data?.purposes?.map(mergePurposes) || [],
    };
  }

  // 인기목적 목록 API
  async getPopularPurposes() {
    const res = await get(`establishment/purpose/recommended/${smartformAppModel.smartformId}`);
    const data = res.data;

    return {
      purposeGroups: data?.purposeGroups?.map(mapPurposeGroup) || [],
    };
  }

  // 스마트폼 추천 목적 전체 조회 API
  async getAllRecommendPurposes() {
    const res = await get(`establishment/purpose/recommended/all/${smartformAppModel.smartformId}`);
    const data = res.data;

    return {
      headers:
        data?.headers?.map((header) => ({
          consonant: header.consonant || '',
          count: header.count || '',
        })) || [],
      results:
        data?.results?.map((result) => ({
          consonant: result.consonant || '',
          purposeGroups: result.purposeGroups?.map(mapPurposeGroup) || [],
        })) || [],
    };
  }
}

export default new BusinessPurposeService();
