import SortBtn from '@comComponents/atoms/Button/SortBtn/SortBtn';
import ErrorText from '@comComponents/atoms/Text/ErrorText/ErrorText';
import Close from '@common/components/asset/icon/btn_close_darkGray.svg';
import Plus from '@common/components/asset/icon/btn_plus_blue.svg';
import Error from '@common/components/asset/icon/ico-error.svg';
import { purposeType } from '@pages/Establish/EtcInfo/BusinessPurpose/constant';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { useEffect, useRef } from 'react';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';

import useSortableItem from '../../hook/useSortableItem';
import st from './style.module.scss';

const SortableContainer = sortableContainer(({ children }) => {
  return <ul>{children}</ul>;
});

const DragHandle = sortableHandle(() => <SortBtn className={st.sortBtn} />);

const SortableItem = sortableElement(
  observer(({ businessPurpose, idx, sortYn, isDragging }) => {
    const { openModal, errorValidation, onChangeContent, enterText, addPurpose, removePurpose } = useSortableItem();

    const isReRendered = useRef(false);

    const isDirectPurpose = businessPurpose.purposeType == purposeType.DIRECT;

    useEffect(() => {
      isReRendered.current = true;
    }, []);

    // 직접입력 추가시 첫 삽입에는 VA 미노출되게
    const errorNotShowCondition = isDirectPurpose && !isReRendered.current && businessPurpose.content === '';

    const errorResult = errorValidation(businessPurpose.content);

    return (
      <li key={idx} className={clsx(st.sortableItem, { [st.chooseOwnPurposeItem]: isDirectPurpose })}>
        {sortYn && <DragHandle />}

        <div>
          <div className={st.purposeItemContainer}>
            <div className={st.businessPurposeNumber}>1. </div>
            {isDirectPurpose ? (
              <>
                <textarea
                  name={`businessPurposes[${idx}].content`}
                  className={clsx(st.inputTextChooseOwnPurpose, { [st.dragging]: isDragging })}
                  value={businessPurpose.content}
                  onChange={(e) => onChangeContent(e, idx)}
                  onBlur={(e) => enterText(e, idx)}
                  data-error={errorResult?.errorState}
                />
                <img
                  src={Plus}
                  onClick={() => addPurpose()}
                  style={{ marginLeft: '8px', cursor: 'pointer' }}
                  alt="plus-icon"
                />
              </>
            ) : (
              <div className={clsx(st.recommendPurposeWrapper)}>
                <div className={clsx(st.textRecommendPurpose, { [st.dragging]: isDragging })}>
                  {businessPurpose.content}
                </div>
                <input
                  type="hidden"
                  value={businessPurpose.id || ''}
                  name={`businessPurposes[${idx}].id`}
                  data-error={errorResult?.errorState}
                />
                {businessPurpose.noteYn && (
                  <div
                    className={st.errorBtnContainer}
                    onClick={() =>
                      openModal({
                        content: businessPurpose.content,
                        cautions: businessPurpose.note.cautions,
                        minCapital: businessPurpose.note.minCapital,
                        postRegistrationRequirements: businessPurpose.note.postRegistrationRequirements,
                      })
                    }
                  >
                    <div className={st.errorText}>유의</div>
                    <img className={st.errorIcon} src={Error} alt="error" />
                  </div>
                )}
              </div>
            )}
          </div>
          {errorResult?.errorState === 'error' && !errorNotShowCondition && (
            <div className={st.errorArea}>
              <ErrorText className={clsx(st.errorText, 'errorText')} dataError={'error'}>
                {errorResult?.errorMessage}
              </ErrorText>
            </div>
          )}
        </div>
        <img src={Close} onClick={() => removePurpose(idx)} className={st.closeBtn} alt="X" />
      </li>
    );
  }),
);

export default {
  Container: SortableContainer,
  Item: SortableItem,
};
