import SquareBtn from '@comComponents/atoms/Button/SquareBtn/SquareBtn';
import LikeBtn from '@comComponents/atoms/HyperLink/LikeBtn/LikeBtn';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import SearchAddress from '@comComponents/atoms/SearchAddress/SearchAddress';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import Upload from '@comComponents/molecules/FileUpload/FileUpload';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import regExp from '@common/module/regExp';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import clsx from 'clsx';
import { toJS } from 'mobx';
import { useObserver } from 'mobx-react';

import st from './StockholdersMeetingMusterNotice.module.scss';
import StockholdersMeetingMusterNoticeVm from './StockholdersMeetingMusterNoticeVm';

function StockholdersMeetingMusterNotice() {
  return useObserver(() => (
    <form onSubmit={(e) => StockholdersMeetingMusterNoticeVm.submit(e)}>
      <input type="submit" value="submit" id="stepForm" style={{ display: 'none' }} />
      <Question>
        <ContentBox className={Qst.spaceBetween}>
          <div className={Qst.infoSection}>
            <div className={Qst.titleWrap}>
              <h2 className={Qst.title}>주주총회 소집통지</h2>
            </div>
            <div className={st.explainBox}>
              <div className={st.explain}>
                <img src="/images/StockholdersMeetingMusterNotice_1.png" alt="소집통지 원칙 예시 " />
              </div>
              <div className={st.explain}>
                <img src="/images/StockholdersMeetingMusterNotice_2.png" alt="소집통지 방법 예시 " />
              </div>
            </div>
            {!StockholdersMeetingMusterNoticeVm.hasLiquidationStep() && (
              <div className={st.animationBox}>
                <div className={st.animationTitle}>
                  가장 쉬운 <span className={st.bold}>온라인 소집통지 방법</span>
                </div>
                <div className={st.animation}>
                  <img src="/images/StockholdersMeetingMusterNotice_3_0306.gif" alt="소집통지 애니메이션 " />
                </div>
                <a
                  className={st.linkBtn}
                  href={`${process.env.REACT_APP_STANDBY_URL}auth/joinMembership`}
                  target="_blank"
                  rel="noreferrer"
                >
                  소집통지 자동발송하기
                </a>
                <div className={st.explain}>서비스 이용시 별도 회원가입이 필요해요.</div>
              </div>
            )}
            <div className={Qst.titleWrap} style={{ marginTop: '100px' }}>
              <h2 className={Qst.title}>주주총회 소집통지를 어떻게 진행하나요?</h2>
            </div>
            <ul className={Qst.answerList}>
              <li>
                <Radio
                  activeBackground
                  className={Qst.answerLabel}
                  value="ONE"
                  checked={StockholdersMeetingMusterNoticeVm.stockholderMeetingMusterNoticeType === 'ONE'}
                  name="stockholderMeetingMusterNoticeType"
                  onChange={StockholdersMeetingMusterNoticeVm.setStockholderMeetingMusterNoticeType}
                >
                  주주총회 <span className={st.radioImportant}>14일 전</span>에 이미{' '}
                  <span className={st.radioImportant}>소집통지를 완료</span>하였습니다.
                </Radio>
              </li>
              <li style={{ height: '90px', position: 'relative' }}>
                <Radio
                  activeBackground
                  className={Qst.answerLabel}
                  value="TWO"
                  checked={StockholdersMeetingMusterNoticeVm.stockholderMeetingMusterNoticeType === 'TWO'}
                  name="stockholderMeetingMusterNoticeType"
                  onChange={StockholdersMeetingMusterNoticeVm.setStockholderMeetingMusterNoticeType}
                >
                  주주총회 <span className={st.radioImportant}>10일 전</span>에 이미{' '}
                  <span className={st.radioImportant}>소집통지를 완료</span>하였습니다.{' '}
                </Radio>
                <div className={st.radioSub}>자본금 10억 원 미만인 경우</div>
              </li>
              <li style={{ height: '90px', position: 'relative' }}>
                <Radio
                  activeBackground
                  className={Qst.answerLabel}
                  value="THREE"
                  checked={StockholdersMeetingMusterNoticeVm.stockholderMeetingMusterNoticeType === 'THREE'}
                  name="stockholderMeetingMusterNoticeType"
                  onChange={StockholdersMeetingMusterNoticeVm.setStockholderMeetingMusterNoticeType}
                >
                  <span className={st.radioImportant}>곧 소집통지를 진행</span>할 예정입니다.
                </Radio>
                <div className={st.radioSub}>(통지 후 10~14일 대기 필요)</div>
              </li>
              <li style={{ height: '117px', position: 'relative' }}>
                <Radio
                  activeBackground
                  className={Qst.answerLabel}
                  value="FOUR"
                  checked={StockholdersMeetingMusterNoticeVm.stockholderMeetingMusterNoticeType === 'FOUR'}
                  name="stockholderMeetingMusterNoticeType"
                  onChange={StockholdersMeetingMusterNoticeVm.setStockholderMeetingMusterNoticeType}
                >
                  <span className={st.radioImportant}>모든 주주의 동의서</span>(도장날인)
                  <span className={st.radioImportant}>를 제출하고, 바로 주주총회를 개최</span>합니다.{' '}
                </Radio>
                <div className={st.radioSub} style={{ top: '76px' }}>
                  (소집통지 생략)
                </div>
              </li>
            </ul>
            <Error
              name="stockholderMeetingMusterNoticeType"
              value={StockholdersMeetingMusterNoticeVm.stockholderMeetingMusterNoticeType}
              errorCase={{ required: `소집통지 진행 방식을 선택해 주세요.` }}
            />
          </div>
          <div className={Qst.qnaSection}>
            <Qna tip="주주총회 소집통지" className={Qst.qnaTitle}>
              <div className={Qst.qnaBox}>
                <QText>왜 소집통지가 필요한가요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>상법에 따라 주주총회를 할 때에는 소집통지가 꼭 필요합니다.</Answer>
                  <p className={Qst.explain}>
                    특히 법무부 방침상 주주총회 의사록에 공증을 받을 때는 반드시 ‘주주총회 소집통지서’를 함께 제출해야
                    합니다
                  </p>
                </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>왜 주주총회 소집통지를 14일 전에 발송해야 하나요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>
                    상법에서 주주총회 소집통지를 14일 전에 발송하도록 정하고 있기 때문입니다.
                  </Answer>
                  <p className={Qst.explain}>
                    다만 3년 동안 소집통지가 반송된 경우에는 그 주주에 대해서는 소집통지를 생략할 수 있습니다.
                  </p>
                </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>자본금 10억 미만이면 소집통지 기간을 단축할 수 있나요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>자본금 10억 미만이면 소집통지를 10일 전에 발송할 수 있습니다.</Answer>
                  <p className={Qst.explain}>
                    다만 간혹 정관에 항상 주주총회 소집통지를 14일 전에 발송하도록 규정을 한 경우가 있습니다. 이렇게
                    정관에 명확히 기간을 정해둔 경우, 10일로 단축하는 것이 원칙적으로 어렵습니다.
                  </p>
                  <p className={Qst.explain}>
                    또한 자본금이 10억 이상인 경우, 주주총회 소집통지를 14일 전에 발송하도록 상법이 정하고 있습니다.
                  </p>
                </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>소집통지를 이메일이나 카톡으로 해도 되나요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>
                    주주의 동의를 얻으면 소집통지를 이메일이나 카톡으로 해도 됩니다.
                  </Answer>
                  <p className={Qst.explain}>
                    소집통지는 우편이 원칙이지만, 주주가 동의를 하는 경우에는 소집통지를 이메일이나 카톡으로 할 수
                    있습니다.
                  </p>
                  <p className={Qst.explain}>
                    다만 주주의 동의가 필요하므로, 앞으로 소집통지는 전자문서(이메일이나 카톡)로 하겠다는 동의서를 미리
                    받아주세요. (상법 362조 제3항)
                  </p>
                  <p className={Qst.explain}>
                    동의서는 원본이 아니라 스캔본으로 받아도 되고, 헬프미에 전달해주실 필요는 없습니다.
                  </p>
                  <LikeBtn
                    download={true}
                    className={Qst.likeBtn}
                    href="https://s3.ap-northeast-2.amazonaws.com/sc.help-me.kr/smartformV3/%EC%A3%BC%EC%A3%BC%EC%B4%9D%ED%9A%8C+%EC%86%8C%EC%A7%91%ED%86%B5%EC%A7%80+%EC%A0%84%EC%9E%90%EB%AC%B8%EC%84%9C+%EB%8F%99%EC%9D%98%EC%96%91%EC%8B%9D.docx"
                    target="_blank"
                  >
                    소집통지 전자문서(카톡, 이메일) 동의 양식 다운로드 받기
                  </LikeBtn>
                </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>소집통지를 우편으로 했는데 반송된 경우 어떻게 하나요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>소집통지가 반송된 경우에도 소집통지를 한 것으로 취급합니다.</Answer>
                  <p className={Qst.explain}>
                    다만 적은 지분율을 가진 주주일지라도, 주주 전원이 동의하지 않는 경우, 소집통지를 진행해주셔야
                    합니다.
                  </p>
                </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>어떤 경우 소집통지를 생략할 수 있나요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>
                    상법상 자본금 10억 원 미만이고, 주주 전원이 동의하는 경우, 소집통지를 생략할 수 있습니다.{' '}
                  </Answer>
                  <p className={Qst.explain}>
                    다만 적은 지분율을 가진 주주일지라도, 주주 전원이 동의하지 않는 경우, 소집통지를 진행해주셔야
                    합니다.
                  </p>
                  <p className={Qst.explain}>
                    또한 분쟁이 있거나, 연락이 되지 않는 주주가 1명이라도 있다면, 소집통지를 생략할 수 없습니다.
                  </p>
                </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>의결권이 없는 주주에게도 소집통지를 해야 하나요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>의결권이 없는 주주에게는 소집통지를 하지 않아도 됩니다. </Answer>
                </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>주주총회 소집통지문에는 어떤 내용이 들어가야 하나요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>
                    주주총회 소집통지문에는 일시, 장소, 목적사항이 들어가야 합니다.{' '}
                  </Answer>
                  <p className={Qst.explain}>
                    주주총회 소집통지문에는 1) 개최하는 날짜, 일시 2) 개최장소 3) 목적사항이 기재되어야 합니다.
                  </p>
                  <p className={Qst.explain}>
                    목적사항은 그 내용을 알 수 있도록 다소 구체적으로 기재되어야 합니다. 예를 들면 신규임원 취임의 경우,
                    어떤 직책으로 몇명이나 취임하는지 기재가 필요합니다. (ex 사내이사 2인 취임)
                  </p>
                  <LikeBtn
                    download={true}
                    className={Qst.likeBtn}
                    href="https://s3.ap-northeast-2.amazonaws.com/sc.help-me.kr/smartformV3/%E1%84%89%E1%85%A2%E1%86%B7%E1%84%91%E1%85%B3%E1%86%AF+%E1%84%8C%E1%85%AE%E1%84%8C%E1%85%AE%E1%84%8E%E1%85%A9%E1%86%BC%E1%84%92%E1%85%AC+%E1%84%89%E1%85%A9%E1%84%8C%E1%85%B5%E1%86%B8%E1%84%90%E1%85%A9%E1%86%BC%E1%84%8C%E1%85%B5%E1%84%89%E1%85%A5+%E1%84%8B%E1%85%A3%E1%86%BC%E1%84%89%E1%85%B5%E1%86%A8.docx"
                    target="_blank"
                  >
                    주주총회 소집통지서 양식(샘플) 다운로드 받기
                  </LikeBtn>
                </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>주주총회 소집통지문에 주주총회 개최장소를 온라인으로 기재해도 되나요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>
                    온라인 주주총회는 아직 허용되지 않아서, 주주총회 소집통지문에 개최장소를 온라인으로 기재할 수
                    없습니다.{' '}
                  </Answer>
                  <p className={Qst.explain}>
                    이사회의 경우 온라인 개최가 가능하지만, 온라인 주주총회는 아직 허용되지 않고 있습니다.
                  </p>
                  <p className={Qst.explain}>
                    물론 전자투표 방식으로 참여가 가능하므로, 주주총회를 오프라인 + 온라인 병행 방식은 가능합니다. 다만
                    이 경우에도 개최장소는 오프라인 장소로 정해야 합니다.
                  </p>
                </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>헬프미에서 소집통지를 대신해줄 수 없나요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>
                    안타깝게도 소집통지 대행 서비스는 아직 제공하고 있지 않고, 추후 제공예정입니다.{' '}
                  </Answer>
                </div>
              </div>
            </Qna>
          </div>
        </ContentBox>
      </Question>
      {StockholdersMeetingMusterNoticeVm.stockholderMeetingMusterNoticeType &&
        StockholdersMeetingMusterNoticeVm.stockholderMeetingMusterNoticeType !== 'FOUR' && (
          <Question>
            <ContentBox className={Qst.spaceBetween}>
              <div className={Qst.infoSection}>
                <div className={Qst.titleWrap}>
                  <h2 className={Qst.title}>주주총회일은 언제인가요?</h2>
                </div>
                <div>
                  <div className={Qst.birthWrap}>
                    <InputText
                      style={{ width: '130px' }}
                      placeholder="0000"
                      maxLength="4"
                      name="주주총회일.year"
                      value={StockholdersMeetingMusterNoticeVm.주주총회일.year}
                      onChange={StockholdersMeetingMusterNoticeVm.set주주총회일Year}
                    />
                    <span className={Qst.birthText}>년</span>
                  </div>
                  <div className={Qst.birthWrap}>
                    <InputText
                      style={{ width: '108px' }}
                      placeholder="00"
                      maxLength="2"
                      name="주주총회일.month"
                      value={StockholdersMeetingMusterNoticeVm.주주총회일.month}
                      onChange={StockholdersMeetingMusterNoticeVm.set주주총회일Month}
                      onBlur={StockholdersMeetingMusterNoticeVm.주주총회일MonthBlur}
                    />
                    <span className={Qst.birthText}>월</span>
                  </div>
                  <div className={Qst.birthWrap}>
                    <InputText
                      style={{ width: '108px' }}
                      placeholder="00"
                      maxLength="2"
                      name="주주총회일.day"
                      value={StockholdersMeetingMusterNoticeVm.주주총회일.day}
                      onChange={StockholdersMeetingMusterNoticeVm.set주주총회일Day}
                      onBlur={StockholdersMeetingMusterNoticeVm.주주총회일DayBlur}
                    />
                    <span className={Qst.birthText}>일</span>
                  </div>
                </div>
                <div style={{ marginTop: '-27px' }}>
                  <Error
                    name="주주총회일.year"
                    value={StockholdersMeetingMusterNoticeVm.주주총회일.year}
                    errorCase={{
                      required: '연도를 입력해 주세요.',
                      pattern: {
                        value: regExp.year().test(StockholdersMeetingMusterNoticeVm.주주총회일.year),
                        message: '올바른 연도를 입력해 주세요',
                      },
                    }}
                  />
                  <Error
                    name="주주총회일.month"
                    value={StockholdersMeetingMusterNoticeVm.주주총회일.month}
                    errorCase={{
                      required: StockholdersMeetingMusterNoticeVm.displayBirthErrorMessage('month', '주주총회일')
                        ? '월을 입력해 주세요.'
                        : null,
                      pattern: {
                        value: StockholdersMeetingMusterNoticeVm.displayBirthErrorMessage('month', '주주총회일')
                          ? regExp.month().test(StockholdersMeetingMusterNoticeVm.주주총회일.month)
                          : null,
                        message: '올바른 월을 입력해 주세요',
                      },
                    }}
                  />
                  <Error
                    name="주주총회일.day"
                    value={StockholdersMeetingMusterNoticeVm.주주총회일.day}
                    errorCase={{
                      required: StockholdersMeetingMusterNoticeVm.displayBirthErrorMessage('day', '주주총회일')
                        ? '일(날짜)을 입력해 주세요.'
                        : null,
                      pattern: {
                        value: StockholdersMeetingMusterNoticeVm.displayBirthErrorMessage('day', '주주총회일')
                          ? regExp.day().test(StockholdersMeetingMusterNoticeVm.주주총회일.day)
                          : null,
                        message: '올바른 일(날짜)을 입력해 주세요.',
                      },
                    }}
                  />
                </div>
              </div>
            </ContentBox>
            <ContentBox className={Qst.spaceBetween}>
              <div className={Qst.infoSection}>
                <div className={Qst.titleWrap}>
                  <h2 className={Qst.title}>주주총회 개최장소가 어디인가요?</h2>
                </div>
                <ul className={Qst.answerList}>
                  <li>
                    <Radio
                      activeBackground
                      className={Qst.answerLabel}
                      name="stockholderMeetingPlaceType"
                      checked={StockholdersMeetingMusterNoticeVm.stockholderMeetingPlaceType === '본점'}
                      value="본점"
                      onChange={StockholdersMeetingMusterNoticeVm.setStockholderMeetingPlaceType}
                    >
                      본점
                    </Radio>
                  </li>
                  <li>
                    <Radio
                      activeBackground
                      className={Qst.answerLabel}
                      name="stockholderMeetingPlaceType"
                      checked={StockholdersMeetingMusterNoticeVm.stockholderMeetingPlaceType === '본점에_가까운_장소'}
                      value="본점에_가까운_장소"
                      onChange={StockholdersMeetingMusterNoticeVm.setStockholderMeetingPlaceType}
                    >
                      본점에 가까운 장소
                    </Radio>
                  </li>
                </ul>
                <Error
                  name="stockholderMeetingPlaceType"
                  value={StockholdersMeetingMusterNoticeVm.stockholderMeetingPlaceType}
                  errorCase={{ required: `주주총회 개최장소를 선택해 주세요.` }}
                />
              </div>
              <div className={Qst.qnaSection}>
                <Qna tip="" className={Qst.qnaTitle}>
                  <div className={Qst.qnaBox}>
                    <QText>주주총회를 본점 아닌 곳에서 개최해도 되나요?</QText>
                    <div className="answerBox">
                      <Answer className={Qst.answer}>
                        주주총회는 본점 또는 본점 가까운 곳에서 개최할 수 있습니다.
                      </Answer>
                      <p className={Qst.explain}>
                        꼭 본점에서 개최해야 하는 것은 아니지만, 상법상 본점에 가까운 곳에서 개최해야 합니다. 본점과
                        매우 먼 곳에서 주주총회를 개최하기 위해서는 정관에 관련 규정을 미리 마련해야 하는 점
                        주의해주세요.
                      </p>
                    </div>
                  </div>
                </Qna>
              </div>
            </ContentBox>
            {StockholdersMeetingMusterNoticeVm.stockholderMeetingPlaceType === '본점에_가까운_장소' && (
              <ContentBox className={Qst.spaceBetween}>
                <div className={Qst.infoSection}>
                  <div className={Qst.titleWrap}>
                    <h2 className={Qst.title}>주주총회 소집장소를 입력해 주세요.</h2>
                  </div>
                  <div className={Qst.rowWrap}>
                    <InputText
                      className={Qst.inputText}
                      style={{ width: '380px' }}
                      explain="도로명 주소"
                      value={StockholdersMeetingMusterNoticeVm.address?.address || ''}
                      readOnly
                      onClick={() => StockholdersMeetingMusterNoticeVm.openAddressModal(true)}
                      name={'address'}
                      errorText={
                        <Error
                          name="address"
                          value={StockholdersMeetingMusterNoticeVm.address?.address || ''}
                          errorCase={{ required: '도로명 주소를 입력해 주세요.' }}
                        />
                      }
                    />
                    <SquareBtn onClick={() => StockholdersMeetingMusterNoticeVm.openAddressModal(true)}>
                      도로명 주소 찾기
                    </SquareBtn>
                    {StockholdersMeetingMusterNoticeVm.state.addressModal && (
                      <SearchAddress
                        onClose={() => StockholdersMeetingMusterNoticeVm.openAddressModal(false)}
                        onComplete={(data) => StockholdersMeetingMusterNoticeVm.addressComplate(data)}
                      />
                    )}
                  </div>
                  <div className={Qst.rowWrap}>
                    <InputText
                      className={Qst.inputText}
                      style={{ width: '273px' }}
                      explain="상세 주소"
                      errorText={
                        <Error
                          name="detailAddress"
                          value={StockholdersMeetingMusterNoticeVm.detailAddress}
                          errorCase={{
                            required: '상세 주소를 입력해 주세요.',
                            pattern: {
                              value: regExp
                                .onlyKorNum_hyphen_comma()
                                .test(StockholdersMeetingMusterNoticeVm.detailAddress),
                              message: (
                                <span>
                                  임대차 계약서상 영문이 있다면, <br />
                                  한글로 옮겨적어주세요.
                                  <br />
                                  EX) B동 &gt; 비동
                                </span>
                              ),
                            },
                          }}
                        />
                      }
                      placeholder="상세주소 없을 시, [없음] 입력"
                      value={StockholdersMeetingMusterNoticeVm.detailAddress}
                      onChange={(e) => StockholdersMeetingMusterNoticeVm.setDetailAddress(e)}
                      name="detailAddress"
                    />
                    <div className={Qst.symbol}>
                      <span className={Qst.symbolIcon}>&#40;&nbsp;</span>
                      <span
                        className={clsx(
                          Qst.symbolText,
                          StockholdersMeetingMusterNoticeVm.bnameFontSizeChange ? Qst.fontSize : null,
                        )}
                      >
                        {StockholdersMeetingMusterNoticeVm.address?.bname || '법정동'},&nbsp;
                      </span>
                      <InputText
                        style={{ width: '200px' }}
                        explain="건물명"
                        placeholder="없을 시, [없음] 입력"
                        value={StockholdersMeetingMusterNoticeVm.nameOfBuilding}
                        onChange={(e) => StockholdersMeetingMusterNoticeVm.setNameOfBuilding(e)}
                        name="nameOfBuilding"
                        errorText={
                          <Error
                            name="nameOfBuilding"
                            value={StockholdersMeetingMusterNoticeVm.nameOfBuilding}
                            style={{ width: '260px' }}
                            errorCase={{
                              required: '건물명을 입력해 주세요.',
                              pattern: {
                                value: regExp
                                  .onlyKorNum_hyphen_comma()
                                  .test(StockholdersMeetingMusterNoticeVm.nameOfBuilding),
                                message: (
                                  <span>
                                    건물명이 영문인 경우, 한글
                                    <br />
                                    발음으로 옮겨적어 주세요. <br />
                                    EX) AB타워 &gt; 에이비 타워
                                  </span>
                                ),
                              },
                            }}
                          />
                        }
                      />
                      <span className={Qst.symbolIcon}>&nbsp;&#41;</span>
                    </div>
                  </div>
                </div>
              </ContentBox>
            )}
            <ContentBox className={Qst.spaceBetween}>
              <div className={Qst.infoSection}>
                <div className={Qst.titleWrap}>
                  <h2 className={Qst.title}>소집통지 발송방법은 무엇인가요?</h2>
                </div>
                <ul className={Qst.answerList}>
                  <li>
                    <Radio
                      activeBackground
                      className={Qst.answerLabel}
                      name="noticeSendMethod"
                      checked={StockholdersMeetingMusterNoticeVm.noticeSendMethod === 'ONLINE'}
                      value="ONLINE"
                      onChange={StockholdersMeetingMusterNoticeVm.setNoticeSendMethod}
                    >
                      온라인 발송 (카톡, 이메일 등)
                    </Radio>
                  </li>
                  <li>
                    <Radio
                      activeBackground
                      className={Qst.answerLabel}
                      name="noticeSendMethod"
                      checked={StockholdersMeetingMusterNoticeVm.noticeSendMethod === 'MAIL'}
                      value="MAIL"
                      onChange={StockholdersMeetingMusterNoticeVm.setNoticeSendMethod}
                    >
                      우편 발송
                    </Radio>
                  </li>
                </ul>
                <Error
                  name="noticeSendMethod"
                  value={StockholdersMeetingMusterNoticeVm.noticeSendMethod}
                  errorCase={{ required: `주주총회 개최장소를 선택해 주세요.` }}
                />
              </div>
              <div className={Qst.qnaSection}>
                <Qna tip="" className={Qst.qnaTitle}>
                  <div className={Qst.qnaBox}>
                    <QText>카톡이나 이메일 소집통지는 항상 가능한가요?</QText>
                    <div className="answerBox">
                      <Answer className={Qst.answer}>
                        주주가 동의를 한 경우에만, 소집통지를 이메일이나 카톡으로 할 수 있고, 동의가 없으면 우편으로
                        소집통지를 해야 합니다.
                      </Answer>
                      <p className={Qst.explain}>
                        소집통지는 우편으로 발송하는 것이 원칙이지만, 주주가 동의한 경우, 온라인 소집통지(이메일, 카톡
                        등)를 할 수 있어요. 편리하게 온라인 소집통지를 하려면, 미리 주주들에게 동의서를 받아주세요.
                        (상번 362조 제3항) 동의서는 헬프미에 전달해주실 필요는 없습니다.
                      </p>
                    </div>
                  </div>
                </Qna>
              </div>
            </ContentBox>
            {(StockholdersMeetingMusterNoticeVm.stockholderMeetingMusterNoticeType === 'ONE' ||
              StockholdersMeetingMusterNoticeVm.stockholderMeetingMusterNoticeType === 'TWO') && (
              <ContentBox className={Qst.spaceBetween}>
                <div className={Qst.infoSection}>
                  <div className={Qst.titleWrap}>
                    <h2 className={Qst.title}>주주총회 소집통지문을 업로드해주세요.</h2>
                  </div>
                  <Upload
                    className={clsx(st.fileUpload, 'fileUpload')}
                    multiple
                    model={StockholdersMeetingMusterNoticeVm.stockholderMeetingMusterNoticeFiles}
                    uploadModel={StockholdersMeetingMusterNoticeVm.uploadFiles}
                    deleteModel={StockholdersMeetingMusterNoticeVm.deleteFileIds}
                    files={toJS(StockholdersMeetingMusterNoticeVm.stockholderMeetingMusterNoticeFiles)}
                    uploadFiles={toJS(StockholdersMeetingMusterNoticeVm.uploadFiles)}
                  >
                    <span className={st.bold}>여기</span>를 눌러 업로드해 주세요.
                  </Upload>
                </div>
              </ContentBox>
            )}
            {StockholdersMeetingMusterNoticeVm.stockholderMeetingMusterNoticeType === 'THREE' && (
              <ContentBox className={Qst.spaceBetween}>
                <div className={Qst.infoSection}>
                  <div className={Qst.titleWrap}>
                    <h2 className={Qst.title}>주주총회 소집통지를 언제 하실 예정인가요?</h2>
                  </div>
                  <div>
                    <div className={Qst.birthWrap}>
                      <InputText
                        style={{ width: '130px' }}
                        placeholder="0000"
                        maxLength="4"
                        name="musterNoticeScheduledDate.year"
                        value={StockholdersMeetingMusterNoticeVm.musterNoticeScheduledDate.year}
                        onChange={StockholdersMeetingMusterNoticeVm.setMusterNoticeScheduledDateYear}
                      />
                      <span className={Qst.birthText}>년</span>
                    </div>
                    <div className={Qst.birthWrap}>
                      <InputText
                        style={{ width: '108px' }}
                        placeholder="00"
                        maxLength="2"
                        name="musterNoticeScheduledDate.month"
                        value={StockholdersMeetingMusterNoticeVm.musterNoticeScheduledDate.month}
                        onChange={StockholdersMeetingMusterNoticeVm.setMusterNoticeScheduledDateMonth}
                        onBlur={StockholdersMeetingMusterNoticeVm.musterNoticeScheduledDateMonthBlur}
                      />
                      <span className={Qst.birthText}>월</span>
                    </div>
                    <div className={Qst.birthWrap}>
                      <InputText
                        style={{ width: '108px' }}
                        placeholder="00"
                        maxLength="2"
                        name="musterNoticeScheduledDate.day"
                        value={StockholdersMeetingMusterNoticeVm.musterNoticeScheduledDate.day}
                        onChange={StockholdersMeetingMusterNoticeVm.setMusterNoticeScheduledDateDay}
                        onBlur={StockholdersMeetingMusterNoticeVm.musterNoticeScheduledDateDayBlur}
                      />
                      <span className={Qst.birthText}>일</span>
                    </div>
                  </div>
                  <div style={{ marginTop: '-27px' }}>
                    <Error
                      name="musterNoticeScheduledDate.year"
                      value={StockholdersMeetingMusterNoticeVm.musterNoticeScheduledDate.year}
                      errorCase={{
                        required: '연도를 입력해 주세요.',
                        pattern: {
                          value: regExp.year().test(StockholdersMeetingMusterNoticeVm.musterNoticeScheduledDate.year),
                          message: '올바른 연도를 입력해 주세요',
                        },
                      }}
                    />
                    <Error
                      name="musterNoticeScheduledDate.month"
                      value={StockholdersMeetingMusterNoticeVm.musterNoticeScheduledDate.month}
                      errorCase={{
                        required: StockholdersMeetingMusterNoticeVm.displayBirthErrorMessage(
                          'month',
                          'musterNoticeScheduledDate',
                        )
                          ? '월을 입력해 주세요.'
                          : null,
                        pattern: {
                          value: StockholdersMeetingMusterNoticeVm.displayBirthErrorMessage(
                            'month',
                            'musterNoticeScheduledDate',
                          )
                            ? regExp.month().test(StockholdersMeetingMusterNoticeVm.musterNoticeScheduledDate.month)
                            : null,
                          message: '올바른 월을 입력해 주세요',
                        },
                      }}
                    />
                    <Error
                      name="musterNoticeScheduledDate.day"
                      value={StockholdersMeetingMusterNoticeVm.musterNoticeScheduledDate.day}
                      errorCase={{
                        required: StockholdersMeetingMusterNoticeVm.displayBirthErrorMessage(
                          'day',
                          'musterNoticeScheduledDate',
                        )
                          ? '일(날짜)을 입력해 주세요.'
                          : null,
                        pattern: {
                          value: StockholdersMeetingMusterNoticeVm.displayBirthErrorMessage(
                            'day',
                            'musterNoticeScheduledDate',
                          )
                            ? regExp.day().test(StockholdersMeetingMusterNoticeVm.musterNoticeScheduledDate.day)
                            : null,
                          message: '올바른 일(날짜)을 입력해 주세요.',
                        },
                      }}
                    />
                  </div>
                </div>
              </ContentBox>
            )}
          </Question>
        )}
    </form>
  ));
}

export default StockholdersMeetingMusterNotice;
