import { _confirm } from '@model/dialogModel';
import etcInfoModel from '@model/establish/etcInfoModel';
import portalModel from '@model/portalModel';
import { purposeType } from '@pages/Establish/EtcInfo/BusinessPurpose/constant';
import { runInAction } from 'mobx';
import { useCallback } from 'react';

import NoteModal from '../components/NoteModal';

const useSortableItem = () => {
  const errorValidation = useCallback(
    (content) => {
      let error = false;
      let errorMessage = '';
      let errorState = 'yet';

      if (!content) {
        error = true;
        errorMessage = '사업목적을 입력해 주세요.';
      } else {
        const 업regExp = /(업|업무)\s*$/;
        const deleteSpaceRegExp = /\s/gi;
        const 업excep = [
          //'~업'이 들어가지 않아도 되는 사업목적
          '부동산중개업',
          '상업용 건축물 및 주택의 임대관리 등 부동산의 관리대행',
          '부동산의 이용 및 개발에 관한 상담',
          '개업공인중개사를 대상으로 한 중개업의 경영기법 및 경영정보의 제공',
          '중개의뢰인의 의뢰에 따른 도배, 이사업체의 소개 등 주거이전에 부수되는 용역의 알선',
          '상업용 건축물과 주택의 분양대행',
          '경매 또는 공매대상 부동산에 대한 권리분석 및 취득의 알선과 매수신청 또는 입찰신청의 대리',
        ];
        const 글자수 = content.trim().length;

        let 업excepYn = false;
        let is중복 = false;

        업excep.forEach((excepPurpose) => {
          if (content.replace(deleteSpaceRegExp, '') === excepPurpose.replace(deleteSpaceRegExp, '')) {
            업excepYn = true;
          }
        });

        let selectedLength = 0;

        etcInfoModel.businessPurposes.forEach((item) => {
          if (item.content === content) {
            selectedLength += 1;
          }
        });

        if (selectedLength > 1) {
          is중복 = true;
        }

        if (업excepYn) {
          //~'업'이 들어가지 않아도 되는 사업목적인 경우
          error = false;
        } else {
          if (!업regExp.test(content)) {
            error = true;
            errorMessage = "마지막 문구가 '~업' 또는 '~업무'로 끝나도록 입력해주세요.";
          } else if (글자수 < 3 || 글자수 > 80) {
            error = true;
            errorMessage = '3글자 이상 80글자 이하로 입력해주세요.';
          } else if (is중복) {
            error = true;
            errorMessage = '중복된 목적입니다. 하나만 남기고 삭제해주세요.';
          } else if (content == '무역업') {
            error = true;
            errorMessage = '상세사업을 앞에 입력해주세요. (EX. 의류 무역업)';
          } else if (content == '유통업') {
            error = true;
            errorMessage = '상세사업을 앞에 입력해주세요. (EX. 의류 유통업)';
          }
        }
      }

      if (error) {
        errorState = 'error';
      } else {
        errorState = 'success';
      }

      return {
        errorState,
        errorMessage,
      };
    },
    [etcInfoModel.businessPurposes],
  );

  const openModal = useCallback(({ content, cautions, minCapital, postRegistrationRequirements }) => {
    const modal = (
      <NoteModal
        content={content}
        cautions={cautions}
        minCapital={minCapital}
        postRegistrationRequirements={postRegistrationRequirements}
      />
    );

    runInAction(() => {
      portalModel.modalContent = modal;
      portalModel.btn = modal.btn ?? null;
      portalModel.closeBtn = true;
    });
  }, []);

  const onChangeContent = useCallback((e, idx) => {
    runInAction(() => {
      etcInfoModel.businessPurposes[idx].content = e.target.value;
    });
  }, []);

  const addPurpose = useCallback((content = '') => {
    runInAction(() => {
      etcInfoModel.businessPurposes.push({
        id: null,
        content,
        purposeType: purposeType.DIRECT,
        noteYn: false,
        note: null,
      });
    });
  }, []);

  /**
   * @note 매니저 분들이 직접입력 목적을 <textarea>에 한번에 복붙했을때 각각 직접입력 목적으로 나눠져서 들어가게 처리해주는 함수
   */
  const enterText = useCallback((e, index) => {
    const value = e.target.value;

    const splitArr = value.split('\n').filter((item) => item.length > 0);

    if (splitArr.length <= 1) {
      return;
    }

    runInAction(() => {
      splitArr.forEach((originalContent, idx) => {
        let content = originalContent;

        if (content.split('1. ').length !== 1) {
          content = content.split('1. ')[1];
        }

        if (idx === 0) {
          etcInfoModel.businessPurposes[index].content = content;

          return;
        }

        addPurpose(content);
      });
    });
  }, []);

  const removePurpose = useCallback(async (idx) => {
    const newArr = etcInfoModel.businessPurposes.filter((_, index) => idx !== index);

    if (await _confirm('선택한 목적을 삭제하시겠습니까?')) {
      etcInfoModel.businessPurposes = newArr;
    }
  }, []);

  return {
    errorValidation,
    openModal,
    onChangeContent,
    enterText,
    addPurpose,
    removePurpose,
  };
};

export default useSortableItem;
