import { highlightUrls } from '../../utils/highlightUrls';
import st from './style.module.scss';

const CheckNoteModal = ({ purposesWithNote = [], onClickNext }) => {
  return (
    <div className={st.Modal}>
      <div className={st.title}>목적 유의사항 안내</div>
      <div className={st.modalContentWrapper}>
        <table>
          <colgroup>
            <col width="180px" />
            <col width="200px" />
            <col />
            <col width="280px" />
          </colgroup>
          <thead>
            <tr>
              <th>목적</th>
              <th>최저자본금 제한</th>
              <th>사업자등록 등을 위한 주의사항</th>
              <th>등기 후 인허가, 등록, 신고 필요여부</th>
            </tr>
          </thead>
          <tbody>
            {purposesWithNote.map((purpose, idx) => (
              <CheckNoteRow
                key={idx}
                purpose={purpose.content}
                minCapital={purpose.note?.minCapital}
                cautions={purpose.note?.cautions}
                postRegistrationRequirements={purpose.note?.postRegistrationRequirements}
              />
            ))}
          </tbody>
        </table>
      </div>
      <div className={st.guide}>
        <div className={st.strong}>고객님, 위 유의사항을 확인하고, 다음 버튼을 눌러주세요.</div>
        <div className={st.text}>
          설립을 할 때에는 최저 자본금 등의 조건을 지키지 않아도 괜찮습니다.(일부 예외 있음)
          <br />
          다만 나중에 해당 업종으로 사업자등록을 할 때에는 조건을 지켜야 합니다. 추후 증자 등을 마쳐서 조건을 충족한 후,
          사업자등록을 신청하면 됩니다.
          <br />
          혹시 불필요한 사업목적이라면 팝업을 닫고, 해당 목적을 삭제하세요.
        </div>
      </div>
      <button type="button" className={st.next} onClick={onClickNext}>
        다음
      </button>
    </div>
  );
};

export const CheckNoteRow = ({ purpose = '', minCapital = '', cautions = '', postRegistrationRequirements = '' }) => {
  return (
    <tr>
      <td className={st.purpose}>{purpose}</td>
      <td className={st.minCapital}>
        {minCapital.length > 0 ? (
          <span style={{ color: '#eb4d59' }}>{minCapital}</span>
        ) : (
          <span style={{ color: '#B8B9BB' }}>없음</span>
        )}
      </td>
      <td className={st.registerCaution}>
        {cautions ? highlightUrls(cautions) : <span style={{ color: '#B8B9BB' }}>없음</span>}
      </td>
      <td className={st.noticeNecessary}>
        {postRegistrationRequirements ? postRegistrationRequirements : <span style={{ color: '#B8B9BB' }}>없음</span>}
      </td>
    </tr>
  );
};

export default CheckNoteModal;
