import etcInfoModel from '@model/establish/etcInfoModel';
import portalModel from '@model/portalModel';
import { purposeType } from '@pages/Establish/EtcInfo/BusinessPurpose/constant';
import { useAllRecommendPurposesQuery } from '@pages/Establish/EtcInfo/BusinessPurpose/queries';
import { runInAction } from 'mobx';
import { useLocalObservable } from 'mobx-react';
import { useEffect } from 'react';

const useAllPurposeGroupsSelect = () => {
  const { data } = useAllRecommendPurposesQuery();

  const mob = useLocalObservable(() => ({
    selectedGroupNameSet: new Set(),
    selectableGroupMap: new Map(),
    unSelectedGroupNameSet: new Set(),
    init(purposeGroups = []) {
      runInAction(() => {
        mob.reset();

        purposeGroups.forEach((group) => {
          const purposeMap = new Map();

          group.purposes.forEach((purpose) => {
            purposeMap.set(purpose.recommendedPurposeId, purpose);
          });

          mob.selectableGroupMap.set(group.groupNames.join(), purposeMap);
        });

        // 선택된 추천 목적들로 이미 선택된 그룹 초기 설정
        const selectedPurposes = etcInfoModel.businessPurposes.filter(
          (item) => item.purposeType === purposeType.RECOMMENDED,
        );

        mob.selectableGroupMap.forEach((purposeMap, key) => {
          const purposes = Array.from(purposeMap.values());

          const isGroupSelected = purposes.every((purpose) =>
            selectedPurposes.some((item) => item.recommendedPurposeId === purpose.recommendedPurposeId),
          );

          if (isGroupSelected) {
            mob.selectedGroupNameSet.add(key);
          }
        });
      });
    },
    reset() {
      mob.selectedGroupNameSet.clear();
      mob.selectableGroupMap.clear();
      mob.unSelectedGroupNameSet.clear();
    },
    checkGroupSelected(groupNames = []) {
      return mob.selectedGroupNameSet.has(groupNames.join());
    },
    toggleGroup(groupNames = []) {
      runInAction(() => {
        if (mob.selectedGroupNameSet.has(groupNames.join())) {
          mob.selectedGroupNameSet.delete(groupNames.join());
          mob.unSelectedGroupNameSet.add(groupNames.join());
        } else {
          mob.selectedGroupNameSet.add(groupNames.join());
          mob.unSelectedGroupNameSet.delete(groupNames.join());
        }
      });
    },
    submit() {
      runInAction(() => {
        // 선택한 그룹 중복 없이 추가
        mob.selectedGroupNameSet.forEach((key) => {
          const purposeMap = mob.selectableGroupMap.get(key);

          purposeMap.forEach((purpose) => {
            if (
              etcInfoModel.businessPurposes.some((item) => item.recommendedPurposeId === purpose.recommendedPurposeId)
            ) {
              return;
            }

            etcInfoModel.businessPurposes.push({
              id: null,
              recommendedPurposeId: purpose.recommendedPurposeId,
              content: purpose.content,
              purposeType: purposeType.RECOMMENDED,
              purposeClassification: purpose.purposeClassification,
              noteYn: Boolean(purpose.note),
              note: purpose.note
                ? {
                    minCapital: purpose.note?.minCapital || '',
                    cautions: purpose.note?.cautions || '',
                    postRegistrationRequirements: purpose.note?.postRegistrationRequirements || '',
                  }
                : null,
            });
          });
        });

        // 선택되어있었으나 해제한 그룹 삭제
        mob.unSelectedGroupNameSet.forEach((key) => {
          const purposeMap = mob.selectableGroupMap.get(key);

          purposeMap.forEach((purpose) => {
            const index = etcInfoModel.businessPurposes.findIndex(
              (item) => item.recommendedPurposeId === purpose.recommendedPurposeId,
            );

            if (index === -1) {
              return;
            }

            etcInfoModel.businessPurposes.splice(index, 1);
          });
        });

        mob.reset();
        portalModel.setModalClose();
      });
    },
  }));

  useEffect(() => {
    mob.init(data.results.map((item) => item.purposeGroups).flat());
  }, [data.results]);

  return {
    headers: data.headers,
    results: data.results,
    groupSelectMob: mob,
  };
};

export default useAllPurposeGroupsSelect;
