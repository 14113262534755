import formDataToJson, { errorFocus } from '@common/module/submit';
import StandbyPaidInCapitalIncreaseStepModel from '@model/changeRegistration/StandbyPaidInCapitalIncreaseStepModel';
import stepService from '@service/stepService';
import { observable, runInAction } from 'mobx';

class StandbySimpleNewSharesAcquirerVm {
  constructor() {
    this.state = observable({
      openIdx: null,
      errorArr: [],
      reviseInfo: false,
      reviseInfoList: [],
    });
  }
  errorState() {
    const error = document.querySelectorAll('[data-error="error"]');
    let errorIdx = [];

    error.forEach((error) => {
      errorIdx.push(Number(error.closest('[data-eq]').dataset.eq));
    });
    errorIdx = errorIdx.filter((elem, idx) => {
      return errorIdx.indexOf(elem) === idx;
    });
    this.state.errorArr = errorIdx;
  }
  load() {
    if (this.state.reviseInfoList.length === 0) {
      this.defaultReviseInfoList();
    }
    if (this.state.reviseInfoList.length !== 0) {
      this.state.openIdx = 0;
    }
  }
  defaultReviseInfoList() {
    this.state.reviseInfoList = [];
    StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers.forEach((item) => {
      if (item.shareholderType === 'KOREAN_PERSON') {
        this.state.reviseInfoList.push({
          koreanName: item.koreanName ? false : true,
          kssn: item.kssn ? false : true,
          koreanAddress: item.koreanAddress ? false : true,
        });
      } else if (item.shareholderType === 'FOREIGNER_PERSON') {
        //국적
        let nationalityTypeYn = true;

        if (item.nationalityType) {
          nationalityTypeYn = false;
        }
        if (item.nationalityType === '기타' && !item.nationalityOthers) {
          nationalityTypeYn = true;
        }
        if (item.nationalityType && item.nationalityOthers) {
          nationalityTypeYn = false;
        }

        // 외국인 주소
        let addressYn = true;

        if (item.residenceRegistrationYn) {
          if (item.koreanAddress) {
            addressYn = false;
          }
        } else {
          if (item.koreanAddress && item.englishAddress) {
            addressYn = false;
          }
        }

        this.state.reviseInfoList.push({
          name: item.englishName && item.koreanName ? false : true,
          nationalityType: nationalityTypeYn,
          birth: item?.birth?.year && item?.birth?.month && item?.birth?.day ? false : true,
          residenceRegistration: item.residenceRegistrationYn || item.residenceRegistrationYn === false ? false : true,
          address: addressYn,
        });
      } else if (item.shareholderType === 'KOREAN_COMPANY') {
        //대표직책
        let representativeYn = true;

        if (item.koreanCompanyRepresentatives) {
          if (item.koreanCompanyRepresentatives.length !== 0) {
            item.koreanCompanyRepresentatives.forEach((representative) => {
              if (representative.name || representative.position) {
                representativeYn = false;
              }
            });
          }
        }

        this.state.reviseInfoList.push({
          koreanName: item.koreanName ? false : true,
          address: item.koreanAddress ? false : true,
          representative: representativeYn,
        });
      } else if (item.shareholderType === 'FOREIGNER_COMPANY') {
        //국적
        let nationalityTypeYn = true;

        if (item.nationalityType) {
          nationalityTypeYn = false;
        }
        if (item.nationalityType === '기타' && !item.nationalityOthers) {
          nationalityTypeYn = true;
        }
        if (item.nationalityType && item.nationalityOthers) {
          nationalityTypeYn = false;
        }

        //대표직책
        let representativeYn = true;

        if (item.foreignerCompanyRepresentatives) {
          if (item.foreignerCompanyRepresentatives.length !== 0) {
            item.foreignerCompanyRepresentatives.forEach((representative) => {
              if (representative.koreanName && representative.englishName) {
                representativeYn = false;
              }
            });
          }
        }

        this.state.reviseInfoList.push({
          nationalityType: nationalityTypeYn,
          name: item.englishName && item.koreanName ? false : true,
          address: item.koreanAddress && item.englishAddress ? false : true,
          representative: representativeYn,
        });
      } else if (item.shareholderType === 'FUND_AND_INVESTMENT_ASSOCIATION') {
        //대표직책
        let representativeYn = true;

        if (item.representatives) {
          if (item.representatives.length !== 0) {
            item.representatives.forEach((representative) => {
              if (representative.name) {
                representativeYn = false;
              }
            });
          }
        }

        this.state.reviseInfoList.push({
          name: item.koreanName ? false : true,
          address: item.koreanAddress ? false : true,
          representative: representativeYn,
        });
      }
    });
  }
  submit(e) {
    e.preventDefault();
    if (formDataToJson(e.target, false).error) {
      //입력하지 않은 데이터가 있는 경우
      this.errorState();
      this.state.openIdx = this.state.errorArr[0];
      const findError = setInterval(() => {
        if (document.querySelector('.open')) {
          clearInterval(findError);
          errorFocus();
        }
      }, 30);

      return;
    }

    this.state.errorArr = [];
    this.state.openIdx = null;

    const param = formDataToJson(e.target).data;

    stepService.saveStep(param);
  }
  get newSharesAcquirers() {
    return StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers;
  }
  setIsOpen(idx) {
    return this.state.openIdx === idx;
  }
  setDropOpen(idx) {
    if (this.state.openIdx === idx) {
      this.state.openIdx = null;
      this.errorState();
    } else {
      this.state.openIdx = idx;
    }
  }
  setTitle(idx) {
    const name = StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].koreanName;
    const isNewYn = StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].isNewYn;

    return `신주인수인 : ${name} 주주님 ${isNewYn ? '(새로운 분)' : ''}`;
  }
  personErrorYn(idx) {
    return this.state.errorArr.indexOf(idx) !== -1;
  }
  setShareholderType(e, idx) {
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].shareholderType = e.target.value;
    });
    this.defaultReviseInfoList();
  }
  reviseInfo() {
    this.state.reviseInfo = !this.state.reviseInfo;
  }
  setReviseInfoList(idx, infoType) {
    if (this.state.reviseInfoList.length !== 0) {
      this.state.reviseInfoList[idx][infoType] = !this.state.reviseInfoList[idx][infoType];
    }
  }
}

export default new StandbySimpleNewSharesAcquirerVm();
