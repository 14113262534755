import { useObserver } from 'mobx-react';
import ExecutiveVm from './ExecutiveVm';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './Executive.module.scss';
import clsx from 'clsx';
import AddBtn from '@comComponents/atoms/Button/AddBtn/AddBtn';
import Drop from '@comComponents/molecules/Dropdown/Dropdown';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import SquareBtn from '@comComponents/atoms/Button/SquareBtn/SquareBtn';
import Icon from '@comComponents/atoms/Icon/Icon';
import Select from '@comComponents/atoms/Input/Select/Select';
import TextBox from '@comComponents/atoms/Text/TextBox/TextBox';
import Badge from '@comComponents/atoms/BadgeBox/BadgeBox';
import LikeBtn from '@comComponents/atoms/HyperLink/LikeBtn/LikeBtn';
import regExp from '@common/module/regExp';
import SearchAddress from '@comComponents/atoms/SearchAddress/SearchAddress';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import ExecutivePosition from './ExecutivePosition';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import { useEffect } from 'react';
import { toJS } from 'mobx';
import AiChat from '@pages/Establish/AiChat/AiChat';

function Executive() {
  useEffect(() => {
    if (ExecutiveVm.executiveMembers.length === 0) {
      //임원 처음 입력할 때, 처음 리스트 추가 및 열기
      ExecutiveVm.addExecutive();
      ExecutiveVm.state.openIdx = 0;
    }
  }, []);

  return useObserver(() => (
    <form onSubmit={(e) => ExecutiveVm.submit(e)}>
      <input type="submit" value="submit" id="stepForm" style={{ display: 'none' }} />
      <Question list={true}>
        <ContentBox>
          <div className={Qst.dropHeader}>
            <div className={Qst.dropTitleWrap}>
              <div className={Qst.dropTitle}>임원정보를 입력해주세요.</div>
              <div className={Qst.dropTitleExplain}>고객님께서 작성하신 임원분들 리스트입니다.</div>
            </div>
            {ExecutiveVm.addYn && <AddBtn onClick={() => ExecutiveVm.addExecutive()}>임원 추가하기</AddBtn>}
          </div>
        </ContentBox>
      </Question>
      {ExecutiveVm.executiveMembers.map((item, idx) => {
        return (
          <Question list={true} key={idx}>
            <ContentBox>
              <Drop
                dataEq={`${idx}`}
                className={clsx(Qst.dropDown, ExecutiveVm.setIsOpen(idx) ? 'open' : 'close')}
                title={ExecutiveVm.setTitle(idx)}
                onClick={() => ExecutiveVm.setDropOpen(idx)}
                isOpen={ExecutiveVm.setIsOpen(idx)}
                closeBtn={ExecutiveVm.addYn ? () => ExecutiveVm.setCloseBtn(idx) : null}
                error={ExecutiveVm.personErrorYn(idx)}
              >
                <input type="hidden" name={`executiveMembers[${idx}].id`} value={item.id} />
                {!ExecutiveVm.isOnlyNew && (
                  <div className={Qst.spaceBetween}>
                    <div className={Qst.infoSection}>
                      <div className={Qst.titleWrap}>
                        <h2 className={clsx(Qst.title, Qst.inside)}>
                          <span className={Qst.bold}>{idx + 1}번째 임원은 어떤 분</span>인가요?
                        </h2>
                      </div>
                      <ul className={Qst.answerList}>
                        {ExecutiveVm.canExecutive(item.shareholderId).map((shareholderId, index) => {
                          return (
                            <li key={index}>
                              <Radio
                                className={Qst.answerLabel}
                                value={shareholderId}
                                activeBackground
                                checked={Number(item.shareholderId) === shareholderId}
                                onChange={(e) => ExecutiveVm.setShareholderId(e, idx)}
                                name={`executiveMembers[${idx}].shareholderId`}
                              >
                                {ExecutiveVm.text.name} {ExecutiveVm.canExecutiveName(shareholderId)}님
                              </Radio>
                            </li>
                          );
                        })}
                        <li>
                          <Radio
                            className={Qst.answerLabel}
                            value="new"
                            activeBackground
                            checked={item.shareholderId === 'new'}
                            onChange={(e) => ExecutiveVm.setShareholderId(e, idx)}
                            name={`executiveMembers[${idx}].shareholderId`}
                          >
                            새로운 분
                          </Radio>
                        </li>
                      </ul>
                      <Error
                        name={`executiveMembers[${idx}].shareholderId`}
                        value={item.shareholderId}
                        errorCase={{ required: '임원이 되실 분을 선택해 주세요.' }}
                      />
                    </div>
                    {ExecutiveVm.executiveTipsDisplayYn && (
                      <div className={Qst.qnaSection}>
                        <Qna tip="임원 등록하기" className={Qst.qnaTitle}>
                          {(ExecutiveVm.smartFormType === 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY' ||
                            ExecutiveVm.smartFormType === 'ESTABLISHMENT_FISHERY_STOCK_COMPANY') && ( //농업회사법인, 어업회사법인일 경우
                            <div className={Qst.qnaBox}>
                              <QText>누구나 {ExecutiveVm.text.formType}의 임원이 될 수 있나요?</QText>
                              <div className="answerBox">
                                <Answer className={Qst.answer}>
                                  최소한 이사의 1/3이상이 {ExecutiveVm.text.person}이어야 합니다.
                                </Answer>
                                <p className={Qst.explain}>
                                  따라서 이사가 1명 뿐이라면 반드시 {ExecutiveVm.text.person}이어야 합니다. 감사는{' '}
                                  {ExecutiveVm.text.person}일 필요가 없습니다. 대표는 {ExecutiveVm.text.person}이
                                  아니어도 무방합니다.
                                </p>
                              </div>
                            </div>
                          )}
                          <div className={Qst.qnaBox}>
                            <QText>임원 입력시 주의할 사항이 있나요?</QText>
                            <div className="answerBox">
                              <Answer className={Qst.answer}>주식을 가지지 않은 임원을 꼭 추가해 주세요.</Answer>
                              <p className={Qst.explain}>
                                상법상 주식회사를 설립할 때에는 발기인총회에서 조사보고를 할 사람("조사보고자")이
                                필요하기 때문입니다. 조사보고자는 주식을 가지고 있지 않은 임원 또는 공증변호사만이 맡을
                                수 있습니다. 모든 임원이 주주가 되는 경우, 조사보고자를 맡을 사람이 없기 때문에,
                                공증변호사를 선임해야 합니다. 이 경우 160만 원 가량의 추가비용이 들기 때문에, 경제적이지
                                않습니다.
                              </p>
                            </div>
                          </div>
                          <div className={Qst.qnaBox}>
                            <QText>미성년자가 임원이 될 수 있나요?</QText>
                            <div className="answerBox">
                              <Answer className={Qst.answer}>
                                만 16세를 넘은 경우 미성년자도 임원이 될 수 있습니다.
                              </Answer>
                              <p className={Qst.explain}>
                                미성년자의 기본증명서(상세), 가족관계증명서(일반 또는 상세)를 법원에 추가로 제출해야
                                합니다.
                              </p>
                            </div>
                          </div>
                          <div className={Qst.qnaBox}>
                            <QText>외국인이 임원이 되는데 제한이 있나요?</QText>
                            <div className="answerBox">
                              <Answer className={Qst.answer}>
                                헬프미에서는 거소등록(외국인등록)을 한 외국인이 임원이 되는 경우 서비스 제공이
                                가능하지만, 거소등록(외국인동록)하지 않은 외국인을 임원으로 등록하는 경우, 서비스 제공이
                                어렵습니다.
                              </Answer>
                              <p className={Qst.explain}>
                                거소등록(외국인등록)하지 않은 외국인이 임원으로 등기하기 위해서는 아포스티유 인증 또는
                                영사관 인증이 필요해서, 절차가 상당히 복잡하기 때문에, 서비스를 제공하고 있지 않습니다.
                                양해 부탁드립니다.
                              </p>
                            </div>
                          </div>
                        </Qna>
                      </div>
                    )}
                  </div>
                )}
                {item.shareholderId !== 'new' && item.shareholderId && (
                  <>
                    <ExecutivePosition
                      name={`executiveMembers[${idx}].position`}
                      idx={idx}
                      position={item.position}
                    ></ExecutivePosition>
                    {ExecutiveVm.shareholderKoreanYn(item.shareholderId) === false && (
                      <div className={Qst.spaceBetween}>
                        <div className={Qst.infoSection}>
                          <div className={Qst.titleWrap}>
                            <h2 className={clsx(Qst.title, Qst.inside)}>
                              <span className={Qst.bold}>인감증명서</span> 또는{' '}
                              <span className={Qst.bold}>본인서명사실확인서</span>를 제출할 수 있는 외국인인가요?
                            </h2>
                            <div className={Qst.titleExplain}>
                              설립의 경우, 인감증명서 준비를 추천드립니다. (본인서명사실확인서의 경우 서명할 문서가
                              많아서 번거로울 수 있습니다.)
                            </div>
                          </div>
                          <ul className={Qst.answerList}>
                            <li>
                              <Radio
                                className={Qst.answerLabel}
                                value={true}
                                activeBackground
                                checked={item.foreignerCertificationYn === true}
                                onChange={(e) => ExecutiveVm.setForeignerCertificationYn(e, idx)}
                                name={`executiveMembers[${idx}].foreignerCertificationYn`}
                              >
                                네
                              </Radio>
                            </li>
                            <li>
                              <Radio
                                className={Qst.answerLabel}
                                checked={false}
                                onClick={() => ExecutiveVm.openModal('ForeignerCertification')}
                              >
                                아니오
                              </Radio>
                            </li>
                          </ul>
                          <Error
                            name={`executiveMembers[${idx}].foreignerCertificationYn`}
                            value={item.foreignerCertificationYn}
                            errorCase={{ required: '인감증명서 또는 본인서명사실확인서 제출 여부를 선택해 주세요.' }}
                          />
                        </div>
                        <div className={Qst.qnaSection}>
                          <Qna className={Qst.noTitle}>
                            <div className={Qst.qnaBox}>
                              <QText>인감증명서 또는 본인서명사실확인서가 꼭 필요한가요?</QText>
                              <div className="answerBox">
                                <Answer className={Qst.answer}>
                                  등기시 외국인 임원의 인감증명서 또는 본인서명확인서가 꼭 필요합니다.
                                </Answer>
                                <p className={Qst.explain}>
                                  법인설립 또는 임원등기시 외국인 임원의 인감증명서 또는 본인서명사실확인서 제출이 꼭
                                  필요하므로, 시간을 절약하기 위해 미리 준비해주세요.
                                </p>
                              </div>
                            </div>
                            <div className={Qst.qnaBox}>
                              <QText>외국인의 경우, 인감증명서 또는 본인서명사실확인서를 어떻게 발급받나요?</QText>
                              <div className="answerBox">
                                <Answer className={Qst.answer}>
                                  본인이 주소지 관할 주민센터에 서류를 가지고 방문하면 바로 발급받습니다.
                                </Answer>
                                <div className={st.explainBox}>
                                  <div className={st.title}>인감증명서의 경우</div>
                                  <ul className={st.list}>
                                    <li>
                                      최초발급 : 본인이 인감도장, 외국인등록증(거소사실증명서), 여권을 가지고 거주지관할
                                      주민센터에 방문하면 됩니다.
                                    </li>
                                    <li>추가발급 : 본인이 외국인등록증, 여권을 가지고 주민센터에 방문하면 됩니다.</li>
                                  </ul>
                                </div>
                                <div className={st.explainBox}>
                                  <div className={st.title}>본인서명사실확인서의 경우</div>
                                  <ul className={st.list}>
                                    <li>
                                      최초발급 : 본인이 외국인등록증(거소사실증명서), 여권을 가지고 주민센터에 방문하면
                                      됩니다.
                                    </li>
                                    <li>
                                      추가발급 : 외국인등록증(거소사실증명서), 여권을 가지고 주민센터를 방문하거나,
                                      정부24 사이트에서 온라인 발급이 가능합니다. (행정기관 제출용도만 온라인발급 가능)
                                    </li>
                                    <li>
                                      발급용도는 "주식회사 OOO 설립등기"등으로 구체적으로 적어야 하고, 대리인도 기재를
                                      해야 합니다. 발급받기 전에 담당매니저에게 용도란, 대리인란에 적을 문구를 확인받고
                                      발급해주세요.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </Qna>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {(item.shareholderId === 'new' || ExecutiveVm.isOnlyNew) && ( //새로운 임원
                  <>
                    <div className={Qst.spaceBetween}>
                      <div className={Qst.infoSection}>
                        <div className={Qst.titleWrap}>
                          <h2 className={clsx(Qst.title, Qst.inside)}>
                            <span className={Qst.bold}>{ExecutiveVm.titleName}의 국적</span>을 선택해 주세요.
                          </h2>
                        </div>
                        <ul className={Qst.answerList}>
                          <li>
                            <Radio
                              className={Qst.answerLabel}
                              value={true}
                              activeBackground
                              checked={item.koreanYn === true}
                              onChange={(e) => ExecutiveVm.setKoreanYn(e, idx)}
                              name={`executiveMembers[${idx}].koreanYn`}
                            >
                              한국인입니다.
                            </Radio>
                          </li>
                          <li>
                            <Radio
                              className={Qst.answerLabel}
                              value={false}
                              activeBackground
                              checked={item.koreanYn === false}
                              onChange={(e) => ExecutiveVm.setKoreanYn(e, idx)}
                              name={`executiveMembers[${idx}].koreanYn`}
                            >
                              외국인입니다.
                            </Radio>
                          </li>
                        </ul>
                        <Error
                          name={`executiveMembers[${idx}].koreanYn`}
                          value={item.koreanYn}
                          errorCase={{ required: '국적을 선택해 주세요.' }}
                        />
                      </div>
                      {ExecutiveVm.executiveTipsDisplayYn(idx) && (
                        <div className={Qst.qnaSection}>
                          <AiChat />
                          <Qna tip="임원 등록하기" className={Qst.qnaTitle}>
                            {(ExecutiveVm.smartFormType === 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY' ||
                              ExecutiveVm.smartFormType === 'ESTABLISHMENT_FISHERY_STOCK_COMPANY') && ( //농업회사법인, 어업회사법인일 경우
                              <div className={Qst.qnaBox}>
                                <QText>누구나 {ExecutiveVm.text.formType}의 임원이 될 수 있나요?</QText>
                                <div className="answerBox">
                                  <Answer className={Qst.answer}>
                                    최소한 이사의 1/3이상이 {ExecutiveVm.text.person}이어야 합니다.
                                  </Answer>
                                  <p className={Qst.explain}>
                                    따라서 이사가 1명 뿐이라면 반드시 {ExecutiveVm.text.person}이어야 합니다. 감사는{' '}
                                    {ExecutiveVm.text.person}일 필요가 없습니다. 대표는 {ExecutiveVm.text.person}이
                                    아니어도 무방합니다.
                                  </p>
                                </div>
                              </div>
                            )}
                            <div className={Qst.qnaBox}>
                              <QText>임원 입력시 주의할 사항이 있나요?</QText>
                              <div className="answerBox">
                                <Answer className={Qst.answer}>주식을 가지지 않은 임원을 꼭 추가해 주세요.</Answer>
                                <p className={Qst.explain}>
                                  상법상 주식회사를 설립할 때에는 발기인총회에서 조사보고를 할 사람("조사보고자")이
                                  필요하기 때문입니다. 조사보고자는 주식을 가지고 있지 않은 임원 또는 공증변호사만이
                                  맡을 수 있습니다. 모든 임원이 주주가 되는 경우, 조사보고자를 맡을 사람이 없기 때문에,
                                  공증변호사를 선임해야 합니다. 이 경우 160만 원 가량의 추가비용이 들기 때문에,
                                  경제적이지 않습니다.
                                </p>
                              </div>
                            </div>
                            <div className={Qst.qnaBox}>
                              <QText>미성년자가 임원이 될 수 있나요?</QText>
                              <div className="answerBox">
                                <Answer className={Qst.answer}>
                                  만 16세를 넘은 경우 미성년자도 임원이 될 수 있습니다.
                                </Answer>
                                <p className={Qst.explain}>
                                  미성년자의 기본증명서(상세), 가족관계증명서(일반 또는 상세)를 법원에 추가로 제출해야
                                  합니다.
                                </p>
                              </div>
                            </div>
                            <div className={Qst.qnaBox}>
                              <QText>외국인이 임원이 되는데 제한이 있나요?</QText>
                              <div className="answerBox">
                                <Answer className={Qst.answer}>
                                  헬프미에서는 거소등록(외국인등록)을 한 외국인이 임원이 되는 경우 서비스 제공이
                                  가능하지만, 거소등록(외국인등록)하지 않은 외국인을 임원으로 등록하는 경우, 서비스
                                  제공이 어렵습니다.
                                </Answer>
                                <p className={Qst.explain}>
                                  거소등록(외국인등록)하지 않은 외국인이 임원으로 등기하기 위해서는 아포스티유 인증 또는
                                  영사관 인증이 필요해서, 절차가 상당히 복잡하기 때문에, 서비스를 제공하고 있지
                                  않습니다. 양해 부탁드립니다.
                                </p>
                              </div>
                            </div>
                          </Qna>
                        </div>
                      )}
                    </div>
                    {item.koreanYn === true && ( //한국인
                      <>
                        <div className={Qst.spaceBetween}>
                          <div className={Qst.infoSection}>
                            <div className={Qst.titleWrap}>
                              <h2 className={clsx(Qst.title, Qst.inside)}>
                                <span className={Qst.bold}>{ExecutiveVm.titleName}의 성함을 입력</span>해 주세요.
                              </h2>
                            </div>
                            <InputText
                              placeholder="ex)홍길동"
                              style={{ width: '316px' }}
                              value={item.koreanName}
                              onChange={(e) => ExecutiveVm.setKoreanName(e, idx)}
                              name={`executiveMembers[${idx}].koreanName`}
                              errorText={
                                <Error
                                  name={`executiveMembers[${idx}].koreanName`}
                                  value={item.koreanName}
                                  errorCase={{ required: '성함을 입력해 주세요.' }}
                                />
                              }
                            />
                            <span className={Qst.nameInput}>님</span>
                          </div>
                        </div>
                        <div className={Qst.spaceBetween}>
                          <div className={Qst.infoSection}>
                            <div className={Qst.titleWrap}>
                              <h2 className={clsx(Qst.title, Qst.inside)}>
                                <span className={Qst.bold}>
                                  {item.koreanName ? item.koreanName + '님' : ExecutiveVm.titleName}의 주민등록번호를
                                  입력
                                </span>
                                해 주세요.
                              </h2>
                              <div className={Qst.titleExplain}>
                                주민등록번호는 헬프미 스마트 보안 시스템에 의하여 안전하게 보관됩니다.
                              </div>
                            </div>

                            <InputText
                              placeholder="111111-1111111"
                              style={{ width: '316px' }}
                              textType="kssn"
                              value={item.kssn}
                              onChange={(e) => ExecutiveVm.setKssn(e, idx)}
                              name={`executiveMembers[${idx}].kssn`}
                              errorText={
                                <Error
                                  name={`executiveMembers[${idx}].kssn`}
                                  value={item.kssn}
                                  errorCase={{
                                    required: '주민등록번호를 입력해 주세요',
                                    validate: {
                                      func: regExp.kssn(item.kssn),
                                      message: '올바른 주민등록번호를 입력해 주세요.',
                                    },
                                  }}
                                />
                              }
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {item.koreanYn === false && ( //외국인
                      <>
                        <div className={Qst.spaceBetween}>
                          <div className={Qst.infoSection}>
                            <div className={Qst.titleWrap}>
                              <h2 className={clsx(Qst.title, Qst.inside)}>
                                <span className={Qst.bold}>한국에 거소등록 또는 외국인등록을 한 외국인</span> 인가요?
                              </h2>
                            </div>
                            <ul className={Qst.answerList}>
                              <li>
                                <Radio
                                  className={Qst.answerLabel}
                                  value={true}
                                  activeBackground
                                  checked={item.residenceRegistrationYn === true}
                                  onChange={(e) => ExecutiveVm.setResidenceRegistrationYn(e, idx)}
                                  name={`executiveMembers[${idx}].residenceRegistrationYn`}
                                >
                                  네
                                </Radio>
                              </li>
                              <li>
                                <Radio
                                  className={Qst.answerLabel}
                                  checked={false}
                                  onClick={() => ExecutiveVm.openModal('ResidenceRegistrationModal')}
                                >
                                  아니오
                                </Radio>
                              </li>
                            </ul>
                            <Error
                              name={`executiveMembers[${idx}].residenceRegistrationYn`}
                              value={item.residenceRegistrationYn}
                              errorCase={{ required: '거소등록 또는 외국인등록 여부를 선택해 주세요.' }}
                            />
                          </div>
                          <div className={Qst.qnaSection}>
                            <Qna className={Qst.noTitle}>
                              <QText>
                                거소등록(외국인등록)하지 않은 외국인은 법인설립에
                                <br /> 참여할 때 제약이 있나요?
                              </QText>
                              <div className="answerBox">
                                <Answer className={Qst.answer}>
                                  한국에서 거소등록(외국인등록)하지 않은 외국인의 경우, 몇 가지 제약이 있습니다.
                                </Answer>
                                <p className={Qst.explain}>
                                  거소등록(외국인등록)하지 않은 외국인이 {ExecutiveVm.text.name}로 참여하는 경우, 한국인{' '}
                                  {ExecutiveVm.text.name} 또는 거소등록(외국인등록)한 외국인 {ExecutiveVm.text.name}{' '}
                                  또는 한국법인 {ExecutiveVm.text.name}가 1명 이상 더 있다면, 서비스 제공이 가능합니다.
                                  하지만 그렇지 않은 경우, 서비스 제공이 어렵습니다. 거소등록(외국인등록)하지 않은
                                  외국인이 단독 {ExecutiveVm.text.name}가 되기 위해서는 아포스티유 인증 또는 영사관
                                  인증이 필요하고, 절차가 상당히 복잡하기 때문에, 서비스를 제공하고 있지 않습니다. 양해
                                  부탁드립니다.
                                </p>
                                <p className={Qst.explain}>
                                  또한 거소등록(외국인등록)하지 않은 외국인을 임원으로 등록하는 경우, 서비스 제공이
                                  어렵습니다. 거소등록(외국인등록)하지 않은 외국인이 임원으로 등록되려면, 아포스티유
                                  인증 또는 영사관 인증이 필요하고, 절차가 상당히 복잡하기 때문에, 서비스를 제공하고
                                  있지 않습니다. 양해 부탁드립니다.
                                </p>
                              </div>
                            </Qna>
                          </div>
                        </div>
                        <div className={Qst.spaceBetween}>
                          <div className={Qst.infoSection}>
                            <div className={Qst.titleWrap}>
                              <h2 className={clsx(Qst.title, Qst.inside)}>
                                <span className={Qst.bold}>인감증명서</span> 또는{' '}
                                <span className={Qst.bold}>본인서명사실확인서</span>를 제출할 수 있는 외국인인가요?
                              </h2>
                              <div className={Qst.titleExplain}>
                                설립의 경우, 인감증명서 준비를 추천드립니다. (본인서명사실확인서의 경우 서명할 문서가
                                많아서 번거로울 수 있습니다.)
                              </div>
                            </div>
                            <ul className={Qst.answerList}>
                              <li>
                                <Radio
                                  className={Qst.answerLabel}
                                  value={true}
                                  activeBackground
                                  checked={item.foreignerCertificationYn === true}
                                  onChange={(e) => ExecutiveVm.setForeignerCertificationYn(e, idx)}
                                  name={`executiveMembers[${idx}].foreignerCertificationYn`}
                                >
                                  네
                                </Radio>
                              </li>
                              <li>
                                <Radio
                                  className={Qst.answerLabel}
                                  checked={false}
                                  onClick={() => ExecutiveVm.openModal('ForeignerCertification')}
                                >
                                  아니오
                                </Radio>
                              </li>
                            </ul>
                            <Error
                              name={`executiveMembers[${idx}].foreignerCertificationYn`}
                              value={item.foreignerCertificationYn}
                              errorCase={{ required: '인감증명서 또는 본인서명사실확인서 제출 여부를 선택해 주세요.' }}
                            />
                          </div>
                          <div className={Qst.qnaSection}>
                            <Qna className={Qst.noTitle}>
                              <div className={Qst.qnaBox}>
                                <QText>인감증명서 또는 본인서명사실확인서가 꼭 필요한가요?</QText>
                                <div className="answerBox">
                                  <Answer className={Qst.answer}>
                                    등기시 외국인 임원의 인감증명서 또는 본인서명확인서가 꼭 필요합니다.
                                  </Answer>
                                  <p className={Qst.explain}>
                                    법인설립 또는 임원등기시 외국인 임원의 인감증명서 또는 본인서명사실확인서 제출이 꼭
                                    필요하므로, 시간을 절약하기 위해 미리 준비해주세요.
                                  </p>
                                </div>
                              </div>
                              <div className={Qst.qnaBox}>
                                <QText>외국인의 경우, 인감증명서 또는 본인서명사실확인서를 어떻게 발급받나요?</QText>
                                <div className="answerBox">
                                  <Answer className={Qst.answer}>
                                    본인이 주소지 관할 주민센터에 서류를 가지고 방문하면 바로 발급받습니다.
                                  </Answer>
                                  <div className={st.explainBox}>
                                    <div className={st.title}>인감증명서의 경우</div>
                                    <ul className={st.list}>
                                      <li>
                                        최초발급 : 본인이 인감도장, 외국인등록증(거소사실증명서), 여권을 가지고
                                        거주지관할 주민센터에 방문하면 됩니다.
                                      </li>
                                      <li>추가발급 : 본인이 외국인등록증, 여권을 가지고 주민센터에 방문하면 됩니다.</li>
                                    </ul>
                                  </div>
                                  <div className={st.explainBox}>
                                    <div className={st.title}>본인서명사실확인서의 경우</div>
                                    <ul className={st.list}>
                                      <li>
                                        최초발급 : 본인이 외국인등록증(거소사실증명서), 여권을 가지고 주민센터에
                                        방문하면 됩니다.
                                      </li>
                                      <li>
                                        추가발급 : 외국인등록증(거소사실증명서), 여권을 가지고 주민센터를 방문하거나,
                                        정부24 사이트에서 온라인 발급이 가능합니다. (행정기관 제출용도만 온라인발급
                                        가능)
                                      </li>
                                      <li>
                                        발급용도는 "주식회사 OOO 설립등기"등으로 구체적으로 적어야 하고, 대리인도 기재를
                                        해야 합니다. 발급받기 전에 담당매니저에게 용도란, 대리인란에 적을 문구를
                                        확인받고 발급해주세요.
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </Qna>
                          </div>
                        </div>
                        {item.residenceRegistrationYn === true && (
                          <>
                            <div className={Qst.spaceBetween}>
                              <div className={Qst.infoSection}>
                                <div className={Qst.titleWrap}>
                                  <h2 className={clsx(Qst.title, Qst.inside)}>
                                    <span className={Qst.bold}>국적을 선택</span>해 주세요.
                                  </h2>
                                </div>
                                <Select
                                  className={Qst.select}
                                  style={{ width: '142px' }}
                                  name={`executiveMembers[${idx}].nationalityType`}
                                  value={item.nationalityType ?? ''}
                                  onChange={(e) => ExecutiveVm.setNationalityType(e, idx)}
                                  errorText={
                                    <Error
                                      name={`executiveMembers[${idx}].nationalityType`}
                                      value={item.nationalityType}
                                      errorCase={{ required: '국적을 선택해 주세요.' }}
                                      style={{ width: '160px' }}
                                    />
                                  }
                                >
                                  <option value="">-</option>
                                  <option value="일본">일본</option>
                                  <option value="미국">미국</option>
                                  <option value="영국">영국</option>
                                  <option value="중국">중국(본토)</option>
                                  <option value="프랑스">프랑스</option>
                                  <option value="독일">독일</option>
                                  <option value="캐나다">캐나다</option>
                                  <option value="홍콩">홍콩</option>
                                  <option value="기타">기타</option>
                                </Select>
                                {item.nationalityType === '기타' && (
                                  <div className={clsx(Qst.rowWrap, st.rowWrap)}>
                                    <InputText
                                      className={Qst.inputText}
                                      style={{ width: '276px' }}
                                      explain="기타 국적"
                                      name={`executiveMembers[${idx}].nationalityOthers`}
                                      value={item.nationalityOthers}
                                      onChange={(e) => ExecutiveVm.setNationalityOthers(e, idx)}
                                      errorText={
                                        <Error
                                          name={`executiveMembers[${idx}].nationalityOthers`}
                                          value={item.nationalityOthers}
                                          errorCase={{ required: '기타국적을 입력해 주세요.' }}
                                        />
                                      }
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className={Qst.spaceBetween}>
                              <div className={Qst.infoSection}>
                                <div className={Qst.titleWrap}>
                                  <h2 className={clsx(Qst.title, Qst.inside)}>
                                    <span className={Qst.bold}>
                                      {ExecutiveVm.titleName}의 이름을 로마자와 한글로 입력
                                    </span>
                                    해 주세요.
                                  </h2>
                                  <div className={Qst.titleExplain}>
                                    외국인의 정식 명칭을 로마자(영문)와 한글 발음으로 입력해 주세요.
                                  </div>
                                  <div className={Qst.titleExTextBox}>
                                    <TextBox
                                      title="로마자(영문)"
                                      className={Qst.nameTextBox}
                                      style={{ width: '174px', height: '40px', padding: '9px 20px' }}
                                    >
                                      <span className={Qst.text}>Jane Austen</span>
                                    </TextBox>
                                    <TextBox
                                      title="한글 발음"
                                      className={Qst.nameTextBox}
                                      style={{ width: '174px', height: '40px', padding: '9px 20px' }}
                                    >
                                      <span className={Qst.text}>제인 오스틴</span>
                                    </TextBox>
                                  </div>
                                  <div className={Qst.titleExplain} style={{ marginTop: '8px' }}>
                                    영문이 아닌 외국성함은, 로마자(영문)으로 변환하여 한글 발음으로 입력해 주세요.
                                  </div>
                                  <div className={Qst.titleExTextBox}>
                                    <TextBox
                                      title="로마자(영문)로 변환"
                                      titleWidth="145px"
                                      className={Qst.nameTextBox}
                                      style={{ width: '171px', height: '40px', padding: '9px 20px' }}
                                    >
                                      <span className={Qst.text}>高竹</span>
                                      <Icon icon="arrow" className={Qst.arrow} />
                                      <span className={Qst.text}>Takamura</span>
                                    </TextBox>
                                    <TextBox
                                      title="한글 발음"
                                      className={Qst.nameTextBox}
                                      style={{ width: '134px', height: '40px', padding: '9px 20px' }}
                                    >
                                      <span className={Qst.text}>타카무라</span>
                                    </TextBox>
                                  </div>
                                </div>
                                <div className={Qst.rowWrap}>
                                  <InputText
                                    className={Qst.inputText}
                                    style={{ width: '276px' }}
                                    explain="로마자(영문) 표기"
                                    name={`executiveMembers[${idx}].englishName`}
                                    value={item.englishName}
                                    onChange={(e) => ExecutiveVm.setEnglishName(e, idx)}
                                    errorText={
                                      <Error
                                        name={`executiveMembers[${idx}].englishName`}
                                        value={item.englishName}
                                        errorCase={{
                                          required: '이름을 로마자로 입력해 주세요.',
                                          pattern: {
                                            value: regExp.onlyEnglish().test(item.englishName),
                                            message: '로마자로 입력해 주세요.',
                                          },
                                        }}
                                      />
                                    }
                                  />
                                  <InputText
                                    className={Qst.inputText}
                                    style={{ width: '276px' }}
                                    explain="한글 발음 표기"
                                    name={`executiveMembers[${idx}].koreanName`}
                                    value={item.koreanName}
                                    onChange={(e) => ExecutiveVm.setKoreanName(e, idx)}
                                    errorText={
                                      <Error
                                        name={`executiveMembers[${idx}].koreanName`}
                                        value={item.koreanName}
                                        errorCase={{
                                          required: '이름을 한글로 입력해 주세요.',
                                          pattern: {
                                            value: regExp.onlyKorean().test(item.koreanName),
                                            message: '한글로 입력해 주세요.',
                                          },
                                        }}
                                      />
                                    }
                                  />
                                </div>
                              </div>
                              <div className={Qst.qnaSection}>
                                <Qna className={Qst.noTitle}>
                                  <QText>어떤 문서에 나온 이름을 입력해야 하나요?</QText>
                                  <div className="answerBox">
                                    <Answer className={Qst.answer}>
                                      한국에서 거소등록(외국인등록)을 한 외국인이라면, 거소등록증(외국인등록증) 또는
                                      인감증명서에 기재된 성함으로 입력해 주세요.
                                    </Answer>
                                  </div>
                                </Qna>
                              </div>
                            </div>
                            <div className={Qst.spaceBetween}>
                              <div className={Qst.infoSection}>
                                <div className={Qst.titleWrap}>
                                  <h2 className={clsx(Qst.title, Qst.inside)}>
                                    <span className={Qst.bold}>
                                      {item.koreanName ? item.koreanName + '님' : ExecutiveVm.titleName}의 여권에 기재된
                                      생년월일을 선택
                                    </span>
                                    해 주세요.
                                  </h2>
                                </div>
                                <div>
                                  <div className={Qst.birthWrap}>
                                    <InputText
                                      style={{ width: '130px' }}
                                      placeholder="0000"
                                      name={`executiveMembers[${idx}].year`}
                                      value={item.year}
                                      onChange={(e) => ExecutiveVm.setYear(e, idx)}
                                      maxLength="4"
                                    />
                                    <span className={Qst.birthText}>년</span>
                                  </div>
                                  <div className={Qst.birthWrap}>
                                    <InputText
                                      style={{ width: '108px' }}
                                      placeholder="00"
                                      name={`executiveMembers[${idx}].month`}
                                      value={item.month}
                                      onChange={(e) => ExecutiveVm.setMonth(e, idx)}
                                      maxLength="2"
                                      onBlur={(e) => ExecutiveVm.monthBlur(e, idx)}
                                    />
                                    <span className={Qst.birthText}>월</span>
                                  </div>
                                  <div className={Qst.birthWrap}>
                                    <InputText
                                      style={{ width: '108px' }}
                                      placeholder="00"
                                      name={`executiveMembers[${idx}].day`}
                                      value={item.day}
                                      onChange={(e) => ExecutiveVm.setDay(e, idx)}
                                      maxLength="2"
                                      onBlur={(e) => ExecutiveVm.dayBlur(e, idx)}
                                    />
                                    <span className={Qst.birthText}>일</span>
                                  </div>
                                </div>
                                <div style={{ marginTop: '-27px' }}>
                                  <Error
                                    name={`executiveMembers[${idx}].year`}
                                    value={item.year}
                                    errorCase={{
                                      required: '연도를 입력해 주세요.',
                                      pattern: {
                                        value: regExp.year().test(item.year),
                                        message: '올바른 연도를 입력해 주세요',
                                      },
                                    }}
                                  />
                                  <Error
                                    name={`executiveMembers[${idx}].month`}
                                    value={item.month}
                                    errorCase={{
                                      required: ExecutiveVm.displayBirthErrorMessage(idx, 'month')
                                        ? '월을 입력해 주세요.'
                                        : null,
                                      pattern: {
                                        value: ExecutiveVm.displayBirthErrorMessage(idx, 'month')
                                          ? regExp.month().test(item.month)
                                          : null,
                                        message: '올바른 월을 입력해 주세요',
                                      },
                                    }}
                                  />
                                  <Error
                                    name={`executiveMembers[${idx}].day`}
                                    value={item.day}
                                    errorCase={{
                                      required: ExecutiveVm.displayBirthErrorMessage(idx, 'day')
                                        ? '일(날짜)을 입력해 주세요.'
                                        : null,
                                      pattern: {
                                        value: ExecutiveVm.displayBirthErrorMessage(idx, 'day')
                                          ? regExp.day().test(item.day)
                                          : null,
                                        message: '올바른 일(날짜)을 입력해 주세요.',
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                    {ExecutiveVm.positionDisplayYn(idx) && (
                      <ExecutivePosition
                        name={`executiveMembers[${idx}].position`}
                        idx={idx}
                        position={item.position}
                      ></ExecutivePosition>
                    )}
                    {ExecutiveVm.isFarmerOrFishermanDisplayYn(idx) && (
                      <div className={Qst.spaceBetween}>
                        <div className={Qst.infoSection}>
                          <div className={Qst.titleWrap}>
                            <h2 className={clsx(Qst.title, Qst.inside)}>
                              {item.koreanName ? item.koreanName + '님' : ExecutiveVm.titleName}은{' '}
                              <span className={Qst.bold}>{ExecutiveVm.text.person}</span>인가요?
                            </h2>
                          </div>
                          <ul className={Qst.answerList}>
                            <li>
                              <Radio
                                className={Qst.answerLabel}
                                value={true}
                                activeBackground
                                checked={item.isFarmerOrFisherman === true}
                                onChange={(e) => ExecutiveVm.setIsFarmerOrFisherman(e, idx)}
                                name={`executiveMembers[${idx}].isFarmerOrFisherman`}
                              >
                                네 {ExecutiveVm.text.person}입니다.
                              </Radio>
                            </li>
                            <li>
                              <Radio
                                className={Qst.answerLabel}
                                value={false}
                                activeBackground
                                checked={item.isFarmerOrFisherman === false}
                                onChange={(e) => ExecutiveVm.setIsFarmerOrFisherman(e, idx)}
                                name={`executiveMembers[${idx}].isFarmerOrFisherman`}
                              >
                                아니오. {ExecutiveVm.text.person}이 아닙니다.
                              </Radio>
                            </li>
                          </ul>
                          <Error
                            name={`executiveMembers[${idx}].isFarmerOrFisherman`}
                            value={item.isFarmerOrFisherman}
                            errorCase={{ required: `${ExecutiveVm.text.person}여부를 선택해 주세요.` }}
                          />
                        </div>
                      </div>
                    )}
                    {ExecutiveVm.addressDisplayYn(idx) && (
                      <div className={Qst.spaceBetween} style={{ paddingBottom: '30px' }}>
                        <div className={Qst.infoSection}>
                          <div className={Qst.titleWrap}>
                            {item.koreanYn === false && (
                              <>
                                <h2 className={clsx(Qst.title, Qst.inside)}>
                                  {item.koreanName ? item.koreanName + '님' : ExecutiveVm.titleName}의{' '}
                                  <span className={Qst.bold}>거소신고(외국인등록) 사실증명상 도로명주소</span>를 입력해
                                  주세요.
                                </h2>
                                <div className={clsx(Qst.titleExplain, Qst.noneMark)} style={{ color: '#fd6200' }}>
                                  반드시 국내 거소신고(외국인등록) 사실증명과 정확히 일치하는 주소를 입력해주세요.
                                </div>
                                <div className={clsx(Qst.titleExplain, Qst.noneMark)} style={{ color: '#fd6200' }}>
                                  거소신고(외국인등록) 사실증명상 주소와 일치하지 않는 경우, 법인설립이 거절됩니다.
                                  (5~7일 더 걸림)
                                </div>
                              </>
                            )}
                            {item.koreanYn === true && (
                              <>
                                <h2 className={clsx(Qst.title, Qst.inside)}>
                                  <span className={Qst.bold}>
                                    {item.koreanName ? item.koreanName + '님' : ExecutiveVm.titleName}의 도로명 주소를
                                    입력
                                  </span>
                                  해 주세요.
                                </h2>
                              </>
                            )}
                          </div>
                          {item.koreanYn === true && (
                            <Badge className={Qst.addressBadge} title="예시" type="example" border={false}>
                              <img
                                className={Qst.addressBadgeImg}
                                src="/images/person_address_example.png"
                                alt="주민등록상 도로명주소 예시"
                              />
                            </Badge>
                          )}
                          <div className={Qst.rowWrap}>
                            <InputText
                              className={Qst.inputText}
                              style={{ width: '380px' }}
                              explain="도로명 주소"
                              value={item?.address?.address || ''}
                              readOnly
                              onClick={() => ExecutiveVm.openAddressModal(true, idx)}
                              name={`executiveMembers[${idx}].address.address`}
                              errorText={
                                <Error
                                  name={`executiveMembers[${idx}].address.address`}
                                  value={item?.address?.address}
                                  errorCase={{ required: '도로명 주소를 입력해 주세요.' }}
                                />
                              }
                            />
                            <SquareBtn onClick={() => ExecutiveVm.openAddressModal(true, idx)}>
                              도로명 주소 찾기
                            </SquareBtn>
                          </div>
                          <div className={Qst.rowWrap}>
                            <InputText
                              className={Qst.inputText}
                              style={{ width: '273px' }}
                              explain="상세 주소"
                              placeholder="상세주소 없을 시, [없음] 입력"
                              value={item.detailAddress}
                              onChange={(e) => ExecutiveVm.koreanAddress_detailAddress(e, idx)}
                              name={`executiveMembers[${idx}].detailAddress`}
                              errorText={
                                <Error
                                  name={`executiveMembers[${idx}].detailAddress`}
                                  value={item.detailAddress}
                                  errorCase={{
                                    required: '상세 주소를 입력해 주세요.',
                                    pattern: {
                                      value: regExp.onlyKorNum_hyphen_comma().test(item.detailAddress),
                                      message: (
                                        <span>
                                          임대차 계약서상 영문이 있다면, <br />
                                          한글로 옮겨적어주세요.
                                          <br />
                                          EX) B동 &gt; 비동
                                        </span>
                                      ),
                                    },
                                  }}
                                />
                              }
                            />
                            <div className={Qst.symbol}>
                              <span className={Qst.symbolIcon}>&#40;&nbsp;</span>
                              <span
                                className={clsx(
                                  Qst.symbolText,
                                  ExecutiveVm.koreanAddress_bnameFontSizeChange(idx) ? Qst.fontSize : null,
                                )}
                              >
                                {item?.address?.bname || '법정동'},&nbsp;
                              </span>
                              <InputText
                                style={{ width: '200px' }}
                                explain="건물명"
                                placeholder="없을 시, [없음] 입력"
                                value={item.nameOfBuilding}
                                onChange={(e) => ExecutiveVm.koreanAddress_nameOfBuilding(e, idx)}
                                name={`executiveMembers[${idx}].nameOfBuilding`}
                                errorText={
                                  <Error
                                    name={`executiveMembers[${idx}].nameOfBuilding`}
                                    value={item.nameOfBuilding}
                                    errorCase={{
                                      required: '건물명을 입력해 주세요.',
                                      pattern: {
                                        value: regExp.onlyKorNum_hyphen_comma().test(item.nameOfBuilding),
                                        message: (
                                          <span>
                                            건물명이 영문인 경우, 한글
                                            <br />
                                            발음으로 옮겨적어 주세요. <br />
                                            EX) AB타워 &gt; 에이비 타워
                                          </span>
                                        ),
                                      },
                                    }}
                                  />
                                }
                              />
                              <span className={Qst.symbolIcon}>&nbsp;&#41;</span>
                            </div>
                          </div>
                        </div>
                        <div className={Qst.qnaSection}>
                          <Qna className={Qst.noTitle}>
                            {item.koreanYn === true && (
                              <div className={Qst.qnaBox}>
                                <QText>주민등록상 주소는 어디서 확인하나요?</QText>
                                <div className="answerBox">
                                  <Answer className={Qst.answer}>정부24 홈페이지에서 무료로 확인가능합니다.</Answer>
                                  <p className={Qst.explain}>
                                    정부24 홈페이지에 로그인하기 위해서는 금융인증서, 공동인증서(구 공인인증서),
                                    간편인증서 등이 필요합니다.
                                  </p>
                                  <LikeBtn className={Qst.likeBtn}>정부24 홈페이지 바로가기</LikeBtn>
                                </div>
                              </div>
                            )}
                            <div className={Qst.qnaBox}>
                              <QText>주소에는 영어를 쓸 수 있나요?</QText>
                              <div className="answerBox">
                                <Answer className={Qst.answer}>
                                  등기소에서는 주소를 적을 때, 한글 숫자만 허용합니다. (영어X)
                                </Answer>
                                <p className={Qst.explain}>주소에 영어가 들어가 있다면, 한글로 변환해 주세요.</p>
                                <TextBox className={clsx(Qst.textBox, Qst.addressTextBox)} title="예시">
                                  <div className={Qst.textWrap}>
                                    <span className={Qst.false}>B동, 202-1001</span>
                                    <Icon icon="arrow" className={Qst.iconArrow} />
                                    <span className={Qst.true}>비동, 202동 1001호</span>
                                  </div>
                                </TextBox>
                              </div>
                            </div>
                          </Qna>
                        </div>
                      </div>
                    )}
                    {ExecutiveVm.resignationDisplayYn(idx) && (
                      <div className={Qst.spaceBetween}>
                        <div className={Qst.infoSection}>
                          <div className={Qst.titleWrap}>
                            <h2 className={clsx(Qst.title, Qst.inside)}>
                              설립 후 이어서{' '}
                              <span className={Qst.bold}>
                                {item.koreanName ? item.koreanName + '님' : ExecutiveVm.titleName}의 사임등기
                              </span>
                              를 진행하시겠어요? (1인 법인 만들기)
                            </h2>
                            {ExecutiveVm.companySize === 'TWO' && (
                              <div className={st.resignationBox}>
                                {ExecutiveVm.initiatorRole === 'REPRESENTATIVE_AUDITOR' && (
                                  <img src="/images/resignationImg.png?1" className={st.resignationImg} />
                                )}
                                {ExecutiveVm.initiatorRole === 'REPRESENTATIVE_DIRECTOR' && (
                                  <>
                                    <img src="/images/resignationImg_director.png?1" className={st.resignationImg} />
                                    <div className={st.resignationExplainBox}>
                                      <div className={st.title}>신속한 절차</div>
                                      <div className={st.content}>
                                        설립 후 이어서 사임등기 진행시,{' '}
                                        {item.koreanName ? item.koreanName + '님' : ExecutiveVm.titleName}이{' '}
                                        <span className={st.bold}>감사</span>가 되는 것이{' '}
                                        <span className={st.bold}>절차상 신속</span>합니다.
                                        <div className={st.explain}>
                                          (현재는 {item.koreanName ? item.koreanName + '님' : ExecutiveVm.titleName}을
                                          '이사'로 선택한 상태)
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                          <ul className={Qst.answerList}>
                            <li>
                              <Radio
                                className={Qst.answerLabel}
                                value={true}
                                activeBackground
                                checked={item.resignationAfterEstablishmentYn === true}
                                onChange={(e) => ExecutiveVm.setResignationAfterEstablishmentYn(e, idx)}
                                name={`executiveMembers[${idx}].resignationAfterEstablishmentYn`}
                              >
                                {ExecutiveVm.initiatorRole === 'REPRESENTATIVE_AUDITOR' && (
                                  <>
                                    네. 설립 후 이어서{' '}
                                    {item.koreanName ? item.koreanName + '님' : ExecutiveVm.titleName}의 사임등기를
                                    진행하겠습니다.
                                    <br />
                                    (설립 비용 안내서에 비용 합산됨)
                                  </>
                                )}
                                {ExecutiveVm.initiatorRole === 'REPRESENTATIVE_DIRECTOR' && (
                                  <>
                                    네. 설립 후 사임등기를 진행합니다. +
                                    {item.koreanName ? item.koreanName + '님' : ExecutiveVm.titleName}을 감사로 변경
                                    <br />
                                    (합산 비용 안내서 제공)
                                  </>
                                )}
                              </Radio>
                            </li>
                            <li>
                              <Radio
                                className={Qst.answerLabel}
                                value={false}
                                activeBackground
                                checked={item.resignationAfterEstablishmentYn === false}
                                onChange={(e) => ExecutiveVm.setResignationAfterEstablishmentYn(e, idx)}
                                name={`executiveMembers[${idx}].resignationAfterEstablishmentYn`}
                              >
                                아니오. {item.koreanName ? item.koreanName + '님' : ExecutiveVm.titleName}의 사임등기를
                                진행하지 않겠습니다.
                                <br />
                                (3년 뒤 임원 중임 또는 퇴임등기 필요)
                              </Radio>
                            </li>
                          </ul>
                          <Error
                            name={`executiveMembers[${idx}].resignationAfterEstablishmentYn`}
                            value={item.resignationAfterEstablishmentYn}
                            errorCase={{ required: '사임 여부를 선택해 주세요.' }}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </Drop>
            </ContentBox>
          </Question>
        );
      })}
      {ExecutiveVm.addYn && (
        <Question list={true}>
          <ContentBox>
            <button type="button" className={Qst.stockholderAddBtn} onClick={() => ExecutiveVm.addExecutive()}>
              임원 추가하기
              <Icon icon="addGray" className={Qst.addGray} />
            </button>
          </ContentBox>
        </Question>
      )}
      {/* 도로명주소 팝업 */}
      {ExecutiveVm.addressModal && (
        <SearchAddress
          onClose={() => ExecutiveVm.openAddressModal(false, null)}
          onComplete={(data) => ExecutiveVm.koreanAddressComplate(data)}
        />
      )}
    </form>
  ));
}

export default Executive;
