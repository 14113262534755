import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import Ast from '../../AdminFinalCheckView.module.scss';
import st from './StandbyFreeIssueOfNewSharesStep.module.scss';
import vm from './StandbyFreeIssueOfNewSharesStepVm';
import clsx from 'clsx';
import { addCommas } from '@common/module/replaceNumber';
import copyText from '@common/module/copyText';
import CopyBtn from '@comComponents/atoms/Button/CopyBtn/CopyBtn';

function StandbyFreeIssueOfNewSharesStep(props) {
  return useObserver(() => (
    <>
      {props?.step?.keepGoingYn ? (
        <>
          {/* 비용 안내대로 진행하는 경우 */}
          <div className={clsx(Ast.row)}>
            <div className={Ast.dName}>무상증자 방식</div>
            <div className={Ast.data}>{props?.step?.freeIssueOfNewSharesType || '-'}</div>
          </div>
          <div className={clsx(Ast.row)}>
            <div className={Ast.dName}>무상증자주식 배정표</div>
            <div className={Ast.data}>
              <table className={st.table}>
                <colgroup>
                  <col />
                </colgroup>
                <thead>
                  <tr>
                    <th style={{ textAlign: 'left' }}>주주명</th>
                    <th>지분율(=배당률)</th>
                    <th>신주식 수</th>
                    <th style={{ textAlign: 'right' }}>무상증자할 주식수</th>
                  </tr>
                </thead>
                <tbody>
                  {props?.step?.freeIssueOfNewSharesAllotmentInfos.map((item, idx) => (
                    <tr key={idx}>
                      <td style={{ textAlign: 'left' }}>
                        <CopyBtn onClick={() => copyText(item.name)}>{item.name}</CopyBtn>
                      </td>
                      <td>
                        <CopyBtn onClick={() => copyText(item.shareRatio)}>{item.shareRatio}</CopyBtn> %
                      </td>
                      {idx === 0 && (
                        <td rowSpan={props.step.freeIssueOfNewSharesAllotmentInfos.length}>
                          <CopyBtn
                            onClick={() => copyText(addCommas(vm.sum(props?.step?.freeIssueOfNewSharesAllotmentInfos)))}
                          >
                            {addCommas(vm.sum(props?.step?.freeIssueOfNewSharesAllotmentInfos))}
                          </CopyBtn>
                        </td>
                      )}
                      <td style={{ textAlign: 'right' }}>
                        <CopyBtn onClick={() => copyText(addCommas(item.newStockAmount))}>
                          {addCommas(item.newStockAmount)}
                        </CopyBtn>{' '}
                        주
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className={clsx(Ast.row)}>
            <div className={Ast.dName}>등기부상 자본금 변동</div>
            <div className={Ast.data}>
              <ul className={st.changeCapital}>
                <li>
                  <div>기존</div>
                  <div>
                    {props?.step?.amount ? (
                      <>
                        <CopyBtn onClick={() => copyText(addCommas(props?.step?.amount))}>
                          {addCommas(props?.step?.amount)}
                        </CopyBtn>{' '}
                        원
                      </>
                    ) : (
                      '-'
                    )}
                  </div>
                </li>
                <li>
                  <div>무상증자</div>
                  <div>
                    {props?.step?.parValue ? (
                      <>
                        <CopyBtn onClick={() => copyText(addCommas(props?.step?.parValue))}>
                          {addCommas(props?.step?.parValue)}
                        </CopyBtn>{' '}
                        원
                      </>
                    ) : (
                      '-'
                    )}
                  </div>
                </li>
                <li>
                  <div>신규</div>
                  <div>
                    {props?.step?.amount && props.step?.parValue ? (
                      <>
                        <CopyBtn onClick={() => copyText(addCommas(props?.step?.amount + props?.step?.parValue))}>
                          {addCommas(props?.step?.amount + props?.step?.parValue)}
                        </CopyBtn>{' '}
                        원
                      </>
                    ) : (
                      '-'
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </>
      ) : (
        <>
          {/* 비용 안내대로 진행하지 않는 경우 */}
          <div className={clsx(Ast.row)}>
            <div className={Ast.dName}>무상증자의 재원</div>
            <div className={Ast.data}>{props?.step?.resourceType || '-'}</div>
          </div>
          <div className={clsx(Ast.row)}>
            <div className={Ast.dName}>무상증자할 금액</div>
            <div className={Ast.data}>
              {props?.step?.amount ? (
                <>
                  <CopyBtn onClick={() => copyText(addCommas(props?.step?.amount))}>
                    {addCommas(props?.step?.amount)}
                  </CopyBtn>{' '}
                  원
                </>
              ) : (
                '-'
              )}
            </div>
          </div>
          <div className={clsx(Ast.row)}>
            <div className={Ast.dName}>발행할 주식수</div>
            <div className={Ast.data}>
              {props?.step?.number ? (
                <>
                  <CopyBtn onClick={() => copyText(addCommas(props?.step?.number))}>
                    {addCommas(props?.step?.number)}
                  </CopyBtn>{' '}
                  주
                </>
              ) : (
                '-'
              )}
            </div>
          </div>
          <div className={clsx(Ast.row)}>
            <div className={Ast.dName}>발행가액(자동계산)</div>
            <div className={Ast.data}>
              {props?.step?.number ? (
                <>
                  액면가{' '}
                  <CopyBtn onClick={() => copyText(addCommas(props?.step?.amount / props?.step?.number))}>
                    {addCommas(props?.step?.amount / props?.step?.number)}
                  </CopyBtn>{' '}
                  원
                </>
              ) : (
                '-'
              )}
            </div>
          </div>
          <div className={clsx(Ast.row)}>
            <div className={Ast.dName}>배정기준일</div>
            <div className={Ast.data}>
              {props?.step?.recordDateForNewSharesAllotment ? (
                <>
                  <CopyBtn onClick={() => copyText(props?.step?.recordDateForNewSharesAllotment)}>
                    {props?.step?.recordDateForNewSharesAllotment}
                  </CopyBtn>{' '}
                  (스탠바이에 입력한 결의일)
                </>
              ) : (
                '-'
              )}
            </div>
          </div>
        </>
      )}
      <div className={clsx(Ast.row)}>
        <div className={Ast.dName}>
          스탠바이 입력내용
          <br />
          일치여부
        </div>
        <div className={Ast.data}>{props?.step?.keepGoingYn ? '네' : '아니오'}</div>
      </div>
      <table className={st.freeIssueTable}>
        <thead>
          <tr>
            <th>주주명</th>
            <th>신주식 수</th>
            <th>무상증자할 주식수</th>
          </tr>
        </thead>
        <tbody>
          {props.step?.freeIssueOfNewSharesAllotmentInfos?.map((item, idx) => (
            <tr key={idx}>
              <td>
                <CopyBtn onClick={() => copyText(item.name)}>{item.name}</CopyBtn>
              </td>
              {idx === 0 && (
                <td rowSpan={props.step?.freeIssueOfNewSharesAllotmentInfos.length}>
                  <CopyBtn onClick={() => copyText(addCommas(vm.sum(props.step.freeIssueOfNewSharesAllotmentInfos)))}>
                    {addCommas(vm.sum(props.step.freeIssueOfNewSharesAllotmentInfos))}
                  </CopyBtn>
                </td>
              )}
              <td>
                <CopyBtn onClick={() => copyText(addCommas(item.newStockAmount))}>
                  {addCommas(item.newStockAmount)}
                </CopyBtn>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            <td></td>
            <td>
              총{' '}
              <CopyBtn onClick={() => copyText(addCommas(vm.sum(props.step.freeIssueOfNewSharesAllotmentInfos)))}>
                {addCommas(vm.sum(props.step.freeIssueOfNewSharesAllotmentInfos))}
              </CopyBtn>{' '}
              주
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  ));
}
export default StandbyFreeIssueOfNewSharesStep;
