import st from './AiChat.module.scss';
import useAiChat from './hook/useAiChat';

const AiChat = ({ showTitle = true }) => {
  const { keyword, handleInputChange, search, onKeyDown } = useAiChat();

  return (
    <div className={st.container}>
      {showTitle && <h2 className={st.title}>실시간 TIP 검색</h2>}
      <div className={st.explain}>헬프미의 방대한 등기 정보를 실시간으로 검색해보세요!</div>
      <div className={st.searchWrapper}>
        <input
          className={st.input}
          type="text"
          placeholder="검색어를 입력해주세요."
          value={keyword}
          onChange={handleInputChange}
          onKeyDown={onKeyDown}
        />
        <button className={st.button} type="button" onClick={search}>
          검색하기
        </button>
      </div>
    </div>
  );
};

export default AiChat;
