import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import TrademarkVm from './TrademarkVm';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './Trademark.module.scss';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import clsx from 'clsx';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import AiChat from '@pages/Establish/AiChat/AiChat';

function Trademark() {
  return useObserver(() => (
    <form
      onSubmit={(e) => {
        TrademarkVm.submit(e);
      }}
    >
      <input type="submit" value="submit" id="stepForm" style={{ display: 'none' }} />
      <Question style={{ padding: '0', display: 'flex', justifyContent: 'end' }}>
        <img src="/images/trademark_banner_establish.png?1" />
      </Question>
      <Question>
        <ContentBox className={Qst.spaceBetween}>
          <div className={Qst.infoSection}>
            <div className={Qst.titleWrap}>
              <h2 className={Qst.title}>상표등록 헬프미 안내문을 받아보시겠어요?</h2>
              <div className={Qst.titleExplain}>샘플 비용 안내서 안내문을 보내드립니다.</div>
            </div>
            <ul className={Qst.answerList}>
              <li>
                <Radio
                  activeBackground
                  className={Qst.answerLabel}
                  value={true}
                  checked={TrademarkVm.trademarkAgreeYn === true}
                  onChange={TrademarkVm.setTrademarkAgreeYn}
                  name="agreeYn"
                >
                  헬프미에서 상표등록 안내문을 받아보겠습니다.
                </Radio>
              </li>
              <li>
                <Radio
                  activeBackground
                  className={Qst.answerLabel}
                  value={false}
                  checked={TrademarkVm.trademarkAgreeYn === false}
                  onChange={TrademarkVm.setTrademarkAgreeYn}
                  name="agreeYn"
                >
                  헬프미 상표등록 안내문을 받지 않겠습니다.
                </Radio>
              </li>
            </ul>
            <Error
              name="agreeYn"
              value={TrademarkVm.trademarkAgreeYn}
              errorCase={{ required: '한글 회사이름 변경 여부를 선택해주세요.' }}
            />
            <a
              className={st.advertisementLink}
              href="https://trademark.help-me.kr"
              target="_blank"
              title="상표등록 헬프미로 이동"
            >
              <img src="/images/trademark_advertisement.png" />
            </a>
          </div>
          <div className={Qst.qnaSection}>
            <AiChat />
            <Qna tip="상표등록" className={Qst.qnaTitle}>
              <div className={Qst.qnaBox}>
                <QText>상표등록하면 어떤 이점이 있나요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>
                    등록된 상표는 다른 사람이 사용하지 못하도록 독점적 배타적 권리를 가질 수 있습니다.
                  </Answer>
                  <p className={Qst.explain}>
                    독점적 권리란 오직 상표권자만 상표를 이용할 수 있다는 의미이고, 배타적 권리란 상표권자 이외의 사람이
                    상표를 이용하지 못하도록 막을 수 있다는 의미입니다. 쉽게 이해하면 상표를 아무나 쓸 수 없게 하고,
                    오직 나만 쓸 수 있게 한다는 것이죠.
                  </p>
                </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>상표등록을 빨리 해야 하나요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>상표등록은 선착순입니다. 먼저 한 사람이 권리를 확보합니다.</Answer>
                  <p className={Qst.explain}>
                    상표는 먼저 출원한 사람이 권리를 가집니다. 출원이란 특허청에 상표등록을 신청하는 것을 말합니다. 같은
                    상표를 출원하는 경우, 먼저 출원한 사람이 등록할 권리를 가지는게 원칙입니다. 그래서 최대한 빨리 내
                    상표를 등록해서 아무나 쓰지 못하도록 만들어야 합니다.
                  </p>
                </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>상표등록은 쉽게 할 수 있나요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>등록할 상표만 있다면 누구나 쉽게 할 수 있습니다.</Answer>
                  <p className={Qst.explain}>
                    등록할 상표만 준비되어 있다면 누구나 쉽게 상표등록을 할 수 있습니다. 특허청에 서류를 갖춰 출원신청을
                    하면 됩니다.
                  </p>
                </div>
              </div>
            </Qna>
          </div>
        </ContentBox>
      </Question>
    </form>
  ));
}
export default Trademark;
