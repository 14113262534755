import { toJS } from 'mobx';
import { Fragment } from 'react';
import { useObserver } from 'mobx-react';
import clsx from 'clsx';
import st from '../FileUploadStep.module.scss';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import TextBox from '@comComponents/atoms/Text/TextBox/TextBox';
import Badge from '@comComponents/atoms/BadgeBox/BadgeBox';
import LikeBtn from '@comComponents/atoms/HyperLink/LikeBtn/LikeBtn';
import Ul from '@comComponents/atoms/Text/UnorderListText/UnorderList';
import Upload from '@comComponents/molecules/FileUpload/FileUpload';
import ErrorText from '@comComponents/atoms/Text/ErrorText/ErrorText';

function BalanceCertificate(props) {
  return useObserver(() => (
    <Question>
      <ContentBox className={Qst.spaceBetween}>
        <div className={Qst.infoSection}>
          {props.item.details.map((detail, idx2) => (
            <Fragment key={idx2}>
              {(detail.fileType === '잔고증명서' || detail.fileType === '잔고증명서_전환사채') && (
                <>
                  <div className={Qst.titleWrap}>
                    <h2 className={Qst.title}>
                      <span className={Qst.bold}>잔고증명서</span>를 업로드해 주세요.
                    </h2>
                  </div>
                  <Badge className={st.balanceBadge} title="예시" type="example" border={false}>
                    <img
                      className={st.balanceBadgeImg}
                      src="/images/certificate_example_changeReg.png"
                      alt="잔고 증명서 예시"
                    />
                    <div className={st.balanceBadgeText}>
                      <ul>
                        <li>
                          잔고증명일자로 부터 <span className={st.bold}>7일이 지나지 않은 잔고증명서 파일</span>을
                          업로드해 주세요.
                        </li>
                        <li>
                          기간이 지난 경우 <span className={st.bold}>월 10만 원의 과태료</span>가 부가됩니다.
                        </li>
                        <li>
                          발급일자와 증명일자가 <span className={st.bold}>동일한 날짜</span>인 경우에는{' '}
                          <span className={st.bold}>입출금 거래가 불가능</span>합니다.
                        </li>
                      </ul>
                    </div>
                  </Badge>
                  <Upload
                    className={clsx(st.fileUpload, `${detail.fileType}`)}
                    multiple
                    accept=".pdf, .jpg, .png"
                    model={props.parentVm.fileGroupTypes[props.idx].details[idx2].files}
                    uploadModel={props.parentVm.fileGroupTypes[props.idx].details[idx2].uploadFiles}
                    deleteModel={props.parentVm.deleteIds}
                    files={toJS(props.parentVm.files(props.idx, idx2))}
                    uploadFiles={toJS(props.parentVm.uploadFiles(props.idx, idx2))}
                    explainText={
                      <div>
                        잔고증명일자가 <span className="bold">7일 이내</span>의 것인지 확인해 주세요.
                      </div>
                    }
                  >
                    <span className={st.bold}>여기</span>를 눌러 업로드해 주세요.
                  </Upload>
                  {props.parentVm.state.errorCheckYn && props.parentVm.displayErrorText(detail.fileType) && (
                    <ErrorText>잔고증명서를 업로드해 주세요.</ErrorText>
                  )}
                </>
              )}
            </Fragment>
          ))}
        </div>
        <div className={Qst.qnaSection}>
          <Qna className={Qst.noTitle}>
            <div className={Qst.qnaBox}>
              <QText>잔고증명서(잔액증명서)는 어떻게 발급받나요?</QText>
              <div className="answerBox">
                <Answer className={Qst.answer}>
                  잔고증명서(잔액증명서)는 인터넷뱅킹 또는 은행방문을 통해서 발급받을 수 있습니다.
                </Answer>
                <div className={Qst.explain}>
                  <Ul>
                    <li>잔고증명서(잔액증명서)는 일정 일자에 예금액이 얼마 있었다는 사실을 증명하는 서류입니다.</li>
                    <li>인터넷뱅킹 또는 은행방문을 통해 손쉽게 발급 받을 수 있습니다.</li>
                  </Ul>
                </div>
                <LikeBtn
                  className={Qst.likeBtn}
                  href="https://www.help-me.kr/guide/detail/%EC%9E%94%EA%B3%A0%EC%A6%9D%EB%AA%85%EC%84%9C-%EB%B0%9C%EA%B8%89-%EB%B0%A9%EB%B2%95"
                  target="_blank"
                >
                  잔고증명서 발급 방법 자세히 보기
                </LikeBtn>
              </div>
            </div>
            <div className={Qst.qnaBox}>
              <QText>잔고증명일자로부터 7일 내에 업로드해야 하는 이유가 무엇인가요?</QText>
              <div className="answerBox">
                <Answer className={Qst.answer}>
                  과태료 발생을 예방하기 위해 잔고증명일자로부터 7일 내에 업로드해주세요.
                </Answer>
                <div className={Qst.explain}>
                  <Ul>
                    <li>
                      원칙상 잔고증명일자로부터 14일 내에 신주발행 등기 접수를 해야 하고, 14일이 초과된 후에는 과태료가
                      발생합니다.
                    </li>
                    <li>
                      과태료 발생을 넉넉하게 예방하기 위해서 헬프미에서는 잔고증명일자로부터 7일 내에 업로드할 것을
                      요청드리고 있습니다.
                    </li>
                  </Ul>
                </div>
              </div>
            </div>
            <div className={Qst.qnaBox}>
              <QText>예금잔액은 얼마나 있어야 하나요?</QText>
              <div className="answerBox">
                <Answer className={Qst.answer}>예금잔액은 신주인수대금(투자금) 이상만 있으면 됩니다.</Answer>
                <div className={Qst.explain}>
                  <Ul>
                    <li>예금잔액은 신주인수대금(투자금)과 반드시 일치할 필요는 없고, 그 금액보다 많으면 됩니다.</li>
                    <li>
                      예금잔액이 기존 자본금에 신주인수대금이 합산된 금액만큼 있어야 하는 것은 아니고,
                      신주인수대금만큼만 있으면 됩니다.
                    </li>
                  </Ul>
                </div>
              </div>
            </div>
            <div className={Qst.qnaBox}>
              <QText>신주인수대금(투자금)을 벌써 써버렸는데, 잔고증명서를 어떻게 발급받나요?</QText>
              <div className="answerBox">
                <Answer className={Qst.answer}>
                  신주인수대금(투자금)을 이미 사용했다면, 잔고가 충분한 과거일자를 잔고증명일자로 정해서 잔고증명서를
                  발급받으면 됩니다.
                </Answer>
                <div className={Qst.explain}>
                  다만 잔고가 충분한 과거일자로부터 이미 2주가 지난 상태라면, 과태료가 발생할 수 있습니다. 과태료는 월
                  5~20만 원 정도 부과되므로, 이 경우 최대한 서둘러서 신주발행등기를 마치는 편이 좋습니다.
                </div>
              </div>
            </div>
            <div className={Qst.qnaBox}>
              <QText>꼭 자유입출금 계좌에서 발급받아야 하나요?</QText>
              <div className="answerBox">
                <Answer className={Qst.answer}>네 꼭 자유입출금 계좌에서 잔고증명서를 발급받으셔야 합니다.</Answer>
                <div className={Qst.explain}>
                  등기소에서는 자유입출금 계좌에서 받은 잔고증명서만 인정하므로 주의를 해주세요.
                </div>
                <TextBox className={Qst.textBox} title="불가능" warn={true}>
                  <div className={clsx(Qst.textWrap, st.textBox)}>
                    증권사 계좌, CMA 통장, 마이너스 통장, 적금/청약계좌, MMDA, FNA, 증권거래 계좌, AMA, MMF 계좌, 외환
                    계좌
                  </div>
                </TextBox>
              </div>
            </div>
            <div className={Qst.qnaBox}>
              <QText>잔고증명서를 발급받으면 불이익은 없나요?</QText>
              <div className="answerBox">
                <Answer className={Qst.answer}>
                  발급일자와 잔고증명일자가 동일하면 당일에 한해 입출금 거래가 불가능합니다.
                </Answer>
                <div className={Qst.explain}>
                  발급일자와 잔고증명일자가 동일하면 잔고증명을 발급 받은 날은 당일에 한해 입금과 출금이 모두 되지
                  않습니다. 발급일자보다 잔고증명일자가 빠른 경우, 이러한 제한이 없습니다.
                </div>
              </div>
            </div>
            <div className={Qst.qnaBox}>
              <QText>가수금증자시에도 잔고증명서가 필요한가요?</QText>
              <div className="answerBox">
                <Answer className={Qst.answer}>
                  가수금 증자는 가수금 계정별원장이 필요하고, 잔고증명서는 필요하지 않습니다.
                </Answer>
                <div className={Qst.explain}>
                  <Ul>
                    <li>세무사에게 ‘가수금 계정별원장’을 요청하여 업로드 해주세요.</li>
                    <li>가수금 계정별원장에는 담당 세무사의 명판을 날인해주세요.</li>
                    <li>전기이월된 내역이 있을 경우 지난 사업 연도의 계정별원장도 준비해주세요.</li>
                  </Ul>
                </div>
              </div>
            </div>
          </Qna>
        </div>
      </ContentBox>
    </Question>
  ));
}

export default BalanceCertificate;
