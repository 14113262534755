import NextBtn from '@comComponents/atoms/Button/LastStepBtn/LastStepBtn';
import RoundBtn from '@comComponents/atoms/Button/RoundBtn/RoundBtn';
import Loading from '@comComponents/atoms/Loading/Loading';
import { addCommas, percent } from '@common/module/replaceNumber';
import scrollToAnimate from '@common/module/scrollToAnimate';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import FocusBox from '@commonComponents/FocusBox/FocusBox';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import SignleQuestionHeader from '@commonComponents/SingleQuestionHeader/SingleQuestionHeader';
import { _alert } from '@model/dialogModel';
import clsx from 'clsx';
import { useObserver } from 'mobx-react';
import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import st from './BeforeFinalCheck.module.scss';
import BeforeFinalCheckVm from './BeforeFinalCheckVm';
import { purposeType } from '@pages/Establish/EtcInfo/BusinessPurpose/constant';

function RowContent(props) {
  useEffect(() => {
    if (props.checkPage === BeforeFinalCheckVm.state.checkPage && BeforeFinalCheckVm.state.checkPage !== 'start') {
      scrollToAnimate(
        window.pageYOffset,
        window.pageYOffset + moveScroll.current.getBoundingClientRect().top - 180,
        700,
      );
    }
  }, [BeforeFinalCheckVm.state.checkPage]);

  const moveScroll = useRef(null);

  let className = st.start;

  if (props.checkPage !== 'start') {
    className = st.focus;
  }

  return useObserver(() => (
    <div className={props.checkPage === BeforeFinalCheckVm.state.checkPage ? className : null} ref={moveScroll}>
      {props.children}
    </div>
  ));
}

function BeforeFinalCheck() {
  useEffect(() => {
    if (BeforeFinalCheckVm.finalPayment === 0) {
      _alert('비용 안내생성에 실패했습니다. 새로고침을 해 주세요.');
    }
  });

  return useObserver(() => (
    <>
      <SignleQuestionHeader>
        <h1 className="title">최종제출 전 내용 확인</h1>
        <div className="explain">최종제출 후 수정이 불가능 합니다. 내용을 꼼꼼히 확인해 주세요.</div>
      </SignleQuestionHeader>
      <div className="mainContent">
        <Question>
          {BeforeFinalCheckVm.isResponse && (
            <RowContent checkPage="start">
              <FocusBox
                page={BeforeFinalCheckVm.page('start')}
                checkPage={BeforeFinalCheckVm.state.checkPage}
                btnYes={() => BeforeFinalCheckVm.btnYes()}
                btnEdit={() => BeforeFinalCheckVm.btnResetFinalSubmissionModel()}
                btnSubmit={() => BeforeFinalCheckVm.submit()}
                disabled={BeforeFinalCheckVm.state.submitBtnDisabledYn}
              />
            </RowContent>
          )}
          <ContentBox style={BeforeFinalCheckVm.isResponse ? null : { height: window.innerHeight }}>
            {BeforeFinalCheckVm.isResponse === false ? (
              <Loading />
            ) : (
              <div className={st.content}>
                <div className={st.table}>
                  <div className={st.title}>회사정보</div>
                  <div className={st.info}>
                    <RowContent checkPage="companySize">
                      <FocusBox
                        page={BeforeFinalCheckVm.page('companySize')}
                        checkPage={BeforeFinalCheckVm.state.checkPage}
                        btnEdit={() => BeforeFinalCheckVm.btnResetFinalSubmissionModel()}
                        btnYes={() => BeforeFinalCheckVm.btnYes()}
                        btnBack={() => BeforeFinalCheckVm.btnBack()}
                      />
                      {BeforeFinalCheckVm.smartFormType() === '주식회사' ||
                      BeforeFinalCheckVm.smartFormType() === '농업회사법인' ||
                      BeforeFinalCheckVm.smartFormType() === '어업회사법인' ? (
                        <>
                          <div className={clsx(st.row, st.before)}>
                            <div className={st.dName}>참여 인원</div>
                            <div className={st.data}>
                              {BeforeFinalCheckVm.companySize()}
                              {!BeforeFinalCheckVm.state.checkPage && (
                                <Link to={BeforeFinalCheckVm.page('companySize').link}>
                                  <RoundBtn className={st.reviseBtn}>수정하기</RoundBtn>
                                </Link>
                              )}
                            </div>
                          </div>
                          {BeforeFinalCheckVm.initiatorRole() && (
                            <div className={clsx(st.row, st.before)}>
                              <div className={st.dName}>참여자 역할</div>
                              <div className={st.data}>
                                {BeforeFinalCheckVm.initiatorRole()}
                                {!BeforeFinalCheckVm.state.checkPage && (
                                  <Link to={BeforeFinalCheckVm.page('companySize').link}>
                                    <RoundBtn className={st.reviseBtn}>수정하기</RoundBtn>
                                  </Link>
                                )}
                              </div>
                            </div>
                          )}
                        </>
                      ) : null}
                    </RowContent>
                    <RowContent checkPage="companyName">
                      <FocusBox
                        page={BeforeFinalCheckVm.page('companyName')}
                        checkPage={BeforeFinalCheckVm.state.checkPage}
                        btnEdit={() => BeforeFinalCheckVm.btnResetFinalSubmissionModel()}
                        btnYes={() => BeforeFinalCheckVm.btnYes()}
                        btnBack={() => BeforeFinalCheckVm.btnBack()}
                      />
                      <div className={clsx(st.row, st.before)}>
                        <div className={st.dName}>한글 회사명</div>
                        <div className={st.data}>
                          {BeforeFinalCheckVm.koreanCorpName}
                          {!BeforeFinalCheckVm.state.checkPage && (
                            <Link to={BeforeFinalCheckVm.page('companyName').link}>
                              <RoundBtn className={st.reviseBtn}>수정하기</RoundBtn>
                            </Link>
                          )}
                        </div>
                      </div>
                      {BeforeFinalCheckVm.englishCorpName ? (
                        <div className={clsx(st.row, st.before)}>
                          <div className={st.dName}>영문 회사명</div>
                          <div className={st.data}>
                            {BeforeFinalCheckVm.englishCorpName}
                            {!BeforeFinalCheckVm.state.checkPage && (
                              <Link to={BeforeFinalCheckVm.page('companyName').link}>
                                <RoundBtn className={st.reviseBtn}>수정하기</RoundBtn>
                              </Link>
                            )}
                          </div>
                        </div>
                      ) : null}
                    </RowContent>
                    <RowContent checkPage="companyAddress">
                      <FocusBox
                        page={BeforeFinalCheckVm.page('companyAddress')}
                        checkPage={BeforeFinalCheckVm.state.checkPage}
                        btnEdit={() => BeforeFinalCheckVm.btnResetFinalSubmissionModel()}
                        btnYes={() => BeforeFinalCheckVm.btnYes()}
                        btnBack={() => BeforeFinalCheckVm.btnBack()}
                      />
                      <div className={clsx(st.row, st.before)}>
                        <div className={st.dName}>회사 주소</div>
                        <div className={st.data}>
                          {BeforeFinalCheckVm.corpAddress}
                          {!BeforeFinalCheckVm.state.checkPage && (
                            <Link to={BeforeFinalCheckVm.page('companyAddress').link}>
                              <RoundBtn className={st.reviseBtn}>수정하기</RoundBtn>
                            </Link>
                          )}
                        </div>
                      </div>
                      <div className={clsx(st.row, st.before)}>
                        <div className={st.dName}>우편물 받을 주소</div>
                        <div className={st.data}>
                          {BeforeFinalCheckVm.deliveryAddress}
                          {!BeforeFinalCheckVm.state.checkPage && (
                            <Link to={BeforeFinalCheckVm.page('companyAddress').link}>
                              <RoundBtn className={st.reviseBtn}>수정하기</RoundBtn>
                            </Link>
                          )}
                        </div>
                      </div>
                    </RowContent>
                  </div>
                </div>
                <div className={st.table}>
                  <div className={st.title}>{BeforeFinalCheckVm.name()}정보</div>
                  <div className={st.info}>
                    {BeforeFinalCheckVm.smartFormType() !== '유한책임회사' && (
                      <RowContent checkPage="parValue">
                        <FocusBox
                          page={BeforeFinalCheckVm.page('parValue')}
                          checkPage={BeforeFinalCheckVm.state.checkPage}
                          btnEdit={() => BeforeFinalCheckVm.btnResetFinalSubmissionModel()}
                          btnYes={() => BeforeFinalCheckVm.btnYes()}
                          btnBack={() => BeforeFinalCheckVm.btnBack()}
                        />
                        <div className={clsx(st.row, st.before)}>
                          <div className={st.dName}>
                            {BeforeFinalCheckVm.smartFormType() === '유한회사' ? '출자 1좌의 금액' : '1주당 금액'}
                          </div>
                          <div className={st.data}>
                            {addCommas(BeforeFinalCheckVm.parValue)}원
                            {!BeforeFinalCheckVm.state.checkPage && (
                              <Link to={BeforeFinalCheckVm.page('parValue').link}>
                                <RoundBtn className={st.reviseBtn}>수정하기</RoundBtn>
                              </Link>
                            )}
                          </div>
                        </div>
                      </RowContent>
                    )}
                    <RowContent checkPage="shareholderInvestment">
                      <FocusBox
                        page={BeforeFinalCheckVm.page('shareholderInvestment')}
                        checkPage={BeforeFinalCheckVm.state.checkPage}
                        btnEdit={() => BeforeFinalCheckVm.btnResetFinalSubmissionModel()}
                        btnYes={() => BeforeFinalCheckVm.btnYes()}
                        btnBack={() => BeforeFinalCheckVm.btnBack()}
                      />
                      <div className={clsx(st.row, st.before)}>
                        <div className={st.dName}>자본금</div>
                        <div className={st.data}>
                          {addCommas(BeforeFinalCheckVm.capital)}원
                          {!BeforeFinalCheckVm.state.checkPage && (
                            <Link to={BeforeFinalCheckVm.page('shareholderInvestment').link}>
                              <RoundBtn className={st.reviseBtn}>수정하기</RoundBtn>
                            </Link>
                          )}
                        </div>
                      </div>
                    </RowContent>
                    <RowContent checkPage="shareholders">
                      <FocusBox
                        page={BeforeFinalCheckVm.page('shareholders')}
                        checkPage={BeforeFinalCheckVm.state.checkPage}
                        btnEdit={() => BeforeFinalCheckVm.btnResetFinalSubmissionModel()}
                        btnYes={() => BeforeFinalCheckVm.btnYes()}
                        btnBack={() => BeforeFinalCheckVm.btnBack()}
                      />
                      <div className={clsx(st.row, st.before)}>
                        <div className={st.dName}>{BeforeFinalCheckVm.name()}정보</div>
                        <div className={st.data}>
                          {BeforeFinalCheckVm.shareholders.map((item, idx) => {
                            return (
                              <div className={st.shareholder} key={idx}>
                                <div className={st.shareholderPosition}>
                                  {BeforeFinalCheckVm.name()}
                                  {(BeforeFinalCheckVm.smartFormType() === '유한회사' ||
                                    BeforeFinalCheckVm.smartFormType() === '유한책임회사') &&
                                    '(=사원)'}
                                </div>
                                <div className={st.shareholderInfo}>
                                  <div className={st.person}>
                                    <span>
                                      {item.nationality} {item.koreanName}
                                      {item.englishName ? `(${item.englishName})` : null}{' '}
                                      {item.birth ? `(${item.birth})` : null}
                                    </span>
                                    <span>{percent(BeforeFinalCheckVm.investmentPercent(idx))} 지분</span>
                                    {BeforeFinalCheckVm.smartFormType() !== '유한책임회사' && (
                                      <span>{addCommas(BeforeFinalCheckVm.stock(idx))}주</span>
                                    )}
                                    <span>투자금 {addCommas(String(item.investment))}원</span>
                                    {BeforeFinalCheckVm.smartFormType() === '농업회사법인' && (
                                      <div className={st.personType}>농업인</div>
                                    )}
                                    {BeforeFinalCheckVm.smartFormType() === '어업회사법인' && (
                                      <div className={st.personType}>어업인</div>
                                    )}
                                  </div>
                                  <div className={st.address}>
                                    {item.address} {item.englishAddress ? `(${item.englishAddress})` : null}
                                  </div>
                                  {item.representatives && (
                                    <div className={st.representativeInfo}>
                                      {item.shareholderType === 'FOREIGNER_COMPANY' && (
                                        <div className={st.representativeTitle}>대표자 정보</div>
                                      )}
                                      <div className={st.representative}>
                                        {item.representatives?.map((representative, idx) => {
                                          if (item.shareholderType === 'KOREAN_COMPANY') {
                                            return (
                                              <div key={idx}>
                                                {representative.position} {representative.koreanName}
                                              </div>
                                            );
                                          } else if (item.shareholderType === 'FOREIGNER_COMPANY') {
                                            return (
                                              <div key={idx}>
                                                {representative.koreanName + `(${representative.englishName})`}
                                              </div>
                                            );
                                          }
                                        })}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                          {!BeforeFinalCheckVm.state.checkPage && (
                            <Link to={BeforeFinalCheckVm.page('shareholders').link}>
                              <RoundBtn className={st.shareholderReviseBtn}>수정하기</RoundBtn>
                            </Link>
                          )}
                        </div>
                      </div>
                    </RowContent>
                  </div>
                </div>
                <div className={st.table}>
                  <div className={st.title}>임원정보</div>
                  <div className={st.info}>
                    <RowContent checkPage="executiveMembers">
                      <FocusBox
                        page={BeforeFinalCheckVm.page('executiveMembers')}
                        checkPage={BeforeFinalCheckVm.state.checkPage}
                        btnEdit={() => BeforeFinalCheckVm.btnResetFinalSubmissionModel()}
                        btnYes={() => BeforeFinalCheckVm.btnYes()}
                        btnBack={() => BeforeFinalCheckVm.btnBack()}
                      />
                      <div className={clsx(st.row, st.before)}>
                        <div className={st.dName}>임원정보</div>
                        <div className={st.data}>
                          {BeforeFinalCheckVm.executives.map((item, idx) => {
                            return (
                              <div className={st.executive} key={idx}>
                                <div className={st.executivePosition}>{item.position}</div>
                                <div className={st.executiveInfo}>
                                  <div className={st.person}>
                                    <span>
                                      {item.nationality} {item.koreanName}
                                      {item.englishName ? `(${item.englishName})` : null} ({item.birth}){' '}
                                      {item.resignationYn ? (
                                        <span className={st.resignation}>설립후 이어서 사임등기 신청</span>
                                      ) : null}
                                    </span>
                                    {BeforeFinalCheckVm.smartFormType() === '농업회사법인' &&
                                      (BeforeFinalCheckVm.isShareholder(idx) ||
                                        BeforeFinalCheckVm.isFarmerOrFisherman(idx)) && (
                                        <div className={st.personType}>농업인</div>
                                      )}
                                    {BeforeFinalCheckVm.smartFormType() === '어업회사법인' &&
                                      (BeforeFinalCheckVm.isShareholder(idx) ||
                                        BeforeFinalCheckVm.isFarmerOrFisherman(idx)) && (
                                        <div className={st.personType}>어업인</div>
                                      )}
                                  </div>
                                  <div className={st.address}>{item.address}</div>
                                  {BeforeFinalCheckVm.positionInfoDisplay(idx) === '주식회사' && (
                                    <div className={st.positionInfo}>
                                      <span className={st.star}>*</span> 이사가 1명이기 때문에, 등기부에는 대표권 있는
                                      사내이사로 표기됩니다.
                                    </div>
                                  )}
                                  {BeforeFinalCheckVm.positionInfoDisplay(idx) === '유한회사' && (
                                    <div className={st.positionInfo}>
                                      <span className={st.star}>*</span> 이사가 1명이기 때문에, 등기부에는 대표권 있는
                                      이사로 표기됩니다.
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                          {!BeforeFinalCheckVm.state.checkPage && (
                            <Link to={BeforeFinalCheckVm.page('executiveMembers').link}>
                              <RoundBtn className={st.executiveMembersReviseBtn}>수정하기</RoundBtn>
                            </Link>
                          )}
                        </div>
                      </div>
                    </RowContent>
                  </div>
                </div>
                <div className={st.table}>
                  <div className={st.title}>기타정보</div>
                  <div className={st.info}>
                    <RowContent checkPage="businessPurpose">
                      <FocusBox
                        page={BeforeFinalCheckVm.page('businessPurpose')}
                        checkPage={BeforeFinalCheckVm.state.checkPage}
                        btnEdit={() => BeforeFinalCheckVm.btnResetFinalSubmissionModel()}
                        btnYes={() => BeforeFinalCheckVm.btnYes()}
                        btnBack={() => BeforeFinalCheckVm.btnBack()}
                      />
                      <div className={clsx(st.row, st.before)}>
                        <div className={st.dName}>사업목적</div>

                        <div className={clsx(st.data, st.businessPurpose)}>
                          <div>
                            <div className={st.purposeBox}>
                              <div className={st.purposeWrapper}>
                                {BeforeFinalCheckVm.purposes.map((purpose, idx) => (
                                  <span
                                    className={clsx(
                                      st.purpose,
                                      purpose.purposeType === purposeType.RECOMMENDED ? st.recommend : st.direct,
                                    )}
                                    key={idx}
                                  >
                                    1. {purpose.content}
                                  </span>
                                ))}
                              </div>
                            </div>
                          </div>
                          {!BeforeFinalCheckVm.state.checkPage && (
                            <Link style={{ marginLeft: '17px' }} to={BeforeFinalCheckVm.page('businessPurpose').link}>
                              <RoundBtn className={st.businessPurposeReviseBtn}>수정하기</RoundBtn>
                            </Link>
                          )}
                        </div>
                      </div>
                      <div className={st.alarmText}>
                        * 총 {BeforeFinalCheckVm.totalPurposeCounts}개 ={' '}
                        <span style={{ color: '#006ac3' }}>
                          추천 목적 {BeforeFinalCheckVm.recommendedPurposeCounts}개
                        </span>{' '}
                        {BeforeFinalCheckVm.directedPurposeCounts > 0 && (
                          <span style={{ color: '#404040' }}>
                            <span style={{ color: '#999fac' }}> + </span>
                            직접입력 목적 {BeforeFinalCheckVm.directedPurposeCounts}개
                          </span>
                        )}
                        {` (추가금액 : ${addCommas(BeforeFinalCheckVm.totalAdditionalChargeCounts * 5500) || 0}원 / 총 ${BeforeFinalCheckVm.totalAdditionalChargeCounts}개 초과)`}
                      </div>
                    </RowContent>
                    {BeforeFinalCheckVm.smartFormType() === '주식회사' ||
                    BeforeFinalCheckVm.smartFormType() === '농업회사법인' ||
                    BeforeFinalCheckVm.smartFormType() === '어업회사법인' ? (
                      <RowContent checkPage="option">
                        <FocusBox
                          page={BeforeFinalCheckVm.page('option')}
                          checkPage={BeforeFinalCheckVm.state.checkPage}
                          btnEdit={() => BeforeFinalCheckVm.btnResetFinalSubmissionModel()}
                          btnYes={() => BeforeFinalCheckVm.btnYes()}
                          btnBack={() => BeforeFinalCheckVm.btnBack()}
                        />
                        <div className={clsx(st.row, st.before)}>
                          <div className={st.dName}>스톡옵션</div>
                          <div className={st.data}>
                            {BeforeFinalCheckVm.stockOptionYn()}
                            {!BeforeFinalCheckVm.state.checkPage && (
                              <Link to={BeforeFinalCheckVm.page('option').link}>
                                <RoundBtn className={st.reviseBtn}>수정하기</RoundBtn>
                              </Link>
                            )}
                          </div>
                        </div>
                        <div className={clsx(st.row, st.before)}>
                          <div className={st.dName}>주식양도제한</div>
                          <div className={st.data}>
                            {BeforeFinalCheckVm.shareTransferRestrictionsYn()}
                            {!BeforeFinalCheckVm.state.checkPage && (
                              <Link to={BeforeFinalCheckVm.page('option').link}>
                                <RoundBtn className={st.reviseBtn}>수정하기</RoundBtn>
                              </Link>
                            )}
                          </div>
                        </div>
                        <div className={clsx(st.row, st.before)}>
                          <div className={st.dName}>공고방법</div>
                          <div className={st.data}>
                            {BeforeFinalCheckVm.announcementMethod}
                            {!BeforeFinalCheckVm.state.checkPage && (
                              <Link to={BeforeFinalCheckVm.page('option').link}>
                                <RoundBtn className={st.reviseBtn}>수정하기</RoundBtn>
                              </Link>
                            )}
                          </div>
                        </div>
                        <div className={clsx(st.row, st.before)}>
                          <div className={st.dName}>예비 사회적 기업</div>
                          <div className={st.data}>
                            {BeforeFinalCheckVm.prospectiveSocialEnterpriseYn()}
                            {!BeforeFinalCheckVm.state.checkPage && (
                              <Link to={BeforeFinalCheckVm.page('option').link}>
                                <RoundBtn className={st.reviseBtn}>수정하기</RoundBtn>
                              </Link>
                            )}
                          </div>
                        </div>
                      </RowContent>
                    ) : null}
                    <RowContent checkPage="chooseStamp">
                      <FocusBox
                        page={BeforeFinalCheckVm.page('chooseStamp')}
                        checkPage={BeforeFinalCheckVm.state.checkPage}
                        btnEdit={() => BeforeFinalCheckVm.btnResetFinalSubmissionModel()}
                        btnYes={() => BeforeFinalCheckVm.btnYes()}
                        btnBack={() => BeforeFinalCheckVm.btnBack()}
                      />
                      <div className={clsx(st.row, st.before)}>
                        <div className={st.dName}>법인인감</div>
                        <div className={st.data}>
                          {BeforeFinalCheckVm.corpSeals()}
                          {!BeforeFinalCheckVm.state.checkPage && (
                            <Link to={BeforeFinalCheckVm.page('chooseStamp').link}>
                              <RoundBtn className={st.corpSealsReviseBtn}>수정하기</RoundBtn>
                            </Link>
                          )}
                        </div>
                      </div>
                      <div className={clsx(st.row, st.before)}>
                        <div className={st.dName}>사용인감</div>
                        <div className={st.data}>
                          {BeforeFinalCheckVm.usageSeal()}
                          {!BeforeFinalCheckVm.state.checkPage && (
                            <Link to={BeforeFinalCheckVm.page('chooseStamp').link}>
                              <RoundBtn className={st.corpSealsReviseBtn}>수정하기</RoundBtn>
                            </Link>
                          )}
                        </div>
                      </div>
                    </RowContent>
                    {BeforeFinalCheckVm.canUseYn() && (
                      <RowContent checkPage="certificate">
                        <FocusBox
                          page={BeforeFinalCheckVm.page('certificate')}
                          checkPage={BeforeFinalCheckVm.state.checkPage}
                          btnEdit={() => BeforeFinalCheckVm.btnResetFinalSubmissionModel()}
                          btnYes={() => BeforeFinalCheckVm.btnYes()}
                          btnBack={() => BeforeFinalCheckVm.btnBack()}
                        />
                        <div className={clsx(st.row, st.before)}>
                          <div className={st.dName} style={{ lineHeight: '1.2' }}>
                            공동인증서 및<br />
                            금융인증서
                          </div>
                          <div className={st.data}>
                            {BeforeFinalCheckVm.canUseYn()}
                            {!BeforeFinalCheckVm.state.checkPage && (
                              <Link to={BeforeFinalCheckVm.page('certificate').link}>
                                <RoundBtn className={st.reviseBtn}>수정하기</RoundBtn>
                              </Link>
                            )}
                          </div>
                        </div>
                      </RowContent>
                    )}
                    <RowContent checkPage="freeLicenseRegistration">
                      <FocusBox
                        page={BeforeFinalCheckVm.page('freeLicenseRegistration')}
                        checkPage={BeforeFinalCheckVm.state.checkPage}
                        btnEdit={() => BeforeFinalCheckVm.btnResetFinalSubmissionModel()}
                        btnYes={() => BeforeFinalCheckVm.btnYes()}
                        btnBack={() => BeforeFinalCheckVm.btnBack()}
                      />
                      <div className={clsx(st.row, st.before)}>
                        <div className={st.dName}>무료사업자등록</div>
                        <div className={st.data}>
                          {BeforeFinalCheckVm.freeLicenseRegistrationYn()}
                          {!BeforeFinalCheckVm.state.checkPage && (
                            <Link to={BeforeFinalCheckVm.page('freeLicenseRegistration').link}>
                              <RoundBtn className={st.reviseBtn}>수정하기</RoundBtn>
                            </Link>
                          )}
                        </div>
                      </div>
                    </RowContent>
                    {BeforeFinalCheckVm.smartFormType() === '농업회사법인' && (
                      <RowContent checkPage="agriculturalNoticeOfEstablishment">
                        <FocusBox
                          page={BeforeFinalCheckVm.page('agriculturalNoticeOfEstablishment')}
                          checkPage={BeforeFinalCheckVm.state.checkPage}
                          btnEdit={() => BeforeFinalCheckVm.btnResetFinalSubmissionModel()}
                          btnYes={() => BeforeFinalCheckVm.btnYes()}
                          btnBack={() => BeforeFinalCheckVm.btnBack()}
                        />
                        <div className={clsx(st.row, st.before)}>
                          <div className={st.dName}>설립통지</div>
                          <div className={st.data}>
                            {BeforeFinalCheckVm.noticeOfEstablishmentYn()}
                            {!BeforeFinalCheckVm.state.checkPage && (
                              <Link to={BeforeFinalCheckVm.page('agriculturalNoticeOfEstablishment').link}>
                                <RoundBtn className={st.reviseBtn}>수정하기</RoundBtn>
                              </Link>
                            )}
                          </div>
                        </div>
                      </RowContent>
                    )}
                    {BeforeFinalCheckVm.smartFormType() === '어업회사법인' && (
                      <RowContent checkPage="fisheryNoticeOfEstablishment">
                        <FocusBox
                          page={BeforeFinalCheckVm.page('fisheryNoticeOfEstablishment')}
                          checkPage={BeforeFinalCheckVm.state.checkPage}
                          btnEdit={() => BeforeFinalCheckVm.btnResetFinalSubmissionModel()}
                          btnYes={() => BeforeFinalCheckVm.btnYes()}
                          btnBack={() => BeforeFinalCheckVm.btnBack()}
                        />
                        <div className={clsx(st.row, st.before)}>
                          <div className={st.dName}>설립통지</div>
                          <div className={st.data}>
                            {BeforeFinalCheckVm.noticeOfEstablishmentYn()}
                            {!BeforeFinalCheckVm.state.checkPage && (
                              <Link to={BeforeFinalCheckVm.page('fisheryNoticeOfEstablishment').link}>
                                <RoundBtn className={st.reviseBtn}>수정하기</RoundBtn>
                              </Link>
                            )}
                          </div>
                        </div>
                      </RowContent>
                    )}
                  </div>
                </div>
                <div className={st.finalPrice}>
                  <div className={BeforeFinalCheckVm.finalPayment === 0 ? clsx(st.final, st.fail) : st.final}>
                    최종 결제금액<span className={st.explain}>(공과금 + 수수료)</span>
                  </div>
                  <div className={st.price}>
                    {BeforeFinalCheckVm.finalPayment === 0 ? (
                      <div className={st.priceFail}>비용 안내생성에 실패했습니다. 새로고침을 해 주세요.</div>
                    ) : (
                      <>
                        {addCommas(BeforeFinalCheckVm.finalPayment)}
                        <span className={st.won}>원</span>
                      </>
                    )}
                  </div>
                </div>
                <div className={st.finalSubmitBtn}>
                  <div className={st.explain}>
                    <div>※ 입력내용을 꼼꼼히 확인하고, [최종 제출하기] 버튼을 눌러주세요.</div>
                    <div>※ 정보 수정을 원하실 경우, [정보 수정하기] 버튼을 눌러서 수정해 주세요.</div>
                    <div>
                      ※ [최종 제출] 이후에는 비용입금, 잔고증명서 업로드, 서류작성, 도장제작, 공과금납부가 순차적으로
                      진행됩니다.
                      <ul className={st.infoList}>
                        <li>
                          진행과정에서 특이사항이 있다면 채팅방으로 안내드릴 예정이며,
                          <br />
                          특이사항이 없는 경우, 필요 서류 작성 후 바로 서명요청 또는 도장날인 요청을 드리겠습니다.
                        </li>
                      </ul>
                    </div>
                    <div>※ [최종 제출] 이후에 정보수정을 원하실 경우, 채팅 상담을 통해 요청해 주세요.</div>
                    <div>
                      ※ 정보 수정 수수료 안내
                      <ul className={st.infoList}>
                        <li>
                          잔고증명서 업로드 후 ~ 전자서명 요청 전 / 도장찍을 서류 발송 전 : 무료
                          <ul className={st.balanceCertificateList}>
                            <li>다만 도장 재제작이 필요한 경우, 추가제작 실비를 요청드릴 수 있습니다.</li>
                            <li>
                              공과금을 이미 납부한 경우, 재입금이 필요할 수 있습니다. (기존 납부금액은 고객님 계좌로
                              환급 신청해드립니다.)
                            </li>
                          </ul>
                        </li>
                        <li>전자서명 요청 후 / 도장찍을 서류 발송 후 ~ 등기 접수 전 : 33,000원/1회</li>
                        <li>등기 접수 후 : 수정 불가능</li>
                      </ul>
                    </div>
                  </div>
                  <div className={st.sumbitBtn}>
                    <NextBtn
                      type="submit"
                      onClick={() => BeforeFinalCheckVm.submit()}
                      disabled={BeforeFinalCheckVm.state.submitBtnDisabledYn}
                    >
                      최종제출 및 비용 안내서 확인
                    </NextBtn>
                  </div>
                </div>
              </div>
            )}
          </ContentBox>
        </Question>
      </div>
    </>
  ));
}

export default BeforeFinalCheck;
