import regExp from '@common/module/regExp';
import formDataToJson, { findPos, objectToFormData } from '@common/module/submit';
import StockCompanySealStepModel from '@model/changeRegistration/StockCompanySealStepModel';
import { _alert } from '@model/dialogModel';
import { pageDepth } from '@model/pageStepModel';
import portalModel from '@model/portalModel';
import Case1Modal from '@pages/ModalContent/changeRegistration/StockholdersMeetingMusterNotice/Case1Modal';
import Case2Modal from '@pages/ModalContent/changeRegistration/StockholdersMeetingMusterNotice/Case2Modal';
import Case3Modal from '@pages/ModalContent/changeRegistration/StockholdersMeetingMusterNotice/Case3Modal';
import stepService from '@service/stepService';
import { observable, runInAction, toJS } from 'mobx';

class StockholdersMeetingMusterNoticeVm {
  constructor() {
    this.state = observable({
      addressModal: false,
    });
  }
  get stockholderMeetingMusterNoticeType() {
    return StockCompanySealStepModel.StockholdersMeetingMusterNotice.stockholderMeetingMusterNoticeType;
  }
  setStockholderMeetingMusterNoticeType(e) {
    StockCompanySealStepModel.StockholdersMeetingMusterNotice.stockholderMeetingMusterNoticeType = e.target.value;
  }
  get 주주총회일() {
    return StockCompanySealStepModel.StockholdersMeetingMusterNotice.주주총회일;
  }
  get musterNoticeScheduledDate() {
    return StockCompanySealStepModel.StockholdersMeetingMusterNotice.musterNoticeScheduledDate;
  }
  setMusterNoticeScheduledDateYear(e) {
    StockCompanySealStepModel.StockholdersMeetingMusterNotice.musterNoticeScheduledDate.year = e.target.value;
  }
  setMusterNoticeScheduledDateMonth(e) {
    StockCompanySealStepModel.StockholdersMeetingMusterNotice.musterNoticeScheduledDate.month = e.target.value;
  }
  setMusterNoticeScheduledDateDay(e) {
    StockCompanySealStepModel.StockholdersMeetingMusterNotice.musterNoticeScheduledDate.day = e.target.value;
  }
  set주주총회일Year(e) {
    StockCompanySealStepModel.StockholdersMeetingMusterNotice.주주총회일.year = e.target.value;
  }
  set주주총회일Month(e) {
    StockCompanySealStepModel.StockholdersMeetingMusterNotice.주주총회일.month = e.target.value;
  }
  set주주총회일Day(e) {
    StockCompanySealStepModel.StockholdersMeetingMusterNotice.주주총회일.day = e.target.value;
  }
  musterNoticeScheduledDateMonthBlur(e) {
    const month = e.target.value;

    if (month && month.length === 1) {
      e.target.value = '0' + month;
    }

    runInAction(() => {
      StockCompanySealStepModel.StockholdersMeetingMusterNotice.musterNoticeScheduledDate.month = e.target.value;
    });
  }
  musterNoticeScheduledDateDayBlur(e) {
    const day = e.target.value;

    if (day && day.length === 1) {
      e.target.value = '0' + day;
    }

    runInAction(() => {
      StockCompanySealStepModel.StockholdersMeetingMusterNotice.musterNoticeScheduledDate.day = e.target.value;
    });
  }
  주주총회일MonthBlur(e) {
    const month = e.target.value;

    if (month && month.length === 1) {
      e.target.value = '0' + month;
    }

    runInAction(() => {
      StockCompanySealStepModel.StockholdersMeetingMusterNotice.주주총회일.month = e.target.value;
    });
  }
  주주총회일DayBlur(e) {
    const day = e.target.value;

    if (day && day.length === 1) {
      e.target.value = '0' + day;
    }

    runInAction(() => {
      StockCompanySealStepModel.StockholdersMeetingMusterNotice.주주총회일.day = e.target.value;
    });
  }
  displayBirthErrorMessage(type, modelKey) {
    const year = StockCompanySealStepModel.StockholdersMeetingMusterNotice[modelKey].year;
    const month = StockCompanySealStepModel.StockholdersMeetingMusterNotice[modelKey].month;

    if (type === 'month') {
      if (year && regExp.year().test(year)) {
        //연도가 제대로 입력되었을 때, 월에 대한 error 메시지
        return true;
      }

      return false;
    } else if (type === 'day') {
      if (year && regExp.year().test(year) && month && regExp.month().test(month)) {
        // 연도, 월이 제대로 입력 되었을 때, 일(날짜)에 대한 error 메시지
        return true;
      }

      return false;
    }
  }
  get stockholderMeetingPlaceType() {
    return StockCompanySealStepModel.StockholdersMeetingMusterNotice.stockholderMeetingPlaceType;
  }
  setStockholderMeetingPlaceType(e) {
    StockCompanySealStepModel.StockholdersMeetingMusterNotice.stockholderMeetingPlaceType = e.target.value;
  }
  get address() {
    return StockCompanySealStepModel.StockholdersMeetingMusterNotice.address;
  }
  get detailAddress() {
    return StockCompanySealStepModel.StockholdersMeetingMusterNotice.detailAddress;
  }
  openAddressModal(openYn) {
    runInAction(() => {
      this.state.addressModal = openYn;
    });
  }
  addressComplate(address) {
    runInAction(() => {
      StockCompanySealStepModel.StockholdersMeetingMusterNotice.address = address;
      StockCompanySealStepModel.StockholdersMeetingMusterNotice.detailAddress = '';
      StockCompanySealStepModel.StockholdersMeetingMusterNotice.nameOfBuilding = address.buildingName;
    });
    this.openAddressModal(false);
  }
  setDetailAddress(e) {
    //상세주소
    runInAction(() => {
      StockCompanySealStepModel.StockholdersMeetingMusterNotice.detailAddress = e.target.value;
    });
  }
  get bnameFontSizeChange() {
    const bName = StockCompanySealStepModel.StockholdersMeetingMusterNotice.address.bname;

    if (bName) {
      if (bName.length > 3) return true; //bname이 3글자보다 길면 true

      return false;
    }

    return false;
  }
  get nameOfBuilding() {
    return StockCompanySealStepModel.StockholdersMeetingMusterNotice.nameOfBuilding;
  }
  setNameOfBuilding(e) {
    runInAction(() => {
      StockCompanySealStepModel.StockholdersMeetingMusterNotice.nameOfBuilding = e.target.value;
    });
  }
  get stockholderMeetingMusterNoticeFiles() {
    return StockCompanySealStepModel.StockholdersMeetingMusterNotice.stockholderMeetingMusterNoticeFiles;
  }
  get uploadFiles() {
    return StockCompanySealStepModel.StockholdersMeetingMusterNotice.uploadFiles;
  }
  get deleteFileIds() {
    return StockCompanySealStepModel.StockholdersMeetingMusterNotice.deleteFileIds;
  }
  async submit(e) {
    e.preventDefault();
    if (this.stockholderMeetingMusterNoticeType === 'ONE' || this.stockholderMeetingMusterNoticeType === 'TWO') {
      if (this.stockholderMeetingMusterNoticeFiles.length === 0 && this.uploadFiles.length === 0) {
        const fileCheck = setInterval(() => {
          clearInterval(fileCheck);
          const top = findPos(document.querySelector('.fileUpload')) - 185;

          window.scroll(0, top);
        }, 30);

        await _alert('주주총회 소집통지문을 업로드해주세요.');

        return;
      }
    }

    const originalFormData = formDataToJson(e.target);

    if (originalFormData.error) {
      return;
    }

    const param = originalFormData.data;

    if (param.address) {
      param.address = this.address;
    }
    if (this.stockholderMeetingMusterNoticeType === 'ONE' || this.stockholderMeetingMusterNoticeType === 'TWO') {
      param.deleteFileIds = toJS(this.deleteFileIds);
    }

    const formData = new FormData();

    objectToFormData(formData, param);

    if (this.stockholderMeetingMusterNoticeType === 'ONE' || this.stockholderMeetingMusterNoticeType === 'TWO') {
      this.uploadFiles.forEach((file, index) => {
        formData.append(`stockholderMeetingMusterNoticeFiles[${index}]`, file);
      });
    }

    // 법인해산, 청산 스텝이 있는 경우 저장 후 종료
    if (this.hasLiquidationStep()) {
      stepService.saveStep(formData);

      return;
    }

    //팝업 & 저장(팝업에서 저장)
    let modal = null;

    if (this.stockholderMeetingMusterNoticeType === 'ONE' || this.stockholderMeetingMusterNoticeType === 'TWO') {
      modal = <Case1Modal formData={formData} />;
    } else if (this.stockholderMeetingMusterNoticeType === 'THREE') {
      modal = <Case2Modal formData={formData} noticeSendMethod={this.noticeSendMethod} />;
    } else if (this.stockholderMeetingMusterNoticeType === 'FOUR') {
      modal = <Case3Modal formData={formData} />;
    }

    runInAction(() => {
      portalModel.modalContent = modal;
      portalModel.btn = null;
      portalModel.closeBtn = true;
    });
  }
  get noticeSendMethod() {
    return StockCompanySealStepModel.StockholdersMeetingMusterNotice.noticeSendMethod;
  }
  setNoticeSendMethod(e) {
    StockCompanySealStepModel.StockholdersMeetingMusterNotice.noticeSendMethod = e.target.value;
  }
  // 법인해산, 청산 스텝이 있는지 확인
  hasLiquidationStep() {
    return pageDepth.hasLiquidationStep();
  }
}

export default new StockholdersMeetingMusterNoticeVm();
