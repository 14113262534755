import { useObserver } from 'mobx-react';
import React, { useState, useEffect, useCallback } from 'react';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Icon from '@comComponents/atoms/Icon/Icon';
import StepBtn from '@comComponents/atoms/Button/StepBtn/StepBtn';
import Badge from '@comComponents/atoms/BadgeBox/BadgeBox';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import Ul from '@comComponents/atoms/Text/UnorderListText/UnorderList';
import TextBox from '@comComponents/atoms/Text/TextBox/TextBox';
import LikeBtn from '@comComponents/atoms/HyperLink/LikeBtn/LikeBtn';
import clsx from 'clsx';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './BalanceCertificate.module.scss';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import BalanceCertificateVm from './BalanceCertificateVm';
import FileUpload from '@comComponents/molecules/FileUpload/FileUpload';
import Loading from '@comComponents/atoms/Loading/Loading';

function BalanceCertificate() {
  const [isHidden, setIsHidden] = useState(false);
  const onScroll = useCallback(() => {
    if (window.scrollY > 10) {
      setIsHidden(true);
    } else {
      setIsHidden(false);
    }
  }, []);
  useEffect(() => {
    BalanceCertificateVm.loadBalanceCertificate();
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);
  return useObserver(() => (
    <div>
      <header className={clsx(st.questionHeader, isHidden ? st.hidden : null)}>
        <div className={st.content}>
          <div className={st.leftBg}></div>
          <div className={st.rightBg}></div>
          <ContentBox>
            <div className={st.titleBox}>
              <h1 className={st.title}>
                <Icon icon="q" />
                <span className={st.text}>잔고 증명서 업로드</span>
              </h1>
              <div className={st.btnArea}>
                <StepBtn
                  step="next"
                  size={isHidden ? 'small' : 'medium'}
                  onClick={() => BalanceCertificateVm.upload()}
                  className={st.stepBtn}
                  disabled={BalanceCertificateVm.state.submitBtnDisabledYn}
                >
                  업로드 후 완료
                </StepBtn>
              </div>
            </div>
          </ContentBox>
        </div>
      </header>
      <div className={clsx('mainContent', st.balanceContent)} style={{ top: '177px' }}>
        <Question>
          {BalanceCertificateVm.state.submitBtnDisabledYn && <Loading />}
          <ContentBox className={Qst.spaceBetween}>
            <div className={Qst.infoSection}>
              <div className={Qst.titleWrap}>
                <h2 className={Qst.title}>
                  <span className={Qst.bold}>잔고증명서(잔액증명서)</span>를 업로드해 주세요.
                </h2>
              </div>
              <Badge className={st.badge} title="예시" type="example" border={false}>
                <img className={st.badgeImg} src="/images/certificate_example_establish.png" alt="잔고 증명서 예시" />
                <div className={st.badgeText}>
                  <ul>
                    <li>
                      잔고증명일자로 부터 <span className={st.bold}>7일이 지나지 않은 잔고증명서 파일</span>을 업로드해
                      주세요.
                    </li>
                    <li>
                      기간이 지난 경우 <span className={st.bold}>월 10만 원의 과태료</span>가 부가됩니다.
                    </li>
                    <li>
                      발급일자와 증명일자가 <span className={st.bold}>동일한 날짜</span>인 경우에는{' '}
                      <span className={st.bold}>입출금 거래가 불가능</span>합니다.
                    </li>
                  </ul>
                </div>
              </Badge>
              <FileUpload
                className={st.fileUpload}
                accept=".pdf, .jpg, .png"
                fileName={BalanceCertificateVm.fileName}
                fileSize={BalanceCertificateVm.fileSize}
                fileExtension={BalanceCertificateVm.fileExtension}
                fileUrl={BalanceCertificateVm.fileUrl}
                model={BalanceCertificateVm.balanceCertificateModel}
                explainText={
                  <span>
                    잔고증명일자가 <span className="bold">7일 이내</span>의 것인지 확인해 주세요.
                  </span>
                }
              >
                <span className={st.bold}>여기</span>를 눌러 잔고증명서를 업로드해 주세요.
              </FileUpload>
            </div>
            <div className={Qst.qnaSection}>
              <Qna tip="잔고증명서 업로드" className={Qst.qnaTitle}>
                <div className={Qst.qnaBox}>
                  <QText>잔고증명서(잔액증명서)는 어떻게 발급받나요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>
                      잔고증명서(잔액증명서)는 인터넷뱅킹 또는 은행방문을 통해서 발급받을 수 있습니다.
                    </Answer>
                    <div className={Qst.explain}>
                      <Ul>
                        <li>잔고증명서(잔액증명서)는 일정 일자에 예금액이 얼마 있었다는 사실을 증명하는 서류입니다.</li>
                        <li>인터넷뱅킹 또는 은행방문을 통해 손쉽게 발급 받을 수 있습니다.</li>
                      </Ul>
                    </div>
                    <LikeBtn
                      className={Qst.likeBtn}
                      href="https://www.help-me.kr/guide/detail/%EC%9E%94%EA%B3%A0%EC%A6%9D%EB%AA%85%EC%84%9C-%EB%B0%9C%EA%B8%89-%EB%B0%A9%EB%B2%95"
                      target="_blank"
                    >
                      잔고증명서 발급 방법 자세히 보기
                    </LikeBtn>
                  </div>
                </div>
                <div className={Qst.qnaBox}>
                  <QText>잔고증명일자로부터 7일 내에 업로드해야 하는 이유가 무엇인가요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>
                      과태료 발생을 예방하기 위해 잔고증명일자로부터 7일 내에 업로드해주세요.
                    </Answer>
                    <div className={Qst.explain}>
                      <Ul>
                        <li>
                          원칙상 잔고증명일자로부터 14일 내에 법인설립 등기 접수를 해야 하고, 14일이 초과된 후에는
                          과태료가 발생합니다.
                        </li>
                        <li>
                          과태료 발생을 넉넉하게 예방하기 위해서 헬프미에서는 잔고증명일자로부터 7일 내에 업로드할 것을
                          요청드리고 있습니다.
                        </li>
                      </Ul>
                    </div>
                  </div>
                </div>
                <div className={Qst.qnaBox}>
                  <QText>어떤 계좌에서 발급받아야 하나요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>
                      주주 중 1명의 자유입출금 계좌에서 발급받아주세요. (새로 만들필요 X)
                    </Answer>
                    <div className={Qst.explain}>
                      <Ul>
                        <li>
                          주주 중 1명의 자유입출금 계좌에 자본금 이상의 돈을 입금시킨 후, 잔고증명서를 발급 받으면
                          됩니다.
                        </li>
                        <li>일반적으로 가장 지분이 많은 주주의 계좌로 정합니다</li>
                        <li>대표이사라고 하더라도, 주식이 없다면 해당 명의로 잔고증명서를 발급받을 수 없습니다.</li>
                      </Ul>
                    </div>
                  </div>
                </div>
                <div className={Qst.qnaBox}>
                  <QText>꼭 자유입출금 계좌에서 발급받아야 하나요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>네 꼭 자유입출금 계좌에서 잔고증명서를 발급받으셔야 합니다.</Answer>
                    <div className={Qst.explain}>
                      등기소에서는 자유입출금 계좌에서 받은 잔고증명서만 인정하므로 주의를 해주세요.
                    </div>
                    <TextBox className={Qst.textBox} title="불가능" warn={true}>
                      <div className={clsx(Qst.textWrap, st.textWrap)}>
                        개인사업자 사업용계좌, 증권사 계좌, CMA 통장, 마이너스 통장, 적금/청약계좌, MMDA, FNA, 증권거래
                        계좌, AMA, MMF 계좌, 외환 계좌
                      </div>
                    </TextBox>
                  </div>
                </div>
                <div className={Qst.qnaBox}>
                  <QText>계좌에는 얼마나 예치되어 있어야 하나요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>자본금과 같거나 더 많은 금액이 예치되어 있으면 됩니다.</Answer>
                  </div>
                </div>
                <div className={Qst.qnaBox}>
                  <QText>잔고증명서를 발급받으면 불이익은 없나요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>
                      발급일자와 잔고증명일자가 동일하면 당일에 한해 입출금 거래가 불가능합니다.
                    </Answer>
                    <div className={Qst.explain}>
                      발급일자와 잔고증명일자가 동일하면 잔고증명을 발급 받은 날은 당일에 한해 입금과 출금이 모두 되지
                      않습니다. 발급일자보다 잔고증명일자가 빠른 경우, 이러한 제한이 없습니다.
                    </div>
                  </div>
                </div>
                <div className={Qst.qnaBox}>
                  <QText>잔고증명서 발급받은 후에 돈을 꺼내 써도 되나요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>사업목적으로 사용하는 경우이라면 돈을 꺼내 써도 됩니다.</Answer>
                    <div className={Qst.explain}>
                      <Ul>
                        <li>
                          잔고증명서를 발급받은 후 법인설립을 완료할 때까지 자본금 만큼은 계속 계좌 잔액을 유지하는 것이
                          원칙입니다.
                        </li>
                        <li>
                          다만 사업 목적으로 사용하는 경우 (ex 설립비용, 임대차보증금, 기타 사업물품 구입 등) 자본금을
                          미리 꺼내 써도 됩니다.{' '}
                        </li>
                        <li>
                          추후 출금 사유가 사업과 관련된 사항임을 증명해야 하므로, 세금계산서나 영수증을 잘 보관해
                          주세요. 법인설립이 완료되면 세무사에게 세금계산서와 영수증을 전달하여 비용처리를 하시면
                          됩니다.
                        </li>
                      </Ul>
                    </div>
                  </div>
                </div>
                <div className={Qst.qnaBox}>
                  <QText>법인설립 후 자본금은 어떻게 처리하나요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>
                      예치한 자본금은 추후 법인계좌를 만든 후, 그 계좌로 이체하면 됩니다.
                    </Answer>
                    <div className={Qst.explain}>
                      <Ul>
                        <li>
                          법인설립 및 사업자등록을 마친 후, 법인계좌를 개설할 수 있습니다. 잔고증명서 발급 후, 예치되어
                          있는 자본금은 법인계좌로 이체해 주세요.
                        </li>
                        <li>법인설립 전에 사업 목적으로 자본금을 이미 썼다면, 남은 잔액만 이체하면 됩니다. </li>
                      </Ul>
                    </div>
                  </div>
                </div>
              </Qna>
            </div>
          </ContentBox>
        </Question>
      </div>
    </div>
  ));
}

export default BalanceCertificate;
