import st from './style.module.scss';

export const modalType = {
  다른_목적O_부동산_중개_추가_시: '다른_목적O_부동산_중개_추가_시',
  부동산_중개O_다른_목적_추가_시: '부동산_중개O_다른_목적_추가_시',
};

const modalText = {
  [modalType.다른_목적O_부동산_중개_추가_시]: {
    title: '새로 고른 <span>[부동산 > 부동산 중개]</span> 목적을 사용하시겠습니까?',
    textHTML: `
      [부동산>부동산중개·공인중개]에 포함된 목적은 공인중개사법 제14조 제1항에 따라 <span>다른 목적과 혼용하여 등기가 불가능합니다.</span>
      <br><br>
      또한 <span>대표가 공인중개사여야 하고, 임원의 1/3이상이 공인중개사여야 하며, 상호에는 부동산중개 또는 공인중개사사무소라는 명칭</span>이 들어가야 합니다.
    `,
    button: {
      yes: '네. [부동산 중개]만 사용하고, 다른 목적은 삭제할게요.',
      no: '아니오. [부동산 중개] 목적만 삭제할게요.',
    },
  },
  [modalType.부동산_중개O_다른_목적_추가_시]: {
    title: '이미 [부동산 > 부동산 중개] 목적을 선택하셨습니다. <span>새로 고른 목적을 사용</span>하시겠습니까?',
    textHTML: `
      [부동산>부동산중개·공인중개]에 포함된 목적은 공인중개사법 제14조 제1항에 따라 <span>다른 목적과 혼용하여 등기가 불가능합니다.</span>
      <br><br>
      또한 <span>대표가 공인중개사여야 하고, 임원의 1/3이상이 공인중개사여야 하며, 상호에는 부동산중개 또는 공인중개사사무소라는 명칭</span>이 들어가야 합니다.
    `,
    button: {
      yes: '네. 새 목적을 사용하고, [부동산 중개]는 삭제할게요.',
      no: '아니오. [부동산 중개] 목적만 유지할게요.',
    },
  },
};

const EstateBrokeragePurposeSelectGuideModal = ({
  type = modalType.다른_목적O_부동산_중개_추가_시,
  onClickYes,
  onClickNo,
}) => {
  return (
    <div className={st.Modal}>
      <div className={st.content}>
        <div className={st.title} dangerouslySetInnerHTML={{ __html: modalText[type].title }}></div>
        <div className={st.text} dangerouslySetInnerHTML={{ __html: modalText[type].textHTML }}></div>
        <div className={st.buttonWrapper}>
          <button type="button" className={st.buttonYes} onClick={onClickYes}>
            {modalText[type].button.yes}
          </button>
          <button type="button" className={st.buttonNo} onClick={onClickNo}>
            {modalText[type].button.no}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EstateBrokeragePurposeSelectGuideModal;
