import { useObserver } from 'mobx-react';
import { Fragment,useEffect } from 'react';
import { Route } from 'react-router-dom';

import AddStockOptionStep from '../AddStockOptionStep/AddStockOptionStep';
import AnnounceMethodStep from '../AnnounceMethodStep/AnnounceMethodStep';
import BoardMeetingSealProvidingStep from '../BoardMeetingSealProvidingStep/BoardMeetingSealProvidingStep';
import BranchOfficeChangeStep from '../BranchOfficeChangeStep/BranchOfficeChangeStep';
import BranchOfficeDeleteStep from '../BranchOfficeDeleteStep/BranchOfficeDeleteStep';
import BranchOfficeInstallStep from '../BranchOfficeInstallStep/BranchOfficeInstallStep';
import BranchOfficeRelocateStep from '../BranchOfficeRelocateStep/BranchOfficeRelocateStep';
import BusinessPurposeStep from '../BusinessPurposeStep/BusinessPurposeStep';
import CapitalIncreaseStep from '../CapitalIncreaseStep/CapitalIncreaseStep';
import ConvertibleBondStep from '../ConvertibleBondStep/ConvertibleBondStep';
import CorpNameStep from '../CorpNameStep/CorpNameStep';
import CorporateTaxStep from '../CorporateTaxStep/CorporateTaxStep';
import ExecutiveMemberChangeStep from '../ExecutiveMemberChangeStep/ExecutiveMemberChangeStep';
import FileUploadStep from '../FileUploadStep/FileUploadStep';
import FreeIssueOfNewSharesStep from '../FreeIssueOfNewSharesStep/FreeIssueOfNewSharesStep';
import HeadOfficeRelocationStep from '../HeadOfficeRelocationStep/HeadOfficeRelocationStep';
import LiquidationStep from '../LiquidationStep/LiquidationStep';
import NumOfSharesToBeIssuedStep from '../NumOfSharesToBeIssuedStep/NumOfSharesToBeIssuedStep';
import PaidInCapitalIncreaseStep from '../PaidInCapitalIncreaseStep/PaidInCapitalIncreaseStep';
import ParValueDivisionStep from '../ParValueDivisionStep/ParValueDivisionStep';
import RepresentativeAddressChangeStep from '../RepresentativeAddressChangeStep/RepresentativeAddressChangeStep';
import StandByBoardMeetingInfoStep from '../StandByBoardMeetingInfoStep/StandByBoardMeetingInfoStep';
import StandbyFileUploadStep from '../StandbyFileUploadStep/StandbyFileUploadStep';
import StandbyFreeIssueOfNewSharesStep from '../StandbyFreeIssueOfNewSharesStep/StandbyFreeIssueOfNewSharesStep';
import StandbyPaidInCapitalIncreaseStep from '../StandbyPaidInCapitalIncreaseStep/StandbyPaidInCapitalIncreaseStep';
import StandbyParValueDivisionStep from '../StandbyParValueDivisionStep/StandbyParValueDivisionStep';
import StandByStockholderMeetingInfoStep from '../StandByStockholderMeetingInfoStep/StandByStockholderMeetingInfoStep';
import StandByUpdateStep from '../StandByUpdateStep/StandByUpdateStep';
import StockCompanySealStep from '../StockCompanySealStep/StockCompanySealStep';
import StockholdersMeetingSealProvidingStep from '../StockholdersMeetingSealProvidingStep/StockholdersMeetingSealProvidingStep';
import TCAgreeAndDeliveryAddressStep from '../TCAgreeAndDeliveryAddressStep/TCAgreeAndDeliveryAddressStep';
import TrademarkStep from '../TrademarkStep/TrademarkStep';
import ChangeRegistrationStepVm from './ChangeRegistrationStepVm';

function ChangeRegistrationStep() {
  useEffect(() => {
    ChangeRegistrationStepVm.loadChangeRegistrationStep();
  }, [ChangeRegistrationStepVm.nowPage]);

  return useObserver(() => (
    <Fragment key={ChangeRegistrationStepVm.nowPage}>
      {ChangeRegistrationStepVm.isLoadData && (
        <>
          {/* 상호 */}
          <Route path="/step/changeRegistration/CorpNameStep" component={CorpNameStep}></Route>
          {/* 스톡옵션 */}
          <Route path="/step/changeRegistration/AddStockOptionStep" component={AddStockOptionStep}></Route>
          {/* 목적 */}
          <Route path="/step/changeRegistration/BusinessPurposeStep" component={BusinessPurposeStep}></Route>
          {/* 본점이전 */}
          <Route path="/step/changeRegistration/HeadOfficeRelocationStep" component={HeadOfficeRelocationStep}></Route>
          {/* 공고방법 */}
          <Route path="/step/changeRegistration/AnnounceMethodStep" component={AnnounceMethodStep}></Route>
          {/* 신주발행 */}
          <Route
            path="/step/changeRegistration/PaidInCapitalIncreaseStep"
            component={PaidInCapitalIncreaseStep}
          ></Route>
          {/* 발행예정주식수 */}
          <Route
            path="/step/changeRegistration/NumOfSharesToBeIssuedStep"
            component={NumOfSharesToBeIssuedStep}
          ></Route>
          {/* 임원+대표자주소 변경 */}
          <Route
            path="/step/changeRegistration/ExecutiveMemberChangeStep"
            component={ExecutiveMemberChangeStep}
          ></Route>
          {/* 지점폐지 */}
          <Route path="/step/changeRegistration/BranchOfficeDeleteStep" component={BranchOfficeDeleteStep}></Route>
          {/* 지점이전 */}
          <Route path="/step/changeRegistration/BranchOfficeRelocateStep" component={BranchOfficeRelocateStep}></Route>
          {/* 지점변경 */}
          <Route path="/step/changeRegistration/BranchOfficeChangeStep" component={BranchOfficeChangeStep}></Route>
          {/* 신규지점설치 */}
          <Route path="/step/changeRegistration/BranchOfficeInstallStep" component={BranchOfficeInstallStep}></Route>
          {/* 이용약관동의 및 송달우편주소 */}
          <Route
            path="/step/changeRegistration/TCAgreeAndDeliveryAddressStep"
            component={TCAgreeAndDeliveryAddressStep}
          ></Route>
          {/* 대표 주소, 주민번호 확인 */}
          <Route
            path="/step/changeRegistration/RepresentativeAddressChangeStep"
            component={RepresentativeAddressChangeStep}
          ></Route>
          {/* z폼_이사회 */}
          <Route
            path="/step/changeRegistration/BoardMeetingSealProvidingStep"
            component={BoardMeetingSealProvidingStep}
          ></Route>
          {/* z폼_주주총회 */}
          <Route
            path="/step/changeRegistration/StockholdersMeetingSealProvidingStep"
            component={StockholdersMeetingSealProvidingStep}
          ></Route>
          {/* x폼 */}
          <Route path="/step/changeRegistration/FileUploadStep" component={FileUploadStep}></Route>
          {/* 무상증자 */}
          <Route path="/step/changeRegistration/FreeIssueOfNewSharesStep" component={FreeIssueOfNewSharesStep}></Route>
          {/* 자본금의 총액 변경(=주식회사의 신주) */}
          <Route path="/step/changeRegistration/CapitalIncreaseStep" component={CapitalIncreaseStep}></Route>
          {/* 액면금 변경 */}
          <Route path="/step/changeRegistration/ParValueDivisionStep" component={ParValueDivisionStep}></Route>
          {/* 법인해산, 청산 */}
          <Route path="/step/changeRegistration/LiquidationStep" component={LiquidationStep}></Route>
          {/* z폼_주주총회_주식회사 */}
          <Route path="/step/changeRegistration/StockCompanySealStep" component={StockCompanySealStep}></Route>
          {/* 법인세 환급 */}
          <Route path="/step/changeRegistration/CorporateTaxStep" component={CorporateTaxStep}></Route>
          {/* 스탠바이 자동업데이트 */}
          <Route path="/step/changeRegistration/StandByUpdateStep" component={StandByUpdateStep}></Route>
          {/* 스탠바이 신주발행 */}
          <Route
            path="/step/changeRegistration/StandbyPaidInCapitalIncreaseStep"
            component={StandbyPaidInCapitalIncreaseStep}
          ></Route>
          {/* 스탠바이 주주총회 */}
          <Route
            path="/step/changeRegistration/StandByStockholderMeetingInfoStep"
            component={StandByStockholderMeetingInfoStep}
          ></Route>
          {/* 스탠바이 이사회 */}
          <Route
            path="/step/changeRegistration/StandByBoardMeetingInfoStep"
            component={StandByBoardMeetingInfoStep}
          ></Route>
          {/* 스탠바이 파일업로드 */}
          <Route path="/step/changeRegistration/StandbyFileUploadStep" component={StandbyFileUploadStep}></Route>
          {/* 전환사채 */}
          <Route path="/step/changeRegistration/ConvertibleBondStep" component={ConvertibleBondStep}></Route>
          {/* 스탠바이 무상증자 */}
          <Route
            path="/step/changeRegistration/StandbyFreeIssueOfNewSharesStep"
            component={StandbyFreeIssueOfNewSharesStep}
          ></Route>
          {/* 스탠바이 액면금분할 */}
          <Route
            path="/step/changeRegistration/StandbyParValueDivisionStep"
            component={StandbyParValueDivisionStep}
          ></Route>
          {/* 상표 */}
          <Route path="/step/changeRegistration/TrademarkStep/Trademark" component={TrademarkStep}></Route>
        </>
      )}
    </Fragment>
  ));
}

export default ChangeRegistrationStep;
