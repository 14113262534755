import { useObserver } from 'mobx-react';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import AddStockOptionVm from './AddStockOptionVm';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import LikeBtn from '@comComponents/atoms/HyperLink/LikeBtn/LikeBtn';
import Ul from '@comComponents/atoms/Text/UnorderListText/UnorderList';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import clsx from 'clsx';
import st from './AddStockOption.module.scss';
import Upload from '@comComponents/molecules/FileUpload/FileUpload';
import { toJS } from 'mobx';
import ErrorText from '@comComponents/atoms/Text/ErrorText/ErrorText';

function AddStockOption() {
  return useObserver(() => (
    <form
      onSubmit={(e) => {
        AddStockOptionVm.submit(e);
      }}
    >
      <input type="submit" value="submit" id="stepForm" style={{ display: 'none' }} />
      <Question>
        <ContentBox className={Qst.spaceBetween}>
          <div className={Qst.infoSection}>
            <div className={Qst.titleWrap}>
              <h2 className={Qst.title}>
                <span className={Qst.bold}>벤처기업 인증</span>을 받으셨나요?
              </h2>
            </div>
            <ul className={Qst.answerList}>
              <li>
                <Radio
                  activeBackground
                  className={Qst.answerLabel}
                  value={true}
                  checked={AddStockOptionVm.ventureYn === true}
                  onChange={AddStockOptionVm.setVentureYn}
                  name="ventureYn"
                >
                  네. 벤처기업인증을 받았습니다.
                </Radio>
              </li>
              <li>
                <Radio
                  activeBackground
                  className={Qst.answerLabel}
                  value={false}
                  checked={AddStockOptionVm.ventureYn === false}
                  onChange={AddStockOptionVm.setVentureYn}
                  name="ventureYn"
                >
                  아니오. 벤처기업인증을 받지 않았습니다.
                </Radio>
              </li>
            </ul>
            <Error
              name="ventureYn"
              value={AddStockOptionVm.ventureYn}
              errorCase={{ required: `벤처기업 인증 여부를 선택해 주세요.` }}
            />
          </div>
          <div className={Qst.qnaSection}>
            <Qna tip="스톡옵션" className={Qst.qnaTitle}>
              <div className={Qst.qnaBox}>
                <QText>스톡옵션이란 무엇인가요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>
                    스톡옵션(=법률상 주식매수선택권)은 회사가 임직원등에게 부여하는 권리로서, 일정 기간이 지난 후에
                    회사의 주식을 미리 정해둔 가격으로 살 수 있는 권리를 말합니다.
                  </Answer>
                </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>스톡옵션은 어떤 절차에 따라 부여하나요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>
                    스톡옵션은 1) 규정등기, 2)당사자와 계약, 3) 주주총회 특별결의 절차를 거쳐 부여합니다.
                  </Answer>
                  <p className={Qst.explain}>
                    스톡옵션 규정등기 이후 스톡옵션을 부여하려면, 당사자와 계약, 주주총회 특별결의를 거쳐야 합니다.
                    스톡옵션 계약서 작성대행을 요청하는 경우, 헬프미 변호사와 직접 상담후 진행하실 수 있습니다. 또한
                    스톡옵션 부여 후 꼭 주주총회 특별결의를 진행해주셔야 하는 점 기억해주세요. 주주총회 특별결의의 경우,
                    헬프미에 의뢰하실 수 있습니다. (공증료, 수수료 발생)
                  </p>
                  <LikeBtn
                    className={Qst.likeBtn}
                    href="https://www.help-me.kr/blog/article/%EC%8A%A4%ED%86%A1%EC%98%B5%EC%85%98%EC%9D%98-%EB%AA%A8%EB%93%A0-%EA%B2%83/"
                    target="_blank"
                  >
                    스톡옵션 부여절차 더 알아보기
                  </LikeBtn>
                </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>벤처기업 인증 여부에 따라 달라지는 것이 있나요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>벤처기업 인증을 받은 경우, 스톡옵션 발행시 혜택이 있습니다.</Answer>
                  <p className={Qst.explain}>
                    벤처기업인증을 받은 벤처기업은 스톡옵션 부여시 아래와 같은 혜택이 있습니다.
                  </p>
                  <div className={Qst.explain}>
                    <Ul>
                      <li>대상확대 : 임직원, 외부전문가(교수, 변호사 등 법률이 정한 사람에게 확대)</li>
                      <li>절차 : 외부 전문가에게 부여시 일부 수량은 이사회에 위임 가능</li>
                      <li>한도 : 발행주식 총수의 50% 이내 발행 가능</li>
                      <li>행사 : 스톡옵션을 받은 사람이 사망, 정년 등 본인 귀책사유 없이 퇴직한 경우에도 행사가능</li>
                    </Ul>
                  </div>
                </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>벤처인증은 어떻게 받나요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>벤처인증은 벤처확인위원회에서 받을 수 있습니다.</Answer>
                  <p className={Qst.explain}>
                    헬프미에서는 벤처인증 업무대행을 하고 있지 않습니다. 따라서 인증 방법에 대한 질의응답 역시 제공하지
                    않는 점 참고 부탁드립니다.
                  </p>
                  <LikeBtn
                    className={Qst.likeBtn}
                    href="https://post.naver.com/viewer/postView.nhn?volumeNo=30861753&memberNo=33777424&searchKeyword=%EC%9D%B8%EC%A6%9D&searchRank=34"
                    target="_blank"
                  >
                    벤처인증 방법 알아보기
                  </LikeBtn>
                </div>
              </div>
            </Qna>
          </div>
        </ContentBox>
      </Question>
      {AddStockOptionVm.ventureYn && ( //벤처기업인증 받은 경우
        <Question>
          <ContentBox className={Qst.spaceBetween}>
            <div className={Qst.infoSection}>
              <div className={Qst.titleWrap}>
                <h2 className={Qst.title}>
                  <span className={Qst.bold}>벤처기업확인서</span>를 업로드해 주세요.
                </h2>
              </div>
              <Upload
                className={clsx(st.fileUpload, 'fileUpload')}
                multiple
                accept=".pdf, .jpg, .png"
                model={AddStockOptionVm.files}
                uploadModel={AddStockOptionVm.uploadFiles}
                deleteModel={AddStockOptionVm.deleteIds}
                files={toJS(AddStockOptionVm.files)}
                uploadFiles={toJS(AddStockOptionVm.uploadFiles)}
                explainText={
                  <div>
                    현재 <span className="bold">유효기간이 남은 </span>벤처기업확인증을 업로드해 주세요.
                  </div>
                }
              >
                <span className={st.bold}>여기</span>를 눌러 업로드해 주세요.
              </Upload>
            </div>
            <div className={Qst.qnaSection}>
              <Qna className={Qst.noTitle}>
                <div className={Qst.qnaBox}>
                  <QText>벤처인증확인서는 어디서 발급받나요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>벤처인증은 중소벤처24사이트에서 발급가능합니다.</Answer>
                    <LikeBtn
                      className={Qst.likeBtn}
                      href="https://www.smes.go.kr/pageApplyCertificateVenture"
                      target="_blank"
                    >
                      벤처기업확인서 발급사이트 바로가기
                    </LikeBtn>
                  </div>
                </div>
              </Qna>
            </div>
          </ContentBox>
        </Question>
      )}
      <Question>
        <ContentBox className={Qst.spaceBetween}>
          <div className={Qst.infoSection}>
            <div className={Qst.titleWrap}>
              <h2 className={Qst.title}>
                <span className={Qst.bold}>어떤 내용으로 스톡옵션 등기</span>를 해드릴까요?
              </h2>
              <div className={clsx(Qst.titleExplain, Qst.noneBefore)}>
                <a
                  style={{ color: '#777c86' }}
                  className={st.helpMeStockOption}
                  href="https://docs.google.com/document/d/1r-ZQJNV_eS03O7ZZyFAg8Mif-gBFabzpY23Rbjackqg/edit#"
                  target="_blank"
                  title="헬프미 고품질 스톡옵션 표준규정 바로보기"
                >
                  헬프미 고품질 스톡옵션 표준규정 바로가기
                </a>
              </div>
            </div>
            <ul className={Qst.answerList}>
              <li>
                <Radio
                  activeBackground
                  className={Qst.answerLabel}
                  value={true}
                  checked={AddStockOptionVm.helpmeRegulationYn === true}
                  onChange={AddStockOptionVm.setHelpmeRegulationYn}
                  name="helpmeRegulationYn"
                >
                  헬프미 스톡옵션 표준규정으로 등기하겠습니다.
                </Radio>
              </li>
              <li>
                <Radio
                  activeBackground
                  className={Qst.answerLabel}
                  value={false}
                  checked={AddStockOptionVm.helpmeRegulationYn === false}
                  onChange={AddStockOptionVm.setHelpmeRegulationYn}
                  name="helpmeRegulationYn"
                >
                  직접 만든 규정으로 등기하겠습니다.
                </Radio>
              </li>
            </ul>
            <Error
              name="helpmeRegulationYn"
              value={AddStockOptionVm.helpmeRegulationYn}
              errorCase={{ required: `스톡옵션 내용을 선택해 주세요.` }}
            />
            {AddStockOptionVm.helpmeRegulationYn === false && (
              <div className={clsx(Qst.titleWrap, Qst.marginTop)}>
                <h2 className={Qst.title}>
                  <span className={Qst.bold}>직접 만든 스톡옵션 규정</span>을 기재해주세요.
                </h2>
                <div className={Qst.titleExplain}>정관 중 몇 조에 해당하는지도 기재해주세요.</div>
                <textarea
                  className={st.textarea}
                  placeholder="직접 만든 스톡옵션 규정을 기재해주세요."
                  onChange={AddStockOptionVm.setRegulation}
                  name="regulation"
                  value={AddStockOptionVm.regulation}
                ></textarea>
                <Error
                  name="regulation"
                  value={AddStockOptionVm.regulation}
                  errorCase={{ required: `직접 만든 스톡옵션 규정을 기재해주세요.` }}
                />
              </div>
            )}
          </div>
        </ContentBox>
      </Question>
    </form>
  ));
}
export default AddStockOption;
