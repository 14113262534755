import ExecutiveMemberChangeStepModel from '@model/changeRegistration/ExecutiveMemberChangeStepModel';
import smartformAppModel from '@model/smartformAppModel';
import stepService from '@service/stepService';
import formDataToJson, { errorFocus, findPos } from '@common/module/submit';
import { runInAction, observable } from 'mobx';
import { _alert, _confirm } from '@model/dialogModel';
import { onlyNumber } from '@common/module/replaceNumber';
import regExp from '@common/module/regExp';
import portalModel from '@model/portalModel';
import ResidenceRegistrationModal from '@pages/ModalContent/changeRegistration/ExecutiveMemberChangeNewChange/ResidenceRegistrationModal';
import ForeignerCertificationModal from '@pages/ModalContent/changeRegistration/ExecutiveMemberChangeNewChange/ForeignerCertificationModal';

class ExecutiveMemberChangeNewChangeVm {
  constructor() {
    this.state = observable({
      openIdx: null,
      addressModal: false,
      addressIdx: null,
      errorArr: [],
    });
  }
  errorState() {
    const error = document.querySelectorAll('[data-error="error"]');
    let errorIdx = [];
    error.forEach((error) => {
      errorIdx.push(Number(error.closest('[data-eq]').dataset.eq));
    });
    errorIdx = errorIdx.filter((elem, idx) => {
      return errorIdx.indexOf(elem) === idx;
    });
    this.state.errorArr = errorIdx;
  }
  submit(e) {
    runInAction(() => {
      e.preventDefault();
      if (formDataToJson(e.target, false).error) {
        //입력하지 않은 데이터가 있는 경우
        this.errorState();
        this.state.openIdx = this.state.errorArr[0];
        const findError = setInterval(() => {
          if (document.querySelector('.open')) {
            clearInterval(findError);
            errorFocus();
          }
        }, 30);
        return;
      } else {
        //올바를 데이터를 입력한 경우
        this.state.errorArr = [];
        this.state.openIdx = null;
        let param = formDataToJson(e.target).data;
        param?.executiveMembers?.forEach((executiveMember, idx) => {
          if (executiveMember.newAddress) {
            param.executiveMembers[idx].newAddress = ExecutiveMemberChangeStepModel.newExecutiveMembers[idx].newAddress;
          }
        });
        if (ExecutiveMemberChangeStepModel.keepProceedYn) {
          stepService.saveStep(param);
        } else {
          if (param.executiveMembers) {
            stepService.saveStep(param);
          } else {
            param.executiveMembers = [];
            stepService.saveStep(param);
          }
        }
      }
    });
  }
  openModal(type) {
    let modal = '';
    if (type === 'ResidenceRegistration') {
      modal = ResidenceRegistrationModal;
    } else if (type === 'ForeignerCertification') {
      modal = ForeignerCertificationModal;
    }
    runInAction(() => {
      portalModel.modalContent = modal.modalContent;
      portalModel.btn = modal.btn ?? null;
      portalModel.closeBtn = modal.closeBtn ?? true;
    });
  }
  get smartFormType() {
    return smartformAppModel.smartFormType;
  }
  get keepProceedYn() {
    return ExecutiveMemberChangeStepModel.keepProceedYn;
  }
  get newExecutiveMembers() {
    return ExecutiveMemberChangeStepModel.newExecutiveMembers;
  }
  get newExecutiveMembersDefaultValue() {
    var val = {
      id: '',
      koreanName: '',
      englishName: '',
      newPosition: '',
      foreignerYn: null,
      kssn: '',
      residenceReportYn: null,
      foreignerCertificationYn: null,
      nationalityType: '',
      nationalityOthers: '',
      birth: {
        day: '',
        month: '',
        year: '',
      },
      newAddress: {},
      nameOfBuilding: '',
      detailAddress: '',
      farmerFishermanYn: null,
      newPositionTag: '',
    };
    return val;
  }
  setIsOpen(idx) {
    return this.state.openIdx === idx;
  }
  setTitle(idx) {
    const keepProceedYn = this.keepProceedYn;
    let title = '';
    let name = this.newExecutiveMembers[idx].koreanName;
    let position = this.newExecutiveMembers[idx].newPosition;
    let positionTag = this.newExecutiveMembers[idx].newPositionTag;
    if (keepProceedYn) {
      //비용 안내대로 진행하는 경우
      if (name) {
        title = name + ' : ' + positionTag;
      } else {
        title = `신규 ${positionTag}님이 되실 분의 정보를 입력해 주세요.`;
      }
    } else {
      //비용 안내대로 진행하지 않는 경우
      if (!name && !position) {
        title = '신규 임원님이 되실 분의 정보를 입력해 주세요.';
      } else if (!name && position) {
        title = '신규 임원(성함을 입력해 주세요.) : ' + position;
      } else if (name && !position) {
        title = name + ' : ' + '직책을 입력해 주세요. ';
      } else {
        title = name + ' : ' + position;
      }
    }
    return title;
  }
  setDropOpen(idx) {
    if (this.state.openIdx === idx) {
      this.state.openIdx = null;
      this.errorState();
    } else {
      this.state.openIdx = idx;
    }
  }
  addNewExecutiveMembers() {
    runInAction(() => {
      ExecutiveMemberChangeStepModel.newExecutiveMembers.push(this.newExecutiveMembersDefaultValue);
      this.state.openIdx = this.newExecutiveMembers.length - 1;
      const newPerson = setInterval(() => {
        clearInterval(newPerson);
        if (this.state.openIdx !== 0) {
          let top = findPos(document.querySelector('.open')) - 155;
          window.scroll(0, top);
        }
      }, 30);
    });
  }
  async setCloseBtn(idx) {
    const keepProceedYn = this.keepProceedYn;
    const newExecutiveMembers = ExecutiveMemberChangeStepModel.newExecutiveMembers;
    let name = this.newExecutiveMembers[idx].koreanName;
    if (!name) {
      name = '해당 신규 임원';
    }
    let confString = name + '님의 정보를 삭제하시겠습니까?';
    const conf = await _confirm(confString);
    if (conf) {
      return newExecutiveMembers.splice(idx, 1);
    }
  }
  personErrorYn(idx) {
    return this.state.errorArr.indexOf(idx) !== -1;
  }
  setForeignerYn(e, idx) {
    runInAction(() => {
      ExecutiveMemberChangeStepModel.newExecutiveMembers[idx].foreignerYn = e.target.value === 'true';
    });
  }
  setKoreanName(e, idx) {
    //한글이름
    runInAction(() => {
      ExecutiveMemberChangeStepModel.newExecutiveMembers[idx].koreanName = e.target.value;
    });
  }
  setEnglishName(e, idx) {
    //영문이름
    runInAction(() => {
      ExecutiveMemberChangeStepModel.newExecutiveMembers[idx].englishName = e.target.value;
    });
  }
  setKssn(e, idx) {
    //주민등록번호
    runInAction(() => {
      ExecutiveMemberChangeStepModel.newExecutiveMembers[idx].kssn = e.target.value;
    });
  }
  get newPositionList() {
    const smartFormType = this.smartFormType;
    switch (smartFormType) {
      case 'STOCK_COMPANY':
        return [
          { text: '단독 대표', value: '단독대표' },
          { text: '각자 대표(대표 2명 이상)', value: '각자대표' },
          { text: '공동 대표(대표 2명 이상)', value: '공동대표' },
          { text: '사내이사', value: '사내이사' },
          { text: '사외이사', value: '사외이사' },
          { text: '기타비상무이사', value: '기타비상무이사' },
          { text: '감사', value: '감사' },
        ];
      case 'AGRICULTURAL_STOCK_COMPANY':
        return [
          { text: '단독 대표', value: '단독대표' },
          { text: '각자 대표(대표 2명 이상)', value: '각자대표' },
          { text: '공동 대표(대표 2명 이상)', value: '공동대표' },
          { text: '사내이사', value: '사내이사' },
          { text: '사외이사', value: '사외이사' },
          { text: '기타비상무이사', value: '기타비상무이사' },
          { text: '감사', value: '감사' },
        ];
      case 'FISHERY_STOCK_COMPANY':
        return [
          { text: '단독 대표', value: '단독대표' },
          { text: '각자 대표(대표 2명 이상)', value: '각자대표' },
          { text: '공동 대표(대표 2명 이상)', value: '공동대표' },
          { text: '사내이사', value: '사내이사' },
          { text: '사외이사', value: '사외이사' },
          { text: '기타비상무이사', value: '기타비상무이사' },
          { text: '감사', value: '감사' },
        ];
      case 'LIMITED_COMPANY':
        return [
          { text: '단독 대표', value: '단독대표' },
          { text: '각자 대표(대표 2명 이상)', value: '각자대표' },
          { text: '공동 대표(대표 2명 이상)', value: '공동대표' },
          { text: '이사', value: '이사' },
          { text: '감사', value: '감사' },
        ];
      case 'LIMITED_LIABILITY_COMPANY':
        return [
          { text: '단독 대표', value: '단독대표' },
          { text: '각자 대표(대표 2명 이상)', value: '각자대표' },
          { text: '공동 대표(대표 2명 이상)', value: '공동대표' },
          { text: '업무집행자', value: '업무집행자' },
        ];
    }
  }
  setNewPosition(e, idx) {
    runInAction(() => {
      ExecutiveMemberChangeStepModel.newExecutiveMembers[idx].newPosition = e.target.value;
    });
  }
  get addressModal() {
    return this.state.addressModal;
  }
  //도로명 주소
  openAddressModal(openYn, idx) {
    runInAction(() => {
      this.state.addressIdx = idx;
      this.state.addressModal = openYn;
    });
  }
  addressComplate(address) {
    runInAction(() => {
      ExecutiveMemberChangeStepModel.newExecutiveMembers[this.state.addressIdx].newAddress = address;
      ExecutiveMemberChangeStepModel.newExecutiveMembers[this.state.addressIdx].detailAddress = '';
      ExecutiveMemberChangeStepModel.newExecutiveMembers[this.state.addressIdx].nameOfBuilding = address.buildingName;
    });
    this.openAddressModal(false, this.state.addressIdx, this.state.newAddressIdx);
  }
  setDetailAddress(e, idx) {
    //상세주소
    runInAction(() => {
      ExecutiveMemberChangeStepModel.newExecutiveMembers[idx].detailAddress = e.target.value;
    });
  }
  setNameOfBuilding(e, idx) {
    //건물명
    runInAction(() => {
      ExecutiveMemberChangeStepModel.newExecutiveMembers[idx].nameOfBuilding = e.target.value;
    });
  }
  setResidenceReportYn(e, idx) {
    //거소등록여부
    runInAction(() => {
      ExecutiveMemberChangeStepModel.newExecutiveMembers[idx].residenceReportYn = e.target.value === 'true';
    });
  }
  setForeignerCertificationYn(e, idx) {
    //인감증명서 또는 본인서명사실확인서 제출 여부
    runInAction(() => {
      ExecutiveMemberChangeStepModel.newExecutiveMembers[idx].foreignerCertificationYn = e.target.value === 'true';
    });
  }
  setNationalityType(e, idx) {
    //국적
    runInAction(() => {
      ExecutiveMemberChangeStepModel.newExecutiveMembers[idx].nationalityType = e.target.value;
    });
  }
  setNationalityOthers(e, idx) {
    //기타국적
    runInAction(() => {
      ExecutiveMemberChangeStepModel.newExecutiveMembers[idx].nationalityOthers = e.target.value;
    });
  }
  //생년월일
  setYear(e, idx) {
    runInAction(() => {
      ExecutiveMemberChangeStepModel.newExecutiveMembers[idx].birth.year = onlyNumber(e.target.value);
    });
  }
  setMonth(e, idx) {
    runInAction(() => {
      ExecutiveMemberChangeStepModel.newExecutiveMembers[idx].birth.month = onlyNumber(e.target.value);
    });
  }
  monthBlur(e, idx) {
    runInAction(() => {
      let month = e.target.value;
      if (month && month.length === 1) {
        e.target.value = '0' + month;
      }
      runInAction(() => {
        ExecutiveMemberChangeStepModel.newExecutiveMembers[idx].birth.month = e.target.value;
      });
    });
  }
  setDay(e, idx) {
    runInAction(() => {
      ExecutiveMemberChangeStepModel.newExecutiveMembers[idx].birth.day = onlyNumber(e.target.value);
    });
  }
  dayBlur(e, idx) {
    runInAction(() => {
      let day = e.target.value;
      if (day && day.length === 1) {
        e.target.value = '0' + day;
      }
      runInAction(() => {
        ExecutiveMemberChangeStepModel.newExecutiveMembers[idx].birth.day = e.target.value;
      });
    });
  }
  displayBirthErrorMessage(idx, type) {
    const year = this.newExecutiveMembers[idx].birth.year;
    const month = this.newExecutiveMembers[idx].birth.month;

    if (type === 'month') {
      if (year && regExp.year().test(year)) {
        //연도가 제대로 입력되었을 때, 월에 대한 error 메시지
        return true;
      } else {
        return false;
      }
    } else if (type === 'day') {
      if (year && regExp.year().test(year) && month && regExp.month().test(month)) {
        // 연도, 월이 제대로 입력 되었을 때, 일(날짜)에 대한 error 메시지
        return true;
      } else {
        return false;
      }
    }
  }
  setFarmerFishermanYn(e, idx) {
    runInAction(() => {
      ExecutiveMemberChangeStepModel.newExecutiveMembers[idx].farmerFishermanYn = e.target.value === 'true';
    });
  }
  get displayDropTitle() {
    const keepProceedYn = this.keepProceedYn;
    if (keepProceedYn) {
      return '신규 임원님의 정보를 입력해 주세요.';
    } else {
      return '신규로 취임하는 임원이 있다면 신규 임원을 추가해 주세요.';
    }
  }
  displayKoreanName(idx) {
    const keepProceedYn = this.keepProceedYn;
    const koreanName = ExecutiveMemberChangeStepModel.newExecutiveMembers[idx].koreanName;
    const newPosition = ExecutiveMemberChangeStepModel.newExecutiveMembers[idx].newPosition;
    const newPositionTag = ExecutiveMemberChangeStepModel.newExecutiveMembers[idx].newPositionTag;
    if (keepProceedYn) {
      if (koreanName) return koreanName;
      else return `신규 ${newPositionTag}`;
    } else {
      if (koreanName) return koreanName;
      else return '신규 임원';
    }
  }
  get displayNewPosition() {
    const keepProceedYn = this.keepProceedYn;
    if (keepProceedYn) {
      return false;
    } else {
      return true;
    }
  }
  displayAddress(idx) {
    //주소입력 표시 여부_직책이 대표인 경우에만 표시
    const newPosition = ExecutiveMemberChangeStepModel.newExecutiveMembers[idx].newPosition;
    if (
      newPosition === '단독대표' ||
      newPosition === '각자대표' ||
      newPosition === '공동대표' ||
      newPosition === '사내이사_대표' ||
      newPosition === '이사_대표' ||
      newPosition === '대표업무집행자' ||
      newPosition === '각자대표업무집행자' ||
      newPosition === '공동대표업무집행자' ||
      newPosition === '업무집행자_대표'
    ) {
      return true;
    } else {
      return false;
    }
  }
  displayFarmerFisherman(idx) {
    const smartFormType = this.smartFormType;
    const newPosition = ExecutiveMemberChangeStepModel.newExecutiveMembers[idx].newPosition;
    if (smartFormType === 'AGRICULTURAL_STOCK_COMPANY' || smartFormType === 'FISHERY_STOCK_COMPANY') {
      if (newPosition === '감사') {
        //감사인 경우 질문X
        return false;
      } else {
        //단독대표, 각자대표, 공동대표, 사내이사. 사외이사, 기타비상무이사 질문O
        return true;
      }
    } else {
      return false;
    }
  }
  get displayFarmerFishermanText() {
    const smartFormType = this.smartFormType;
    if (smartFormType === 'AGRICULTURAL_STOCK_COMPANY') {
      return '농업';
    } else if (smartFormType === 'FISHERY_STOCK_COMPANY') {
      return '어업';
    }
  }
  displayPosition(idx) {
    const keepProceedYn = this.keepProceedYn;
    let position = this.newExecutiveMembers[idx].newPosition;
    let positionTag = this.newExecutiveMembers[idx].newPositionTag;
    if (keepProceedYn) {
      return positionTag;
    } else {
      if (position) {
        return position;
      } else {
        return '임원';
      }
    }
  }
}
export default new ExecutiveMemberChangeNewChangeVm();
