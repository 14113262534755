import { _confirm } from '@model/dialogModel';
import etcInfoModel from '@model/establish/etcInfoModel';
import { purposeType } from '@pages/Establish/EtcInfo/BusinessPurpose/constant';
import { arrayMoveImmutable } from 'array-move';
import { runInAction } from 'mobx';
import { useLocalObservable } from 'mobx-react';
import { useCallback, useEffect } from 'react';

/**
 * @note 예외적인 케이스라 서버 데이터에 키값 추가하지 않고 프론트에서 하드코딩으로 예외처리.
 *       추후 지속적으로 추가될 수 있다면 서버 데이터에 키값 추가 필요.
 * @author 김록원
 * @date 2025-02-18
 */
const bottomFixedPurposeContentList = ['위 각호에 관련된 전자상거래 및 통신판매중개업'];

const useSelectedPurpose = () => {
  const selectedPurposeMob = useLocalObservable(() => ({
    isDragging: false,
  }));

  const onSortStart = () => {
    runInAction(() => {
      selectedPurposeMob.isDragging = true;
    });
  };

  const onSortEnd = (e) => {
    const updatedPurposes = arrayMoveImmutable(etcInfoModel.businessPurposes, e.oldIndex, e.newIndex);

    runInAction(() => {
      etcInfoModel.businessPurposes = updatedPurposes;
      selectedPurposeMob.isDragging = false;
    });
  };

  const removePurposes = useCallback(async () => {
    if (await _confirm('전체 목적을 삭제하시겠습니까?')) {
      etcInfoModel.businessPurposes = [];
    }
  }, []);

  const removePurposeById = useCallback(async (purposeId) => {
    const newArr = etcInfoModel.businessPurposes.filter((purpose) => purpose.recommendedPurposeId !== purposeId);

    if (await _confirm('선택한 목적을 삭제하시겠습니까?')) {
      etcInfoModel.businessPurposes = newArr;
    }
  }, []);

  const businessPurposes = [...etcInfoModel.businessPurposes];

  const recommendPurposeCount = businessPurposes.filter(
    (purpose) => purpose.purposeType === purposeType.RECOMMENDED,
  ).length;
  const directPurposeCount = businessPurposes.filter((purpose) => purpose.purposeType == purposeType.DIRECT).length;

  const bottomFixedPurposes = businessPurposes.filter((purpose) =>
    bottomFixedPurposeContentList.includes(purpose.content),
  );
  const draggablePurposes = businessPurposes.filter(
    (purpose) => !bottomFixedPurposeContentList.includes(purpose.content),
  );

  // 하단 고정 목적들 최하단으로 옮기기
  useEffect(() => {
    if (bottomFixedPurposes.length === 0) {
      return;
    }

    runInAction(() => {
      bottomFixedPurposes.forEach((purpose) => {
        const index = etcInfoModel.businessPurposes.findIndex((p) => p.content === purpose.content);

        if (index === -1) {
          return;
        }

        const updated = arrayMoveImmutable(etcInfoModel.businessPurposes, index, -1);

        etcInfoModel.businessPurposes = updated;
      });
    });
  }, [etcInfoModel.businessPurposes.length, bottomFixedPurposes.length]);

  return {
    selectedPurposeMob,
    onSortStart,
    onSortEnd,
    recommendPurposeCount,
    directPurposeCount,
    businessPurposes,
    bottomFixedPurposes,
    draggablePurposes,
    removePurposes,
    removePurposeById,
  };
};

export default useSelectedPurpose;
