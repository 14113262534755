import { useObserver } from 'mobx-react';
import BranchOfficeDeleteVm from './BranchOfficeDeleteVm';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './BranchOfficeDelete.module.scss';
import Check from '@comComponents/atoms/Input/Check/Check';
import TextBtn from '@comComponents/atoms/Button/TextBtn/TextBtn';
import ErrorText from '@comComponents/atoms/Text/ErrorText/ErrorText';

function BranchOfficeDelete() {
  return useObserver(() => (
    <form onSubmit={(e) => BranchOfficeDeleteVm.submit(e)}>
      <input type="submit" value="submit" id="stepForm" style={{ display: 'none' }} />
      <Question>
        <ContentBox className={Qst.spaceBetween}>
          <div className={Qst.infoSection}>
            <div className={Qst.titleWrap}>
              <h2 className={Qst.title}>
                아래는 <span className={Qst.bold}>등기부상 지점 목록</span>입니다.
                <br />
                <span className={Qst.bold}>폐지할 지점</span>을 <span className={Qst.bold}>모두 선택</span>해 주세요.
              </h2>
              <div className={Qst.titleExplain}>
                고객님이 비용 안내단계에서 말씀하신 폐지할 지점이 미리 선택되어 있습니다.
              </div>
              <div className={Qst.titleExplain}>
                만약 비용 안내단계에서 말씀하신 폐지할 지점 숫자 또는 위치가 달라지는 경우, 공과금 및 수수료가 달라질 수
                있습니다.
              </div>
            </div>
            <div className={st.checkHeader}>
              <div>
                <Check
                  className={st.checkAll}
                  style={{ fontSize: '17px', fontWeight: '500', top: '1px', whiteSpace: 'pre' }}
                  checked={BranchOfficeDeleteVm.checkAll}
                  onChange={(e) => BranchOfficeDeleteVm.setDeleteCheckAll(e)}
                  removeCheck
                  lineThrough={false}
                >
                  전체선택({BranchOfficeDeleteVm.deleteCount}/{BranchOfficeDeleteVm.branchOffices.length})
                </Check>
              </div>
              <div>
                <TextBtn className={st.unCheckAll} onClick={() => BranchOfficeDeleteVm.setDeleteCheckAll(false)}>
                  선택해제
                </TextBtn>
              </div>
            </div>
            <input type="hidden" name="branchOfficeDeletes" value="delete"></input>
            <ul className={st.branchAddressList}>
              {BranchOfficeDeleteVm.branchOffices.map((item, idx) => {
                return (
                  <li key={idx} className={item.deleteYn ? st.removeLi : null}>
                    <Check
                      style={{ fontSize: '18px' }}
                      name="branchOfficeDeletes"
                      value={item.id}
                      checked={item.deleteYn === true}
                      onChange={(e) => BranchOfficeDeleteVm.setDeleteYn(e, idx)}
                      removeCheck
                      lineThrough={false}
                    >
                      {item.address}
                    </Check>
                  </li>
                );
              })}
            </ul>
            {BranchOfficeDeleteVm.dataError() && (
              <div>
                <ErrorText>폐지할 지점을 선택해 주세요.</ErrorText>
              </div>
            )}
          </div>
        </ContentBox>
      </Question>
    </form>
  ));
}

export default BranchOfficeDelete;
