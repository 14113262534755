import InputText from '@comComponents/atoms/Input/InputText/InputText';
import Select from '@comComponents/atoms/Input/Select/Select';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import { addCommas } from '@common/module/replaceNumber';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import clsx from 'clsx';
import { useObserver } from 'mobx-react';
import { Fragment } from 'react';

import st from './StandbyCapitalIncrease.module.scss';
import vm from './StandbyCapitalIncreaseVm';

function StandbyCapitalIncrease() {
  return useObserver(() => (
    <form onSubmit={(e) => vm.submit(e)}>
      <input type="submit" value="submit" id="stepForm" style={{ display: 'none' }} />
      <Question>
        <ContentBox>
          <div className={st.titleWrap}>
            <h2 className={st.title}>
              회사의 <span className={st.bold}>기존 등기부 상태</span>를 확인해주세요.
            </h2>
          </div>
          <table className={st.sharesOnRegisterTable}>
            <colgroup>
              <col width="234px" />
              <col />
              <col />
              <col width="420px" />
            </colgroup>
            <thead className={st.thead}>
              <tr>
                <th>주식종류</th>
                <th>등기부상 주식수</th>
                <th>등기부상 액면금</th>
                <th>등기부상 자본금(= 주식수 x 액면금)</th>
              </tr>
            </thead>
            <tbody className={st.tbody}>
              {vm.classStocksOnRegister.map((stock, idx) => {
                return (
                  <tr key={idx}>
                    <td>{stock.name}</td>
                    <td>{addCommas(stock.number)}주</td>
                    <td>{addCommas(stock.parValue)}원</td>
                    <td>{addCommas(stock.capitalStock)}원</td>
                  </tr>
                );
              })}
              <tr>
                <td>합계</td>
                <td>{addCommas(vm.totalNumberOfSharesOnRegister)}주</td>
                <td></td>
                <td>{addCommas(vm.totalCapitalStock)}원</td>
              </tr>
            </tbody>
          </table>
        </ContentBox>
      </Question>
      <Question>
        <ContentBox>
          <div className={st.titleWrap}>
            <h2 className={st.title}>
              <span className={st.bold}>주식종류, 투자금, 주식수</span>를 입력해주세요.
            </h2>
            <div className={st.explainBox}>
              <div className={st.exContent}>
                <div className={st.title}>입력방법</div>
                <div className={st.content}>
                  <div className={st.title}>1. 주식종류 선택하기</div>
                  <div className={st.content}>- 종류를 정한 적이 없다면 보통주 선택!</div>
                  <div className={st.title}>2. 투자금 입력하기</div>
                  <div className={st.content}>
                    - 정확한 금액O 대략적 금액X
                    <br />- 가수금 증자의 경우, 증자할 금액만!
                  </div>
                  <div className={st.title}>3. 주식수 입력하기</div>
                </div>
              </div>
              <div className={st.exContent}>
                <div className={st.title}>
                  자동계산된
                  <br />
                  발행가액
                  <br />
                  확인방법
                </div>
                <div className={st.content}>
                  <div className={st.title}>
                    1.{' '}
                    <a
                      className={st.link}
                      href="https://www.help-me.kr/guide/detail/1%EC%A3%BC%EB%8B%B9-%EA%B8%88%EC%95%A1%EC%9D%80-%EC%8B%9C%EA%B0%80-%EB%B0%9C%ED%96%89%EA%B0%80%EC%99%80-%EC%96%B4%EB%96%BB%EA%B2%8C-%EB%8B%A4%EB%A5%B8%EA%B0%80%EC%9A%94"
                      target="_blank"
                      rel="noreferrer"
                    >
                      발행가액
                    </a>{' '}
                    = 투자금 ÷ 주식 수
                  </div>
                  <div className={st.title}>2. 발행가액은 소수점이 불가능!</div>
                  <div className={st.title}>3. 발행가액이 소수점인 경우 해결방법</div>
                  <div className={st.content}>
                    1) 투자자에게 추가 입금을 받거나, 소액의 투자금을 반환하기
                    <br />
                    2) 인수 주식수를 줄이거나 늘리기
                    <br />
                    3) 외국돈 → 원화 환전시 소수점이 된 경우, 반올림하기
                  </div>
                </div>
              </div>
            </div>
            <div className={st.titleExplain}>
              발행가액이 투자자마다 달라도 무방하지만, 주식 발행 시기가 비슷하거나(6개월~1년 미만), 신주인수인끼리 특수
              관계가 있다면 증여세가 발생할 수도 있습니다.
            </div>
            <div className={st.titleExplain}>
              여러 번에 나누어 신주발행을 해야 하는 경우, 담당 매니저에게 말씀해주세요.
            </div>
          </div>
          <table className={st.newSharesTable}>
            <colgroup>
              <col width="148px" />
              <col width="104px" />
              <col />
              <col width="212px" />
              <col width="212px" />
              <col width="208px" />
            </colgroup>
            <thead className={st.thead}>
              <tr>
                <th>신주인수인</th>
                <th>발행방법</th>
                <th>주식종류</th>
                <th>투자금</th>
                <th>주식수</th>
                <th>발행가액(자동계산)</th>
              </tr>
            </thead>
            <tbody className={st.tbody}>
              {vm.newShares.map((newShare, index) => {
                let is유상증자 = false,
                  is가수금증자 = false;

                if (newShare?.유상증자) is유상증자 = true;
                if (newShare?.가수금증자) is가수금증자 = true;

                return (
                  <Fragment key={index}>
                    {newShare?.유상증자?.stocks?.map((stock, idx1) => {
                      let _className = null;
                      const length = newShare.유상증자.stocks.length;

                      if (is유상증자 && !is가수금증자) {
                        //유상증자만 있는 경우
                        if (length === 1) {
                          _className = st.row;
                        } else {
                          if (idx1 === 0) {
                            _className = st.firstRow;
                          } else if (idx1 === length - 1) {
                            _className = st.lastRow;
                          }
                        }
                      } else if (is유상증자 && is가수금증자) {
                        //유상증자, 가수금 증자 모두 있는 경우
                        if (idx1 === 0) {
                          _className = st.firstRow;
                        } else if (idx1 === length - 1) {
                          _className = st.유상증자LastRow;
                        }
                      }

                      return (
                        <tr key={idx1} className={clsx(index % 2 === 0 ? st.even : st.odd, _className)}>
                          {idx1 === 0 ? (
                            <>
                              <td className={st.name}>
                                <input type="hidden" name={`newShares[${index}].id`} value={newShare.id} />
                                <input type="hidden" name={`newShares[${index}].name`} value={newShare.name} />
                                {newShare.name}
                              </td>
                              <td className={st.resourceType}>유상증자</td>
                            </>
                          ) : (
                            <>
                              <td></td>
                              <td></td>
                            </>
                          )}
                          <td>
                            <input
                              type="hidden"
                              name={`newShares[${index}].유상증자.stocks[${idx1}].id`}
                              value={stock.id || ''}
                            />
                            <Select
                              className={clsx(st.selectStock, st.miniSelect)}
                              name={`newShares[${index}].유상증자.stocks[${idx1}].stockType`}
                              style={{ width: '210px' }}
                              value={stock.stockType ?? ''}
                              onChange={(e) => vm.set유상증자StockType(e, index, idx1)}
                              errorFixed
                              errorText={
                                <Error
                                  name={`newShares[${index}].유상증자.stocks[${idx1}].stockType`}
                                  style={{ fontSize: '16px' }}
                                  value={stock.stockType}
                                  errorCase={{ required: '주식종류를 선택해 주세요.' }}
                                />
                              }
                            >
                              <option value="">-</option>
                              <option value="보통주">보통주</option>
                              <option value="우선주">우선주</option>
                              <option value="전환우선주">전환우선주</option>
                              <option value="상환우선주">상환우선주</option>
                              <option value="전환상환우선주">전환상환우선주</option>
                              <option value="직접입력">직접입력</option>
                            </Select>
                            {idx1 === 0 ? (
                              <button type="button" className={st.rowBtn} onClick={() => vm.add유상증자Stock(index)}>
                                추가
                              </button>
                            ) : (
                              <button
                                type="button"
                                className={clsx(st.rowBtn, st.delete)}
                                onClick={() => vm.delete유상증자Stock(index, idx1)}
                              >
                                삭제
                              </button>
                            )}
                            {stock.stockType === '직접입력' && (
                              <InputText
                                className={st.inputStock}
                                inputClassName={st.miniInput}
                                placeholder="주식종류를 입력"
                                name={`newShares[${index}].유상증자.stocks[${idx1}].name`}
                                style={{ width: '210px' }}
                                value={stock.name ?? ''}
                                onChange={(e) => vm.set유상증자StockName(e, index, idx1)}
                                errorFixed
                                errorText={
                                  <Error
                                    name={`newShares[${index}].유상증자.stocks[${idx1}].name`}
                                    value={stock.name}
                                    errorCase={{ required: '주식종류를 입력해 주세요.' }}
                                    style={{ fontSize: '16px' }}
                                  />
                                }
                              />
                            )}
                          </td>
                          <td className={stock.stockType === '직접입력' ? st.vertical : null}>
                            <InputText
                              className={st.investment}
                              inputClassName={st.miniInput}
                              placeholder="0"
                              placeholderAlign="right"
                              name={`newShares[${index}].유상증자.stocks[${idx1}].investment`}
                              style={{ width: '160px' }}
                              value={addCommas(stock.investment)}
                              onChange={(e) => vm.set유상증자Investment(e, index, idx1)}
                              errorIconNon
                              errorFixed
                              errorText={
                                <Error
                                  name={`newShares[${index}].유상증자.stocks[${idx1}].investment`}
                                  value={stock.investment}
                                  style={
                                    vm.유상증자투자금ValidateFunc(index, idx1).numberCheckYn
                                      ? { width: '175px', fontSize: '16px', textAlign: 'left' }
                                      : { width: '515px', fontSize: '16px', textAlign: 'left' }
                                  }
                                  errorCase={{
                                    required: '투자금을 입력해 주세요.',
                                    validate: {
                                      func: vm.유상증자투자금ValidateFunc(index, idx1).validation,
                                      message: vm.유상증자투자금ValidateFunc(index, idx1).message,
                                    },
                                  }}
                                />
                              }
                            />
                            <span>원</span>
                          </td>
                          <td className={stock.stockType === '직접입력' ? st.vertical : null}>
                            <InputText
                              inputClassName={st.miniInput}
                              className={st.stockNumber}
                              placeholder="0"
                              placeholderAlign="right"
                              name={`newShares[${index}].유상증자.stocks[${idx1}].number`}
                              style={{ width: '160px' }}
                              value={addCommas(stock.number)}
                              onChange={(e) => vm.set유상증자Number(e, index, idx1)}
                              errorIconNon
                              errorFixed
                              errorText={
                                <Error
                                  name={`newShares[${index}].유상증자.stocks[${idx1}].number`}
                                  value={stock.number}
                                  style={{ width: '175px', fontSize: '16px', textAlign: 'left' }}
                                  errorCase={{
                                    required: '주식수를 입력해 주세요.',
                                    validate: {
                                      func: vm.유상증자주식수ValidateFunc(index, idx1).validation,
                                      message: vm.유상증자주식수ValidateFunc(index, idx1).message,
                                    },
                                  }}
                                />
                              }
                            />
                            <span>주</span>
                          </td>
                          <td className={stock.stockType === '직접입력' ? st.vertical : null}>
                            {vm.유상증자issuePrice(index, idx1)?.point ? (
                              <span>
                                {addCommas(vm.유상증자issuePrice(index, idx1).issuePrice)}
                                <span className={st.issuePriceError}>
                                  .{addCommas(vm.유상증자issuePrice(index, idx1).point)}
                                </span>
                              </span>
                            ) : (
                              addCommas(vm.유상증자issuePrice(index, idx1))
                            )}
                            원
                            {vm.classStocksOnRegister[0].parValue === vm.유상증자issuePrice(index, idx1) && (
                              <div className={st.successPrice}>액면가</div>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                    {newShare?.가수금증자?.stocks?.map((stock, idx2) => {
                      let _className = null;
                      const length = newShare.가수금증자.stocks.length;

                      if (!is유상증자 && is가수금증자) {
                        //가수금증자만 있는 경우
                        if (length === 1) {
                          _className = st.row;
                        } else {
                          if (idx2 === 0) {
                            _className = st.firstRow;
                          } else if (idx2 === length - 1) {
                            _className = st.lastRow;
                          }
                        }
                      } else if (is유상증자 && is가수금증자) {
                        //유상증자, 가수금 증자 모두 있는 경우
                        if (length === 1) {
                          _className = st.가수금증자Row;
                        } else if (idx2 === 0) {
                          _className = st.가수금증자FirstRow;
                        } else if (idx2 === length - 1) {
                          _className = st.lastRow;
                        }
                      }

                      return (
                        <tr key={idx2} className={clsx(index % 2 === 0 ? st.even : st.odd, _className)}>
                          {idx2 === 0 ? (
                            <>
                              {!newShare?.유상증자 ? (
                                <td className={st.name}>
                                  <input type="hidden" name={`newShares[${index}].id`} value={newShare.id} />
                                  <input type="hidden" name={`newShares[${index}].name`} value={newShare.name} />
                                  {newShare.name}
                                </td>
                              ) : (
                                <td></td>
                              )}
                              <td className={st.resourceType}>가수금증자</td>
                            </>
                          ) : (
                            <>
                              <td></td>
                              <td></td>
                            </>
                          )}
                          <td>
                            <input
                              type="hidden"
                              name={`newShares[${index}].가수금증자.stocks[${idx2}].id`}
                              value={stock.id || ''}
                            />
                            <Select
                              className={clsx(st.selectStock, st.miniSelect)}
                              name={`newShares[${index}].가수금증자.stocks[${idx2}].stockType`}
                              style={{ width: '210px' }}
                              value={stock.stockType ?? ''}
                              onChange={(e) => vm.set가수금증자StockType(e, index, idx2)}
                              errorFixed
                              errorText={
                                <Error
                                  name={`newShares[${index}].가수금증자.stocks[${idx2}].stockType`}
                                  style={{ fontSize: '16px' }}
                                  value={stock.stockType}
                                  errorCase={{ required: '주식종류를 선택해 주세요.' }}
                                />
                              }
                            >
                              <option value="">-</option>
                              <option value="보통주">보통주</option>
                              <option value="우선주">우선주</option>
                              <option value="전환우선주">전환우선주</option>
                              <option value="상환우선주">상환우선주</option>
                              <option value="전환상환우선주">전환상환우선주</option>
                              <option value="직접입력">직접입력</option>
                            </Select>
                            {idx2 === 0 ? (
                              <button type="button" className={st.rowBtn} onClick={() => vm.add가수금증자Stock(index)}>
                                추가
                              </button>
                            ) : (
                              <button
                                type="button"
                                className={clsx(st.rowBtn, st.delete)}
                                onClick={() => vm.delete가수금증자Stock(index, idx2)}
                              >
                                삭제
                              </button>
                            )}
                            {stock.stockType === '직접입력' && (
                              <InputText
                                className={st.inputStock}
                                inputClassName={st.miniInput}
                                placeholder="주식종류를 입력"
                                name={`newShares[${index}].가수금증자.stocks[${idx2}].name`}
                                value={stock.name ?? ''}
                                style={{ width: '210px', height: '40px', fontSize: '16px' }}
                                onChange={(e) => vm.set가수금증자StockName(e, index, idx2)}
                                errorFixed
                                errorText={
                                  <Error
                                    name={`newShares[${index}].가수금증자.stocks[${idx2}].name`}
                                    value={stock.name}
                                    style={{ fontSize: '16px', textAlign: 'left' }}
                                    errorCase={{ required: '주식종류를 입력해 주세요.' }}
                                  />
                                }
                              />
                            )}
                          </td>
                          <td className={stock.stockType === '직접입력' ? st.vertical : null}>
                            <InputText
                              className={st.investment}
                              placeholder="0"
                              placeholderAlign="right"
                              name={`newShares[${index}].가수금증자.stocks[${idx2}].investment`}
                              value={addCommas(stock.investment)}
                              style={{ width: '160px', height: '40px', fontSize: '16px' }}
                              onChange={(e) => vm.set가수금증자Investment(e, index, idx2)}
                              errorIconNon
                              errorFixed
                              errorText={
                                <Error
                                  name={`newShares[${index}].가수금증자.stocks[${idx2}].investment`}
                                  value={stock.investment}
                                  style={
                                    vm.가수금증자투자금ValidateFunc(index, idx2).numberCheckYn
                                      ? { width: '175px', fontSize: '16px', textAlign: 'left' }
                                      : { width: '515px', fontSize: '16px', textAlign: 'left' }
                                  }
                                  errorCase={{
                                    required: '투자금을 입력해 주세요.',
                                    validate: {
                                      func: vm.가수금증자투자금ValidateFunc(index, idx2).validation,
                                      message: vm.가수금증자투자금ValidateFunc(index, idx2).message,
                                    },
                                  }}
                                />
                              }
                            />
                            <span>원</span>
                          </td>
                          <td className={stock.stockType === '직접입력' ? st.vertical : null}>
                            <InputText
                              className={st.stockNumber}
                              placeholder="0"
                              placeholderAlign="right"
                              name={`newShares[${index}].가수금증자.stocks[${idx2}].number`}
                              value={addCommas(stock.number)}
                              style={{ width: '160px', height: '40px', fontSize: '16px' }}
                              onChange={(e) => vm.set가수금증자Number(e, index, idx2)}
                              errorIconNon
                              errorFixed
                              errorText={
                                <Error
                                  name={`newShares[${index}].가수금증자.stocks[${idx2}].number`}
                                  value={stock.number}
                                  style={{ width: '175px', fontSize: '16px', textAlign: 'left' }}
                                  errorCase={{
                                    required: '주식수를 입력해 주세요.',
                                    validate: {
                                      func: vm.가수금증자주식수ValidateFunc(index, idx2).validation,
                                      message: vm.가수금증자주식수ValidateFunc(index, idx2).message,
                                    },
                                  }}
                                />
                              }
                            />
                            <span>주</span>
                          </td>
                          <td className={stock.stockType === '직접입력' ? st.vertical : null}>
                            {vm.가수금증자issuePrice(index, idx2)?.point ? (
                              <span>
                                {addCommas(vm.가수금증자issuePrice(index, idx2).issuePrice)}
                                <span className={st.issuePriceError}>
                                  .{addCommas(vm.가수금증자issuePrice(index, idx2).point)}
                                </span>
                              </span>
                            ) : (
                              addCommas(vm.가수금증자issuePrice(index, idx2))
                            )}
                            원
                            {vm.classStocksOnRegister[0].parValue === vm.가수금증자issuePrice(index, idx2) && (
                              <div className={st.successPrice}>액면가</div>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </Fragment>
                );
              })}
              <tr>
                <td className={st.sum}>합계</td>
                <td></td>
                <td></td>
                <td>
                  {vm.investmentSum()}
                  <span className={st.tagText}>원</span>
                </td>
                <td>
                  {vm.numberSum()}
                  <span className={st.tagText}>주</span>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </ContentBox>
      </Question>
    </form>
  ));
}

export default StandbyCapitalIncrease;
