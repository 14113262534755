import { useState } from 'react';

const staging =
  process.env.REACT_APP_ENV === 'staging' || process.env.REACT_APP_ENV === 'development' ? 'staging.' : '';
const url = `https://${staging}help-me.kr/blog`;

const useAiChat = () => {
  const [keyword, setKeyword] = useState('');

  const handleInputChange = (e) => {
    setKeyword(e.target.value);
  };

  const search = () => {
    if (!keyword.trim()) return;

    const encodedKeyword = encodeURIComponent(keyword);

    window.open(`${url}?aisearch=${encodedKeyword}`, '_blank');
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();

      search();
    }
  };

  return {
    keyword,
    handleInputChange,
    search,
    onKeyDown,
  };
};

export default useAiChat;
