import Icon from '@comComponents/atoms/Icon/Icon';
import ErrorText from '@comComponents/atoms/Text/ErrorText/ErrorText';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import TextBox from '@comComponents/atoms/Text/TextBox/TextBox';
import FileUpload from '@comComponents/molecules/FileUpload/FileUpload';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import clsx from 'clsx';
import { observer } from 'mobx-react';

import st from '../StandbyFileUpload/StandbyFileUpload.module.scss';
import vm from './StockholderListVm';

const targetFileTypes = ['기존_주주명부', '주주명부_공증', '주주명부_서면결의'];

const StockholderList = observer((props) => {
  const details = props.file.details.filter((detail) => targetFileTypes.includes(detail.fileType));

  return (
    <>
      {details.map((detail, idx2) => (
        <Question style={{ padding: '0', width: '100%', marginTop: '30px', boxShadow: 'none' }} key={idx2}>
          <ContentBox className={Qst.spaceBetween}>
            <div className={clsx(Qst.infoSection, st.fileDetail)}>
              <div>
                <h2 className={st.title}>{props.regularShareholderMeetingYn && '정기주총 '}기존 주주명부</h2>
                {detail.fileType !== '주주명부_서면결의' &&
                  detail.finalSubmittedDate &&
                  props.parentVm.fileGroupTypes[props.idx].details[idx2].files[0]?.fileName &&
                  !props.parentVm.fileGroupTypes[props.idx].details[idx2].files[0]?.uploadFiles && (
                    <span className={st.date}>
                      ({detail.finalSubmittedDate?.year}년 {detail.finalSubmittedDate?.month}월{' '}
                      {detail.finalSubmittedDate?.day}일 변경 주주명부)
                    </span>
                  )}
                {props.regularShareholderMeetingYn && (
                  <div className={st.explain}>※ 정기주총은 결산월 마지막날 기준 주주명부 필요</div>
                )}
              </div>
              {detail.fileType === '주주명부_서면결의' && (
                <>
                  <div className={st.stockholdersListInfoBox} style={{ marginTop: '10px' }}>
                    <img
                      className={st.stockholdersListInfoImg}
                      src="/images/stockholdersList_info.png"
                      alt="주주명부 안내"
                    />
                  </div>
                  <div className={st.stockholdersListBox}>
                    <img className={st.stockholdersListBoxImg} src="/images/stockholdersList.png" alt="주주명부 예시" />
                  </div>
                  <div className={st.stockholdersFileInfoBox}>
                    <img
                      className={st.stockholdersFileInfoImg}
                      src="/images/stockholders_file_info.png"
                      alt="파일 확장자 안내"
                    />
                  </div>
                </>
              )}
              <FileUpload
                id={detail.fileType}
                className={st.fileUpload}
                accept=".pdf, .jpg, .png"
                downloadBtn
                fileName={props.parentVm.fileGroupTypes[props.idx].details[idx2].files[0].fileName}
                fileSize={props.parentVm.fileGroupTypes[props.idx].details[idx2].files[0].fileSize}
                fileExtension={vm.fileExtension(
                  props.parentVm.fileGroupTypes[props.idx].details[idx2].files[0].fileName,
                )}
                fileUrl={props.parentVm.fileGroupTypes[props.idx].details[idx2].files[0].url}
                model={props.parentVm.fileGroupTypes[props.idx].details[idx2].files[0]}
                explainText={
                  detail.fileType === '주주명부_서면결의' && (
                    <div>
                      <span className="bold">모든 주주의 주민/법인등록번호</span>가 기재된 파일이 필요합니다.
                    </div>
                  )
                }
              >
                <span className={st.bold}>여기</span>를 눌러 업로드해 주세요.
              </FileUpload>
              {props.parentVm.state.errorCheckYn && props.parentVm.displayErrorText(detail.fileType) && (
                <ErrorText>기존 주주명부를 업로드해 주세요.</ErrorText>
              )}
            </div>
            {detail.fileType === '주주명부_서면결의' && (
              <div className={Qst.qnaSection}>
                <Qna className={Qst.noTitle}>
                  <div className={Qst.qnaBox}>
                    <QText>기존 주주명부란 어떤 것을 말하나요?</QText>
                    <div className="answerBox">
                      <Answer className={Qst.answer}>현재 시점의 주주명부를 말합니다.</Answer>
                      <TextBox className={Qst.textBox} title="예시">
                        <div className={clsx(Qst.textWrap, st.textWrap)}>
                          <span className={st.text}>
                            이번에 헬프미에 신주발행
                            <br />
                            업무를 의뢰한 경우
                          </span>
                          <Icon icon="arrow" className={st.arrow} />
                          <span className={st.bold}>
                            신주발행이 반영되지
                            <br />
                            않은 주주명부
                          </span>
                        </div>
                        <div className={clsx(Qst.textWrap, st.textWrap)}>
                          <span className={st.text}>
                            변경 일자(결의 일자)가
                            <br />
                            과거로 정해진 경우
                          </span>
                          <Icon icon="arrow" className={st.arrow} />
                          <span className={st.bold}>
                            해당 변경 일자
                            <br />
                            기준의 주주명부
                          </span>
                        </div>
                      </TextBox>
                    </div>
                  </div>
                  <div className={Qst.qnaBox}>
                    <QText>모든 주주의 주민/법인등록번호는 왜 필요한가요?</QText>
                    <div className="answerBox">
                      <Answer className={Qst.answer}>
                        등기소에서 등기를 진행할 때 모든 주주의 주민/법인등록번호를 요청합니다.
                      </Answer>
                    </div>
                  </div>
                </Qna>
              </div>
            )}
          </ContentBox>
        </Question>
      ))}
    </>
  );
});

export default StockholderList;
